import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import CheckoutProvider from "./context/CheckoutContext";
import "./i18n";

ReactDOM.render(
  <CheckoutProvider>
    <App />
  </CheckoutProvider>,
  document.getElementById("root")
);
