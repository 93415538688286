import supportedCurrencies from "./SupportedCurrencies";

export const formatAmount = (amount, currencyCode) => {
  if (amount == null) {
    console.error("Amount is null or undefined");
    return currencyCode;
  }

  const currency = supportedCurrencies[currencyCode];
  if (!currency) {
    console.error("Currency not supported", currencyCode);
    return `${amount} ${currencyCode}`;
  }

  const { exponent, symbol = currencyCode } = currency;
  const language = navigator.language || navigator.userLanguage;

  return amount === 0
    ? symbol
    : Number(amount.toFixed(exponent)).toLocaleString(language, {
        maximumFractionDigits: exponent,
      }) + ` ${symbol}`;
};
