import visa from "./card-brands/visa-icon.svg";
import mastercard from "./card-brands/mastercard-icon.svg";
import amex from "./card-brands/american-express-icon.svg";
import discover from "./card-brands/discover-icon.svg";
import jcb from "./card-brands/jcb-icon.svg";
import diners from "./card-brands/diners-icon.svg";
import maestro from "./card-brands/maestro-icon.svg";
import unionPay from "./card-brands/unionpay-icon.svg";

export default {
  visa,
  mastercard,
  amex,
  discover,
  jcb,
  diners,
  maestro,
  unionPay,
};
