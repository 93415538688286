import React, { useState, useEffect, useContext } from "react";
import { CustomInput } from "../../../input/CustomInput";
import { CleaveInput } from "../../../input/CleaveInput";
import { SaveCardCheckbox } from "../../../input/saveCardCheckbox/saveCardCheckbox";
import { AdditionalFields, TcFooter } from "../../../Components";
import { Amount } from "../amount/Amount";
import { useTranslation } from "react-i18next";
import Cardbrands from "../../../../assets/CardBrands";
import Assets from "../../../../assets/Assets";
import basic_card_default from "../../../../assets/img/logos/creditCardSave/credit-card-default.png";
import basic_card_active from "../../../../assets/img/logos/credit-card.svg";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import QRCode from "react-qr-code";
import { formatAmount } from "../../../../utils/CurrencyFormater";
import { ReactComponent as UserIcon } from "../../../../assets/img/form-icons/solar_user-bold.svg";
import { ReactComponent as CardIcon } from "../../../../assets/img/form-icons/ion_card.svg";
import { ReactComponent as LockIcon } from "../../../../assets/img/form-icons/material-symbols_lock.svg";
import { ReactComponent as DateIcon } from "../../../../assets/img/form-icons/lets-icons_date-fill.svg";
import { ReactComponent as QrIcon } from "../../../../assets/img/form-icons/ph_qr-code-bold.svg";
import { useFormContext } from "react-hook-form";
import { ReactComponent as MoneyIcon } from "../../../../assets/img/form-icons/mdi_money.svg";

import "./style.scss";

export const CardForm = ({
  requestAmount,
  dataCards,
  handleClick,
  handleSelectCard,
  handleDeleteCard,
  isNew,
  checked,
  handleChange,
}) => {
  const { data, method } = useContext(CheckoutContext);
  const [brand, setBrand] = useState(null);
  const [type, setType] = useState(null);
  const { t } = useTranslation();
  const { errors } = useFormContext();
  function onCreditCardTypeChanged(type) {
    setType(type);
  }

  useEffect(() => {
    if (dataCards !== null) {
      if (dataCards.length === 0) handleClick();
    }
  }, [dataCards]);

  function validatePan(value) {
    let error;
    if (!value) {
      error = `${t("CardNumberRequired")}`;
    } else {
      error = isCardValid(value);
    }

    return error || true;
  }

  function validateCVV(value, name) {
    let error;
    if (!value) {
      error = `${t(name)}`;
    }
    if (value.length < 3) {
      error = `${t("invalidCVV")}`;
    }

    return error || true;
  }

  function validateExpiryDate(value) {
    let error;
    if (!value) {
      error = `${t("CardExpiryDateRequired")}`;
    }
    if (value.length < 5 || !isValidDate(value)) {
      error = `${t("CardExpiryDateInvalid")}`;
    }

    return error || true;
  }

  function isValidDate(date) {
    let today = new Date().toISOString().slice(0, 10);
    let todaySplit = today.split("-");
    let currentYear = todaySplit[0].substring(2, 4);
    let currntMonth = todaySplit[1];

    let dateSplit = date.split("/");
    let dateYear = dateSplit[1];
    let dateMonth = dateSplit[0];

    if (dateYear < currentYear) {
      return false;
    } else if (dateMonth < currntMonth && dateYear === currentYear) {
      return false;
    } else {
      return true;
    }
  }

  function isCardValid(value) {
    if (/[^0-9-\s]+/.test(value)) return false;
    let check = 0;
    let even = false;
    value = value.replace(/\D/g, "");

    if (value.length !== 16) {
      return `${t("CardNumberInvalid")}`;
    }

    for (let n = value.length - 1; n >= 0; n--) {
      let digitAtN = value.charAt(n);
      let digitAsInt = parseInt(digitAtN, 10);

      if (even) {
        if ((digitAsInt *= 2) > 9) digitAsInt -= 9;
      }
      check += digitAsInt;
      even = !even;
    }

    if (check % 10 !== 0) {
      return `${t("CardNumberInvalid")}`;
    }

    return true;
  }

  useEffect(() => {
    switch (type) {
      case "amex":
        setBrand(Cardbrands[type]);
        break;
      case "visa":
        setBrand(Cardbrands[type]);
        break;
      case "mastercard":
        setBrand(Cardbrands[type]);
        break;
      case "discover":
        setBrand(Cardbrands[type]);
        break;
      case "jcb":
        setBrand(Cardbrands[type]);
        break;
      case "maestro":
        setBrand(Cardbrands[type]);
        break;
      case "diners":
        setBrand(Cardbrands[type]);
        break;
      case "unionPay":
        setBrand(Cardbrands[type]);
        break;
      default:
        setBrand(null);
    }
  }, [type]);

  const isShownCardBuilder = (isNew ? isNew : !dataCards)
    ? !data.cardDetails
    : false;

  function showQrCode() {
    const popup = document.getElementById("popup");
    popup.classList.remove("d-none");
  }
  function closePopup() {
    const popup = document.getElementById("popup");
    popup.classList.add("d-none");
  }

  const validateCardholderName = (value) => {
    const trimmedValue = value.trim();
    const patternOnlyLatinLettersAndSpaces = /^[A-Za-z\s]+$/;
    const patternTwoToFourWords = /^(?:(?:[A-Za-z]{2,}\s*){2,4})$/;

    if (!patternOnlyLatinLettersAndSpaces.test(trimmedValue)) {
      return "validationInvalidCharactersInName";
    } else if (!patternTwoToFourWords.test(trimmedValue)) {
      return "validationExactlyTwoWordsRequiredInName";
    }

    return null;
  };

  const SubmitName = () => {
    switch (data.type) {
      case "CARDVERIFY":
        return t("verifyCard");
      case "WITHDRAWAL":
        return t("withdraw");
      default:
        return t("ButtonSubmitLabel");
    }
  };

  const descriptionMessage = () => {
    if (data.description) {
      return (
        <div className="message-wrapper-amount">
          <span className="message-title">To pay:</span>
          <span className="message-text">{data.description}</span>
        </div>
      );
    }
  };

  return (
    <div className="form-card">
      {data.type !== "CARDVERIFY" && (
        <div className="amount">
          {descriptionMessage()}

          {requestAmount ? (
            <Amount requestAmount={requestAmount} />
          ) : (
            <div className="amount-container">
              <MoneyIcon className="amount-icon" />
              <div className="amount-value">
                {data.amount && formatAmount(data.amount, data.currency)}
              </div>
            </div>
          )}
        </div>
      )}

      {!isNew && dataCards && (
        <>
          <div className="list-saveCards">
            {dataCards.map((el) => (
              <div
                key={el.id}
                className={
                  el.isSelected
                    ? "saveCard__item saveCard__item-active"
                    : "saveCard__item"
                }
                onMouseDown={(e) => {
                  handleSelectCard(el.id);
                }}
              >
                <img
                  className="saveCard__img"
                  src={el.isSelected ? basic_card_active : basic_card_default}
                  alt="basic_card"
                />
                <p className="saveCard__text">{el.cardNumber}</p>
                <div className="delete-btn__wrapper">
                  {el.isSelected && (
                    <button
                      className="delete-btn"
                      onClick={(event) => {
                        handleDeleteCard(el.id);
                      }}
                    ></button>
                  )}
                </div>
              </div>
            ))}
          </div>

          <CleaveInput
            options={{
              blocks: [3],
              numericOnly: true,
            }}
            name="cardSecurityCode"
            id="cvv"
            label="CardSecurityCodeLabel"
            placeHolder=""
            type="tel"
            autocomplete="cc-csc"
            wrapperClass="card-field-wrapper cvv-wrapper cvvSaveCard"
            inputValidation={(value) => validateCVV(value, "CVVRequired")}
            className="cvvSaveCard"
            inputClass="cvv-secure"
            additionalChild={
              <LockIcon
                className={`left-slot ${errors["cardSecurityCode"]?.message ? "error" : ""}`}
              />
            }
          />
          <div className="new-card-btn-container">
            <button className="nevCard-btn button-submit" onClick={handleClick}>
              <span>+</span>
              {t("addNewCard")}
            </button>
          </div>
        </>
      )}
      {isShownCardBuilder && (
        <>
          <div className="card-form-wrapper">
            <CleaveInput
              options={{
                blocks: [12],
                creditCard: true,
                onCreditCardTypeChanged,
                onchange: (value) => {
                  console.log(value);
                },
              }}
              brand={brand}
              wrapperClass="card-field-wrapper"
              name="cardNumber"
              id="number"
              label="cardPanLabel"
              type="tel"
              class="input-card"
              autocomplete="cc-number"
              inputClass="cardnumber-input-icon"
              inputValidation={(value) => validatePan(value)}
              additionalChild={
                <CardIcon
                  className={`left-slot ${errors["cardNumber"]?.message ? "error" : ""}`}
                />
              }
            />

            <div className="input-row">
              <CleaveInput
                options={{
                  date: true,
                  datePattern: ["m", "y"],
                }}
                name="expiryDate"
                id="expiry"
                label="CardExpiryLable"
                type="tel"
                autocomplete="cc-exp"
                wrapperClass="card-field-wrapper expiry-wrapper"
                specialClass="expiry-special-wrapper"
                inputValidation={(value) => validateExpiryDate(value)}
                additionalChild={
                  <DateIcon
                    className={`left-slot ${errors["expiryDate"]?.message ? "error" : ""}`}
                  />
                }
              />
              {data.type !== "WITHDRAWAL" && (
                <CleaveInput
                  options={{
                    blocks: [3],
                    numericOnly: true,
                  }}
                  name="cardSecurityCode"
                  id="cvv"
                  label="CardSecurityCodeLabel"
                  placeHolder=""
                  type="tel"
                  inputmode="numeric"
                  autocomplete="cc-csc"
                  inputClass="cvv-secure"
                  wrapperClass="card-field-wrapper cvv-wrapper"
                  inputValidation={(value) => validateCVV(value, "CVVRequired")}
                  additionalChild={
                    <LockIcon
                      className={`left-slot ${errors["cardSecurityCode"]?.message ? "error" : ""}`}
                    />
                  }
                />
              )}
            </div>
            <CustomInput
              maxLength="128"
              regexp={/^[a-zA-Z ]+$/}
              name="cardholderName"
              id="holder"
              label="cardHolderLabel"
              validate={validateCardholderName}
              required="Cardholder name"
              autocomplete="cc-name"
              wrapperClass="card-field-wrapper card-name-wrapper"
              additionalChild={
                <UserIcon
                  className={`left-slot ${errors["cardholderName"]?.message ? "error" : ""}`}
                />
              }
            />
          </div>
        </>
      )}

      {method.additionalFields && <AdditionalFields />}

      {isShownCardBuilder && (
        <>
          <SaveCardCheckbox
            name="saveCard"
            id="saveCard"
            label="saveCardLabel"
          />

          {data.showQrCode && (
            <>
              <div className="qr-code">
                <div className="qr-code__block">
                  <QrIcon className="qr-code__img" />
                  <span className="qr-code__title">View QR Code</span>
                </div>
                <button
                  type="button"
                  onClick={showQrCode}
                  className="qr-code__btn"
                >
                  See QR code
                </button>
              </div>
              <div className="popup d-none" id="popup">
                <div className="popup__block">
                  <div
                    className="popup__btn-close"
                    onClick={() => closePopup()}
                  >
                    <img src={Assets.close} alt="cancel" />
                  </div>
                  <p className="popup__title">QR Code</p>
                  <QRCode
                    className="popup__qr-code"
                    value={document.location.href}
                    size={263}
                  />
                  <button
                    type="button"
                    onClick={closePopup}
                    className="popup__btn-cancel"
                  >
                    CANCEL
                  </button>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {data.tcFooter.text && (
        <TcFooter checked={checked} handleChange={handleChange} />
      )}

      <div className="submit-wrapper">
        <input className="button-submit" type="submit" value={SubmitName()} />
      </div>
    </div>
  );
};
