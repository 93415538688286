import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import "./style.scss";
import ValueBlockchain from "./ValueBlockchain";

export const BlockchainSelect = (props) => {
  const { errors, control } = useFormContext();
  const { t } = useTranslation();

  const inputValidation = (value) => {
    if (!props.required && !value) return true;
    return value
      ? true
      : t("validationNotBlank", { fieldName: t(props.label) });
  };

  const selectOptions = ValueBlockchain.map((blockchain) => {
    return {
      value: blockchain.value,
      label: blockchain.name,
    };
  });

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
    }),
  };

  return (
    <>
      <div className="wrapper-input wrapper-input-full">
        <label htmlFor={props.id}>{t(props.label)}</label>

        <div className="input-container">
          <Controller
            as={({ onChange, value }) => (
              <Select
                value={selectOptions.find((c) => c.value === value)}
                onChange={(val) => onChange(val.value)}
                id={props.id}
                name={props.name}
                styles={style}
                options={selectOptions}
                placeholder=""
                classNamePrefix="select-custom"
                className={
                  "select-custom" +
                  (errors[props.name]?.message ? " error " : " ")
                }
              />
            )}
            name={props.name}
            control={control}
            defaultValue=""
            rules={{ validate: (value) => inputValidation(value) }}
          />
          {errors[props.name]?.ref.name && (
            <>
              <div className="error__message-block">
                <p className="error__message error__message--cardholderName">
                  {errors[props.name]?.message}
                </p>
              </div>
            </>
          )}
          {props.additionalChild && props.additionalChild}
        </div>
      </div>
    </>
  );
};
