import React, { useContext } from "react";
import { AdditionalFields } from "../../../Components";
import { Amount } from "../amount/Amount";
import { useTranslation } from "react-i18next";

import "./style.scss";
import { CheckoutContext } from "../../../../context/CheckoutContext";

export const AlternativeForm = ({ requestAmount }) => {
  const { method } = useContext(CheckoutContext);
  const { t } = useTranslation();

  return (
    <div className="form-card">
      {requestAmount && <Amount />}
      {method.additionalFields && <AdditionalFields />}
      <input
        className="button-submit"
        type="submit"
        value={t("ButtonSubmitLabel")}
      />
    </div>
  );
};
