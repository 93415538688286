import React from "react";
import SecureLogosAssets from "../../assets/img/secure-logos/SecureLogosAssets";

import "./style.scss";

export const SecureLogos = () => {
  return (
    <div className="secure-logos-wrapper">
      <img src={SecureLogosAssets.pci} alt={SecureLogosAssets.pci} />
      <img
        src={SecureLogosAssets.poweredByVisa}
        alt={SecureLogosAssets.poweredByVisa}
      />
      <img
        src={SecureLogosAssets.masterSecure}
        alt={SecureLogosAssets.masterSecure}
      />
    </div>
  );
};
