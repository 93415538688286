import { ReactComponent as basic_card } from "../../assets/img/logos/credit-card.svg";
import { ReactComponent as flexepin } from "../../assets/img/logos/flexepin-logo.svg";
import { ReactComponent as payretailers } from "../../assets/img/logos/payretailers-logo.svg";
import { ReactComponent as trustpayments } from "../../assets/img/logos/trustpayments-logo.svg";
import { ReactComponent as tink } from "../../assets/img/logos/tink-logo.svg";
import { ReactComponent as b2binpay } from "../../assets/img/logos/b2binpay-logo.svg";
import { ReactComponent as click } from "../../assets/img/logos/click-logo.svg";
import { ReactComponent as monetix } from "../../assets/img/logos/monetix-logo.svg";
import { ReactComponent as volt } from "../../assets/img/logos/volt-logo.svg";
import { ReactComponent as crypto } from "../../assets/img/logos/crypto-logo.svg";
import { ReactComponent as paypal } from "../../assets/img/logos/paypal-logo.svg";
import { ReactComponent as neteller } from "../../assets/img/logos/neteller-logo.svg";
import { ReactComponent as perfectmoney } from "../../assets/img/logos/perfectmoney-logo.svg";
import { ReactComponent as skrill } from "../../assets/img/logos/skrill-logo.svg";
import { ReactComponent as billline } from "../../assets/img/logos/billline-logo.svg";
import { ReactComponent as alycepay } from "../../assets/img/logos/alycepay.svg";
import { ReactComponent as applepay } from "../../assets/img/logos/apple-pay.svg";
import { ReactComponent as astropay } from "../../assets/img/logos/astropay-logo.svg";
import { ReactComponent as bitexpro } from "../../assets/img/logos/bitexpro.svg";
import { ReactComponent as boleto } from "../../assets/img/logos/boleto.svg";
import { ReactComponent as gate8transact } from "../../assets/img/logos/gate-8-transact.svg";
import { ReactComponent as iban } from "../../assets/img/logos/iban.svg";
import { ReactComponent as macropay } from "../../assets/img/logos/macropay.svg";
import { ReactComponent as mobile } from "../../assets/img/logos/mobile.svg";
import { ReactComponent as monnet } from "../../assets/img/logos/monnet.svg";
import { ReactComponent as ngenius } from "../../assets/img/logos/ngenius.svg";
import { ReactComponent as paymaxis } from "../../assets/img/logos/paymaxis.svg";
import { ReactComponent as pix } from "../../assets/img/logos/pix.svg";
import { ReactComponent as upi } from "../../assets/img/logos/upi.svg";
import { ReactComponent as banktransfer } from "../../assets/img/logos/banktransfer.svg";
import { ReactComponent as beeline } from "../../assets/img/logos/beeline.svg";
import { ReactComponent as bitexpro_advwallet } from "../../assets/img/logos/bitexpro_advwallet.svg";
import { ReactComponent as bitexpro_applepay } from "../../assets/img/logos/bitexpro_applepay.svg";
import { ReactComponent as bitexpro_crypto } from "../../assets/img/logos/bitexpro_crypto.svg";
import { ReactComponent as bitexpro_giropay } from "../../assets/img/logos/bitexpro_giropay.svg";
import { ReactComponent as bitexpro_neteller } from "../../assets/img/logos/bitexpro_neteller.svg";
import { ReactComponent as bitexpro_openbank } from "../../assets/img/logos/bitexpro_openbank.svg";
import { ReactComponent as bitexpro_p2p } from "../../assets/img/logos/bitexpro_p2p.svg";
import { ReactComponent as bitexpro_payeer } from "../../assets/img/logos/bitexpro_payeer.svg";
import { ReactComponent as bitexpro_skrill } from "../../assets/img/logos/bitexpro_skrill.svg";
import { ReactComponent as bitexpro_sticpay } from "../../assets/img/logos/bitexpro_sticpay.svg";
import { ReactComponent as blik } from "../../assets/img/logos/blik.svg";
import { ReactComponent as giropay } from "../../assets/img/logos/giropay.svg";
import { ReactComponent as interac } from "../../assets/img/logos/interac.svg";
import { ReactComponent as interkassa } from "../../assets/img/logos/interkassa.svg";
import { ReactComponent as kcell } from "../../assets/img/logos/kcell.svg";
import { ReactComponent as localp2p } from "../../assets/img/logos/localp2p.svg";
import { ReactComponent as mbway } from "../../assets/img/logos/mbway.svg";
import { ReactComponent as openbanking } from "../../assets/img/logos/openbanking.svg";
import { ReactComponent as paysafecard } from "../../assets/img/logos/paysafecard.svg";
import { ReactComponent as poli } from "../../assets/img/logos/poli.svg";
import { ReactComponent as rapid_transfer } from "../../assets/img/logos/rapid_transfer.svg";
import { ReactComponent as sepa } from "../../assets/img/logos/sepa.svg";
import { ReactComponent as payid } from "../../assets/img/logos/payid-method.svg";
import { ReactComponent as sofort } from "../../assets/img/logos/sofort-method.svg";
import { ReactComponent as stripe } from "../../assets/img/logos/stripe-method.svg";
import { ReactComponent as advcash } from "../../assets/img/logos/advcash.svg";
import { ReactComponent as efecty } from "../../assets/img/logos/efecty.svg";
import { ReactComponent as eft } from "../../assets/img/logos/eft.svg";
import { ReactComponent as eupago } from "../../assets/img/logos/eupago.svg";
import { ReactComponent as ezpay } from "../../assets/img/logos/ezpay.svg";
import { ReactComponent as ideal } from "../../assets/img/logos/ideal.svg";
import { ReactComponent as mandiri } from "../../assets/img/logos/mandiri.svg";
import { ReactComponent as numipay_hpp } from "../../assets/img/logos/numipay_hpp.svg";
import { ReactComponent as numipay_voucher } from "../../assets/img/logos/numipay_voucher.svg";
import { ReactComponent as paycos } from "../../assets/img/logos/paycos.svg";
import { ReactComponent as payscrow } from "../../assets/img/logos/payscrow.svg";
import { ReactComponent as prisma_link } from "../../assets/img/logos/prisma_link.svg";
import { ReactComponent as przelewy24 } from "../../assets/img/logos/przelewy24.svg";
import { ReactComponent as qbit } from "../../assets/img/logos/qbit.svg";
import { ReactComponent as revolutpay } from "../../assets/img/logos/revolutpay.svg";
import { ReactComponent as samsungpay } from "../../assets/img/logos/samsungpay.svg";
import { ReactComponent as viettelpay } from "../../assets/img/logos/viettelpay.svg";
import { ReactComponent as googlepay } from "../../assets/img/logos/google-pay-white.svg";
import { ReactComponent as alipay } from "../../assets/img/logos/alipay.svg";
import { ReactComponent as bitexpro_advcard } from "../../assets/img/logos/bitexpro_advcard.svg";
import { ReactComponent as bitexpro_blik } from "../../assets/img/logos/bitexpro_blik.svg";
import { ReactComponent as bitexpro_bpwallet } from "../../assets/img/logos/bitexpro_bpwallet.svg";
import { ReactComponent as bitexpro_expay } from "../../assets/img/logos/bitexpro_expay.svg";
import { ReactComponent as bitexpro_hsp } from "../../assets/img/logos/bitexpro_hsp.svg";
import { ReactComponent as bitexpro_papara } from "../../assets/img/logos/bitexpro_papara.svg";
import { ReactComponent as bitexpro_paysafecard } from "../../assets/img/logos/bitexpro_paysafecard.svg";
import { ReactComponent as bitexpro_paysafecash } from "../../assets/img/logos/bitexpro_paysafecash.svg";
import { ReactComponent as bitexpro_rapid } from "../../assets/img/logos/bitexpro_rapid.svg";
import { ReactComponent as bitexpro_sofort } from "../../assets/img/logos/bitexpro_sofort.svg";
import { ReactComponent as dana } from "../../assets/img/logos/dana.svg";
import { ReactComponent as debitway } from "../../assets/img/logos/debitway.svg";
import { ReactComponent as fps } from "../../assets/img/logos/fps.svg";
import { ReactComponent as gcash } from "../../assets/img/logos/gcash.svg";
import { ReactComponent as hayhay } from "../../assets/img/logos/hayhay.svg";
import { ReactComponent as kakaopay } from "../../assets/img/logos/kakaopay.svg";
import { ReactComponent as kesspay } from "../../assets/img/logos/kesspay.svg";
import { ReactComponent as khipu } from "../../assets/img/logos/khipu.svg";
import { ReactComponent as khipubanktransfer } from "../../assets/img/logos/khipubanktransfer.svg";
import { ReactComponent as mobilemoney_airtel } from "../../assets/img/logos/mobilemoney_airtel.svg";
import { ReactComponent as mobilemoney_banktransfer } from "../../assets/img/logos/mobilemoney_banktransfer.svg";
import { ReactComponent as mobilemoney_mpesa } from "../../assets/img/logos/mobilemoney_mpesa.svg";
import { ReactComponent as mobilemoney_mtn } from "../../assets/img/logos/mobilemoney_mtn.svg";
import { ReactComponent as mobilemoney_orange } from "../../assets/img/logos/mobilemoney_orange.svg";
import { ReactComponent as mobilemoney_ozow } from "../../assets/img/logos/mobilemoney_ozow.svg";
import { ReactComponent as mobilemoney_snapscan } from "../../assets/img/logos/mobilemoney_snapscan.svg";
import { ReactComponent as mobilemoney_vodafone } from "../../assets/img/logos/mobilemoney_vodafone.svg";
import { ReactComponent as mobilemoney_wave } from "../../assets/img/logos/mobilemoney_wave.svg";
import { ReactComponent as mobilemoney_zamtel } from "../../assets/img/logos/mobilemoney_zamtel.svg";
import { ReactComponent as mobilemoney } from "../../assets/img/logos/mobilemoney.svg";
import { ReactComponent as multibanco } from "../../assets/img/logos/multibanco.svg";
import { ReactComponent as bitexpro_banktransfer } from "../../assets/img/logos/bitexpro_banktransfer.svg";
import { ReactComponent as bitexpro_creditcard } from "../../assets/img/logos/bitexpro_creditcard.svg";
import { ReactComponent as bitexpro_netbanking } from "../../assets/img/logos/bitexpro_netbanking.svg";
import { ReactComponent as bitexpro_qrpayment } from "../../assets/img/logos/bitexpro_qrpayment.svg";
import { ReactComponent as bitexpro_upi } from "../../assets/img/logos/bitexpro_upi.svg";
import { ReactComponent as papara } from "../../assets/img/logos/papara.svg";

export default {
  basic_card,
  flexepin,
  payretailers,
  trustpayments,
  tink,
  b2binpay,
  click,
  monetix,
  volt,
  crypto,
  paypal,
  neteller,
  perfectmoney,
  skrill,
  billline,
  alycepay,
  applepay,
  astropay,
  bitexpro,
  boleto,
  gate8transact,
  iban,
  macropay,
  mobile,
  monnet,
  ngenius,
  paymaxis,
  pix,
  upi,
  beeline,
  banktransfer,
  bitexpro_advwallet,
  bitexpro_applepay,
  bitexpro_crypto,
  bitexpro_giropay,
  bitexpro_neteller,
  bitexpro_openbank,
  bitexpro_p2p,
  bitexpro_payeer,
  bitexpro_skrill,
  bitexpro_sticpay,
  blik,
  giropay,
  interac,
  interkassa,
  kcell,
  localp2p,
  mbway,
  openbanking,
  paysafecard,
  poli,
  rapid_transfer,
  sepa,
  payid,
  stripe,
  sofort,
  advcash,
  efecty,
  eft,
  eupago,
  ezpay,
  ideal,
  mandiri,
  numipay_hpp,
  numipay_voucher,
  paycos,
  payscrow,
  prisma_link,
  przelewy24,
  qbit,
  revolutpay,
  samsungpay,
  viettelpay,
  googlepay,
  alipay,
  bitexpro_advcard,
  bitexpro_blik,
  bitexpro_bpwallet,
  bitexpro_expay,
  bitexpro_hsp,
  bitexpro_papara,
  bitexpro_paysafecard,
  bitexpro_paysafecash,
  bitexpro_rapid,
  bitexpro_sofort,
  dana,
  debitway,
  fps,
  gcash,
  hayhay,
  kakaopay,
  kesspay,
  khipu,
  khipubanktransfer,
  mobilemoney_airtel,
  mobilemoney_banktransfer,
  mobilemoney_mpesa,
  mobilemoney_mtn,
  mobilemoney_orange,
  mobilemoney_ozow,
  mobilemoney_snapscan,
  mobilemoney_vodafone,
  mobilemoney_wave,
  mobilemoney_zamtel,
  mobilemoney,
  multibanco,
  papara,
  bitexpro_banktransfer,
  bitexpro_creditcard,
  bitexpro_netbanking,
  bitexpro_qrpayment,
  bitexpro_upi,
};
