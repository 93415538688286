import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { SecureLogos } from "../Components";
import Assets from "../../assets/Assets";
import { CleaveInput } from "../input/CleaveInput";
import { ReactComponent as LockIcon } from "../../assets/img/form-icons/material-symbols_lock.svg";
import { FormProvider } from "react-hook-form";
import { CustomInput } from "../input/CustomInput";
import { ReactComponent as EmailIcon } from "../../assets/img/form-icons/ic_baseline-email.svg";
import { capitalizeAndReplaceUnderscore, getSvgImg } from "../../utils/helper";
import PaymentMethodLogos from "./PaymanetmethodLogos";

import "./styles.scss";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";

export const AlternativeMethods = ({ onSubmit, form }) => {
  const { data } = useContext(CheckoutContext);
  const { cancelPayment } = useContext(DispatchContext);
  const { t } = useTranslation();

  function validatePassword(value, name) {
    let error;
    if (!value) {
      error = `${t(name)}`;
    }

    return error || true;
  }

  return (
    <>
      <div className="wrapper-state wrapper-threeds wrapper-alternative">
        <div className="navbar">
          <div className="navbar__img">
            {getSvgImg(PaymentMethodLogos[data.paymentMethod.toLowerCase()])}
          </div>
          {data.showCancelButton && (
            <div className="button-cancel" onClick={() => cancelPayment()}>
              <img src={Assets.close} alt="cancel" />
            </div>
          )}
        </div>
        <h2>
          Deposit with {capitalizeAndReplaceUnderscore(data.paymentMethod)}{" "}
          emulator
        </h2>
        {data.amount && (
          <div className="state-threeds state-threeds__amount">
            <p className="state-threeds__title state-alternative__title ">
              {t("AmountLabel")}:
            </p>
            <p>{data.amount}</p>
          </div>
        )}

        <FormProvider {...form}>
          <form onSubmit={onSubmit} className="threeds-form">
            <CustomInput
              id="email"
              name={"Email"}
              label={`Email`}
              required={true}
              type="email"
              additionalChild={<EmailIcon className={`left-slot `} />}
            />
            <CleaveInput
              options={{
                numericOnly: false,
              }}
              name="Password"
              id="password"
              label="Password"
              placeHolder=""
              type="tel"
              autocomplete="cc-csc"
              inputClass="otp-secure"
              wrapperClass="card-field-wrapper cvv-wrapper"
              inputValidation={(value) =>
                validatePassword(value, "PasswordRequired")
              }
              additionalChild={<LockIcon className={`left-slot }`} />}
            />
            <input
              className="button-submit"
              style={{ width: "100%" }}
              type="submit"
              value="Submit"
            />
          </form>
        </FormProvider>
      </div>
      <SecureLogos />
    </>
  );
};
