import React, { useContext, useEffect, useState, useRef } from "react";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";

import "./style.scss";

export const Timer = () => {
  const { data } = useContext(CheckoutContext);
  const { cancelPayment } = useContext(DispatchContext);
  const [secondsRemaining, setSecondsRemaining] = useState(data.checkoutTtl);
  const [showWarning, setShowWarning] = useState("");

  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  useInterval(() => {
    if (secondsRemaining > 0) {
      setSecondsRemaining(secondsRemaining - 1);
      if (secondsRemaining === 60) {
        setShowWarning("time-warning");
      }
    } else {
      cancelPayment();
    }
  }, 1000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const twoDigits = (num) => String(num).padStart(2, "0");

  return (
    <div className={`timer-wrapper ${showWarning}`}>
      <div className="timer-state">{twoDigits(minutesToDisplay)[0]}</div>
      <div className="timer-state">{twoDigits(minutesToDisplay)[1]}</div>
      <span> : </span>
      <div className="timer-state">{twoDigits(secondsToDisplay)[0]}</div>
      <div className="timer-state">{twoDigits(secondsToDisplay)[1]}</div>
    </div>
  );
};
