import { ReactComponent as banktransfer } from "../../assets/img/logos/banktransfer.svg";
import { ReactComponent as giropay } from "../../assets/img/logos/giropay-method.svg";
import { ReactComponent as neteller } from "../../assets/img/logos/neteller-method.svg";
import { ReactComponent as payid } from "../../assets/img/logos/payid-method.svg";
import { ReactComponent as skrill } from "../../assets/img/logos/skrill-method.svg";
import { ReactComponent as sofort } from "../../assets/img/logos/sofort-method.svg";
import { ReactComponent as stripe } from "../../assets/img/logos/stripe-method.svg";

export default {
  banktransfer,
  giropay,
  neteller,
  payid,
  skrill,
  sofort,
  stripe,
};
