import React, { useContext, useEffect } from "react";
import {
  CheckoutContext,
  DispatchContext,
} from "../../context/CheckoutContext";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export const Progress = (props) => {
  const { id } = useContext(CheckoutContext);
  const { getCheckout } = useContext(DispatchContext);
  const { t } = useTranslation();

  useEffect(() => {
    const timeoutId = setTimeout(function () {
      if (id) {
        getCheckout(id);
        clearTimeout(timeoutId);
      }
    }, props.checkInterval);
    return () => clearTimeout(timeoutId);
  }, [id, props.checkInterval, getCheckout]);

  useEffect(() => {
    if (id) {
      const eventListener = new EventSource(`/sse/${id}`);
      eventListener.onmessage = () => {
        eventListener.close();
        getCheckout(id);
      };

      eventListener.onerror = () => {
        eventListener.close();
      };

      return () => {
        eventListener.close();
      };
    }
  }, [id, getCheckout]);

  return (
    <section className="wrapper-progress">
      <div className="load-wrapper">
        <span className="loader"></span>
      </div>
      <div className="load-text">{t("progress")}</div>
    </section>
  );
};
