import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import "./style.scss";

export const CustomSelect = (props) => {
  const { register, errors } = useFormContext();
  const { t } = useTranslation();

  const name = props.name;
  return (
    <>
      <div className="wrapper-custom-select">
        <label htmlFor={props.id}>{t(props.name)}</label>
        <select
          ref={register({ required: `${t(props.name)} is required` })}
          id={props.id}
          name={props.name}
          className={
            "select-custom select-arrow " +
            props.inputClass +
            (errors[props.name]?.message ? " error " : " ")
          }
        >
          <option key="placeholder" value="" hidden>
            {t("customSelectPlaceHolder" + name)}
          </option>
          {props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayName}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};
