export const shouldAutoPostCheckout = (data) => {
  return (
    data.amount &&
    data.checkoutOptions.length === 1 &&
    (!data.checkoutOptions[0].additionalFields ||
      (data.checkoutOptions[0].additionalFields &&
        data.checkoutOptions[0].additionalFields.length === 0)) &&
    (data.checkoutOptions[0].paymentMethod !== "BASIC_CARD" || data.cardDetails)
  );
};

export const showPaymentInstructions = (data) => {
  return (
    data && data.paymentMethod === "CRYPTO" && data.state === "AWAITING_WEBHOOK"
  );
};

export const handleSelectCard = (id, dataCards, setDataCards) => {
  setDataCards(dataCards.map((el) => ({ ...el, isSelected: el.id === id })));
};

export const handleDeleteCard = (id, dataCards, setDataCards) => {
  fetch(`/checkouts/customer-cards/${id}`, {
    method: "DELETE",
  });
  setDataCards(dataCards.filter((el) => el.id !== id));
};

export const capitalizeAndReplaceUnderscore = (str) => {
  return str
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const redirectToReturnUrl = (returnUrl, time, showResultPage) => {
  if (returnUrl && !showResultPage) {
    window.location.replace(returnUrl);
  }

  if (time && returnUrl) {
    setTimeout(() => {
      window.location.replace(returnUrl);
    }, time * 1000);
  }
};

export const getSvgImg = (Image) => typeof Image === "object" && <Image />;

export const getFirstAndLastWord = (str) => {
  const words = str.trim().split(/\s+/);
  const firstWord = words[0];
  const lastWord = words[words.length - 1];

  return `${firstWord} ${lastWord}`;
};
