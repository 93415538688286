import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const options = {
  // order and from where user language should be detected
  order: ["navigator"],
};
i18n.use(LanguageDetector).init(options);
// the translations 1
// (tip moveto JSON file and import)
const resources = {
  en: {
    translation: {
      ExpiresEnd: "Expiration date",
      cardHolderLabel: "Cardholder Name",
      cardHolderPlaceHolder: "Name on the card",
      cardPanLabel: "Card Number",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Expiration date",
      CardExpiryPlaceholder: "MM/YY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Amount",
      AmountPlaceholder: "Enter the amount",
      ButtonSubmitLabel: "PAY NOW",
      SelectPaymentMethodLabel: "Select Payment Method",
      SelectPredefineAmountLabel: "Select an Amount",
      ManualAmountLabel: "Use a Different Amount",
      customerCitizenshipCountryCodeLabel: "Citizenship",
      customerCitizenshipCountryCodePlaceHolder: "Country of citizenship",
      billingCountryCodeLabel: "Country",
      billingCountryCodePlaceHolder: "Select Country",
      customerFirstNameLabel: "First name",
      customerFirstNamePlaceHolder: "First name",
      customerLastNameLabel: "Last name",
      customerLastNamePlaceHolder: "Last name",
      billingAddressLine1Label: "Address line 1",
      billingAddressLine1PlaceHolder: "Address line 1",
      billingCityLabel: "City",
      billingCityPlaceHolder: "City",
      billingStateLabel: "State",
      billingStatePlaceHolder: "State",
      billingPostalCodeLabel: "Postal code",
      billingPostalCodePlaceHolder: "Postal code",
      customerDateOfBirthLabel: "Date of birth",
      customerDateOfBirthPlaceHolder: "Date of birth",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Enter your email",
      customerPhoneLabel: "Phone",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Personal Identification",
      customerPersonalIdPlaceHolder: "Identification number",
      billingAddressLine2Label: "Address line 2",
      billingAddressLine2PlaceHolder: "Address line 2",
      CardNumberRequired: "Card number is required",
      CardNumberInvalid: "Invalid card number",
      CardExpiryDateRequired: "Expire date is required",
      CVVRequired: "Security code is required",
      customerAccountNumberLabel: "Account Number",
      customerAccountNumberPlaceHolder: "Enter your account",
      AmountRequiredLabel: "Amount is required",
      AmountInvalidLabel: "Amount is invalid",
      CancelLabel: "Cancel",
      PhoneNumberRequired: "Phone number is required",
      PhoneNumberInvalid: "Phone number is invalid",
      PaymentMethodNotAvailableLabel: "Payment methods not available",
      ButtonConfirmLabel: "Confirm",
      BASIC_CARD: "Credit Card",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pay with your bank",
      VOLT: "Pay with your bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Pay with your bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "Please confirm the converted amount.",
      customSelectPlaceHolderpaymentMethod: "Select method",
      customSelectPlaceHolderdocumentType: "Select document type",
      customSelectPlaceHoldercryptoCurrency: "Select crypto currency",
      blockchainValuePlaceHolder: "Select the blockchain value",
      paymentMethod: "Payment Method",
      documentType: "Document type",
      documentNumber: "Document number",
      cryptoCurrency: "Crypto currency",
      accountNumber: "Account number",
      maskedPanMessage: "Amount will be deducted from :",
      endingIn: "ending in",
      descriptionLabel: "Description:",
      invalidCVV: "Invalid CVV",
      invalidOTP: "Invalid OTP",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Invalid or expired date",
      IN_PROGRESS: "IN PROGRESS",
      CANCELLED: "CANCELLED",
      DECLINED: "DECLINED",
      COMPLETED: "COMPLETED",
      backToShopButtonLabel: "Return to website",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Deposit amount:",
      debitAmountLabel: "Debit amount:",
      validationRequired: "The {{fieldName}} field is required",
      validationMaxLength: "The maximum length is {{maxLength}} characters",
      cryptoWarningMessage_1:
        "If you send any other amount, the system will accept it anyway!",
      sendAmountInToAddress:
        "Send {{amount}} in one payment to the following address: {{address}}",
      copyToClipboard: "Copy address to clipboard",
      copied: "Copied",
      scanAddressOr: "Scan the wallet address or",
      amountToPay: "To pay",
      commissionLabel: "Commission:",
      walletDepositMessage:
        "Please proceed with deposit using the following address:",
      ButtonConfirmCascadingLabel: "Try again using another provider",
      ButtonConfirmCascadingTimeoutLabel: "Will try another provider in ",
      cascadingInstructions:
        "Your transaction was declined, but don't worry as we have another provider to try with. Please, do not close this page.",
      errorCode:
        "Dear Customer, unfortunately, there’s no available payment option for this scenario. Please make sure the following params are supported: Card Type, BIN Country, Amount and Currency Please contact the merchant or try to use another card.",
      TERMINAL_NOT_FOUND: `Dear Customer, Unfortunately, there is no available payment option for this scenario. Please use another payment method or another card.`,
      saveCardLabel: "Do you want to save the card?",
      addNewCard: "Add new card",
      accept: "Accept",
      validationInvalidCharactersInName:
        "Please use at least two Latin symbols",
      validationExactlyTwoWordsRequiredInName:
        "The cardholder's name must consist of two to four words, each containing at least two letters.",
      validationNotBlank: "The {{fieldName}} field cannot be blank",
      receiptBtn: "Get receipt",
      progress:
        "Please wait, processing your transaction. Do not close this page",
      verifyCard: "Verify Card",
      withdraw: "Withdraw",
    },
  },
  ru: {
    translation: {
      ExpiresEnd: "Срок действия",
      cardHolderLabel: "Имя на карте",
      cardHolderPlaceHolder: "Имя на карте",
      cardPanLabel: "Номер карты",
      cardPanPlaceHolder: "Номер карты",
      CardExpiryLable: "ММ/ГГ",
      CardExpiryPlaceholder: "ММ/ГГ",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Сумма",
      AmountPlaceholder: "Сумма",
      ButtonSubmitLabel: "Оплатить",
      SelectPaymentMethodLabel: "Выберите способ оплаты",
      SelectPredefineAmountLabel: "Выбрать сумму",
      ManualAmountLabel: "Выбрать другую сумму",
      customerCitizenshipCountryCodeLabel: "Гражданство",
      customerCitizenshipCountryCodePlaceHolder: "Гражданство",
      billingCountryCodeLabel: "Страна",
      billingCountryCodePlaceHolder: "Выберите страну",
      customerFirstNameLabel: "Имя",
      customerFirstNamePlaceHolder: "Имя",
      customerLastNameLabel: "Фамилия",
      customerLastNamePlaceHolder: "Фамилия",
      customerPersonalIdLabel: "Номер паспорта",
      customerPersonalIdPlaceHolder: "Номер паспорта",
      billingAddressLine1Label: "Адрес",
      billingAddressLine1PlaceHolder: "Адрес",
      billingCityLabel: "Город",
      billingCityPlaceHolder: "Город",
      billingStateLabel: "Регион",
      billingStatePlaceHolder: "Регион",
      billingPostalCodeLabel: "Почтовый индекс",
      billingPostalCodePlaceHolder: "Почтовый индекс",
      customerDateOfBirthLabel: "Дата рождения",
      customerDateOfBirthPlaceHolder: "Дата рождения",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Email",
      customerPhoneLabel: "Телефон",
      customerPhonePlaceHolder: "+51 1234567",
      billingAddressLine2Label: "Адрес, строка 2",
      billingAddressLine2PlaceHolder: "Адрес, строка 2",
      customerAccountNumberLabel: "Номер счета",
      customerAccountNumberPlaceHolder: "Номер счета",
      AmountRequiredLabel: "Не выбрана сумма",
      AmountInvalidLabel: "Неверная сумма",
      CancelLabel: "Отменить",
      CardNumberRequired: "Номер карты обязателен",
      PhoneNumberRequired: "Не выбран номер телефона",
      CardNumberInvalid: "Не верный номер карты",
      CardExpiryDateRequired: "Укажите дату истечения срока действия",
      CVVRequired: "Требуется код безопасности",
      PhoneNumberInvalid: "Неверный номер телефона",
      PaymentMethodNotAvailableLabel: "Нет доступных способов платежа",
      ButtonConfirmLabel: "Подтвердить",
      BASIC_CARD: "Credit Card",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Платить в своем банке",
      VOLT: "Платить в своем банке",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Платить в своем банке",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto депозит",
      ConfirmationMessage: "Пожалуйста, подтвердите сумму после конвертации.",
      customSelectPlaceHolderpaymentMethod: "Выберите метод",
      customSelectPlaceHolderdocumentType: "Выберите тип документа",
      customSelectPlaceHoldercryptoCurrency: "Выберите крипто валюту",
      blockchainValuePlaceHolder: "Выберите значение блокчейна",
      paymentMethod: "Способ платежа",
      documentType: "Тип документа",
      documentNumber: "Номер документа",
      cryptoCurrency: "Крипто валюта",
      accountNumber: "Номер счета",
      maskedPanMessage: "Сумма будет списана с карты:",
      endingIn: "заканчивается на",
      descriptionLabel: "Описание:",
      invalidCVV: "Неверный CVV",
      invalidOTP: "Неверный OTP",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Неверный срок дейстия",
      IN_PROGRESS: "ОБРАБАТЫВАЕТСЯ",
      CANCELLED: "ОТМЕНЕН",
      DECLINED: "ОТКЛОНЕН",
      COMPLETED: "УСПЕШЕН",
      backToShopButtonLabel: "Вернуться на сайт",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Сумма к зачислению:",
      debitAmountLabel: "Сумма списания:",
      validationRequired: "Поле {{fieldName}} обязательно",
      validationMaxLength: "Максимальная длина {{maxLength}} символов",
      cryptoWarningMessage_1:
        "Если Вы отправите другую сумму, система все равно обработает оплату!",
      sendAmountInToAddress:
        "Отправьте {{amount}} одним платежом на следующий адрес: {{address}}",
      copyToClipboard: "Скопируйте адрес",
      copied: "Скопировано",
      scanAddressOr: "Сканируйте адрес кошелька или",
      amountToPay: "Платить",
      commissionLabel: "Комиссия:",
      walletDepositMessage:
        "Пожалуйста, продолжите внесение депозита, используя следующий адрес:",
      ButtonConfirmCascadingLabel:
        "Попробуйте еще раз, используя другого провайдера",
      ButtonConfirmCascadingTimeoutLabel: "Попробую другого провайдера через",
      cascadingInstructions:
        "Ваша транзакция была отклонена, но не волнуйтесь, у нас есть другой провайдер для попытки. Пожалуйста, не закрывайте эту страницу.",
      TERMINAL_NOT_FOUND: `Уважаемый клиент, к сожалению, для данного сценария нет доступного способа оплаты. Пожалуйста, используйте другой метод оплаты или другую карту.`,
      saveCardLabel: "Сохранить карту",
      addNewCard: "добавить новую карту",
      accept: "Принять",
      validationInvalidCharactersInName:
        "Убедитесь, что используются минимум два латинских символа.",
      validationExactlyTwoWordsRequiredInName:
        "Имя владельца карты должно состоять из двух-четырех слов, содержащих не менее двух букв.",
      validationNotBlank: "Поле {{fieldName}} не может быть пустым.",
      progress:
        "Пожалуйста, подождите, ваша транзакция в обработке. Не закрывайте эту страницу.",
      verifyCard: "Подтвердить карту",
      withdraw: "Вывести средства",
    },
  },
  kk: {
    translation: {
      ExpiresEnd: "Срок действия",
      cardHolderLabel: "Имя на карте",
      cardHolderPlaceHolder: "Имя на карте",
      cardPanLabel: "Номер карты",
      cardPanPlaceHolder: "Номер карты",
      CardExpiryLable: "ММ/ГГ",
      CardExpiryPlaceholder: "ММ/ГГ",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Сумма",
      AmountPlaceholder: "Сумма",
      ButtonSubmitLabel: "Оплатить",
      SelectPaymentMethodLabel: "Выберите способ оплаты",
      SelectPredefineAmountLabel: "Выбрать сумму",
      ManualAmountLabel: "Выбрать другую сумму",
      customerCitizenshipCountryCodeLabel: "Гражданство",
      customerCitizenshipCountryCodePlaceHolder: "Гражданство",
      billingCountryCodeLabel: "Страна",
      billingCountryCodePlaceHolder: "Выберите страну",
      customerFirstNameLabel: "Имя",
      customerFirstNamePlaceHolder: "Имя",
      customerLastNameLabel: "Фамилия",
      customerLastNamePlaceHolder: "Фамилия",
      customerPersonalIdLabel: "Номер паспорта",
      customerPersonalIdPlaceHolder: "Номер паспорта",
      billingAddressLine1Label: "Адрес, строка 2",
      billingAddressLine1PlaceHolder: "Адрес",
      billingCityLabel: "Город",
      billingCityPlaceHolder: "Город",
      billingStateLabel: "Регион",
      billingStatePlaceHolder: "Регион",
      billingPostalCodeLabel: "Почтовый индекс",
      billingPostalCodePlaceHolder: "Почтовый индекс",
      customerDateOfBirthLabel: "Дата рождения",
      customerDateOfBirthPlaceHolder: "Дата рождения",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Email",
      customerPhoneLabel: "Телефон",
      customerPhonePlaceHolder: "+51 1234567",
      billingAddressLine2Label: "Адрес, строка 2",
      billingAddressLine2PlaceHolder: "Адрес, строка 2",
      customerAccountNumberLabel: "Номер счета",
      customerAccountNumberPlaceHolder: "Номер счета",
      AmountRequiredLabel: "Не выбрана сумма",
      AmountInvalidLabel: "Неверная сумма",
      CancelLabel: "Отменить",
      CardNumberRequired: "Номер карты обязателен",
      PhoneNumberRequired: "Не выбран номер телефона",
      CardNumberInvalid: "Не верный номер карты",
      CardExpiryDateRequired: "Укажите дату истечения срока действия",
      CVVRequired: "Требуется код безопасности",
      PhoneNumberInvalid: "Неверный номер телефона",
      PaymentMethodNotAvailableLabel: "Нет доступных способов платежа",
      ButtonConfirmLabel: "Подтвердить",
      BASIC_CARD: "Credit Card",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Платить в своем банке",
      VOLT: "Платить в своем банке",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Платить в своем банке",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto депозит",
      ConfirmationMessage: "Пожалуйста, подтвердите сумму после конвертации.",
      customSelectPlaceHolderpaymentMethod: "Выберите метод",
      customSelectPlaceHolderdocumentType: "Выберите тип документа",
      customSelectPlaceHoldercryptoCurrency: "Выберите крипто валюту",
      blockchainValuePlaceHolder: "Выберите значение блокчейна",
      paymentMethod: "Способ платежа",
      documentType: "Тип документа",
      documentNumber: "Номер документа",
      cryptoCurrency: "Крипто валюта",
      accountNumber: "Номер счета",
      maskedPanMessage: "Сумма будет списана с карты:",
      endingIn: "заканчивается на",
      descriptionLabel: "Описание:",
      invalidCVV: "Неверный CVV",
      invalidOTP: "Неверный OTP",
      CardExpiryDateInvalid: "Неверный срок дейстия",
      IN_PROGRESS: "ОБРАБАТЫВАЕТСЯ",
      CANCELLED: "ОТМЕНЕН",
      DECLINED: "ОТКЛОНЕН",
      COMPLETED: "УСПЕШЕН",
      backToShopButtonLabel: "Вернуться на сайт",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Сумма к зачислению:",
      debitAmountLabel: "Сумма списания:",
      validationRequired: "Поле {{fieldName}} обязательно",
      validationMaxLength: "Максимальная длина {{maxLength}} символов",
      cryptoWarningMessage_1:
        "Если Вы отправите другую сумму, система все равно обработает оплату!",
      sendAmountInToAddress:
        "Отправьте {{amount}} одним платежом на следующий адрес: {{address}}",
      copyToClipboard: "Скопируйте адрес",
      copied: "Скопировано",
      scanAddressOr: "Сканируйте адрес кошелька или",
      amountToPay: "Платить",
      commissionLabel: "Комиссия:",
      walletDepositMessage:
        "Пожалуйста, продолжите внесение депозита, используя следующий адрес:",
      ButtonConfirmCascadingLabel:
        "Попробуйте еще раз, используя другого провайдера",
      ButtonConfirmCascadingTimeoutLabel: "Попробую другого провайдера через",
      cascadingInstructions:
        "Ваша транзакция была отклонена, но не волнуйтесь, у нас есть другой провайдер для попытки. Пожалуйста, не закрывайте эту страницу.",
      TERMINAL_NOT_FOUND: `Уважаемый клиент, к сожалению, для данного сценария нет доступного способа оплаты. Пожалуйста, используйте другой метод оплаты или другую карту.`,
      saveCardLabel: "Сохранить карту",
      addNewCard: "добавить новую карту",
      accept: "Принять",
      validationInvalidCharactersInName:
        "Убедитесь, что используются минимум два латинских символа.",
      validationExactlyTwoWordsRequiredInName:
        "Имя владельца карты должно состоять из двух-четырех слов, содержащих не менее двух букв.",
      validationNotBlank: "Поле {{fieldName}} не может быть пустым.",
      progress:
        "Пожалуйста, подождите, ваша транзакция в обработке. Не закрывайте эту страницу.",
      verifyCard: "Подтвердить карту",
      withdraw: "Вывести средства",
    },
  },
  it: {
    translation: {
      ExpiresEnd: "Scade Fine",
      cardHolderLabel: "Nome titolare",
      cardHolderPlaceHolder: "Nome sulla carta",
      cardPanLabel: "Numero carta",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Data di scadenza",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "Codice di sicurezza",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Importo",
      AmountPlaceholder: "Digita l'importo",
      ButtonSubmitLabel: "Paga ora",
      SelectPaymentMethodLabel: "Seleziona un metodo di pagamento",
      SelectPredefineAmountLabel: "Seleziona un importo",
      ManualAmountLabel: "Usa un importo diverso",
      customerCitizenshipCountryCodeLabel: "Cittadinanza",
      customerCitizenshipCountryCodePlaceHolder: "Paese di cittadinanza",
      billingCountryCodeLabel: "Stato",
      billingCountryCodePlaceHolder: "Seleziona Stato",
      customerFirstNameLabel: "Nome",
      customerFirstNamePlaceHolder: "Nome",
      customerLastNameLabel: "Cognome",
      customerLastNamePlaceHolder: "Cognome",
      billingAddressLine1Label: "Indirizzo riga 1",
      billingAddressLine1PlaceHolder: "Indirizzo riga 1",
      billingCityLabel: "Città",
      billingCityPlaceHolder: "Città",
      billingStateLabel: "Stato",
      billingStatePlaceHolder: "Stato",
      billingPostalCodeLabel: "Codice postale",
      billingPostalCodePlaceHolder: "Codice postale",
      customerDateOfBirthLabel: "Data di nascita",
      customerDateOfBirthPlaceHolder: "Data di nascita",
      customerEmailLabel: "Indirizzo e-mail",
      customerEmailPlaceHolder: "mail@mail.com",
      customerPhoneLabel: "Numero di telefono",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Identificativo personale",
      customerPersonalIdPlaceHolder: "Numero identificativo",
      billingAddressLine2Label: "Indirizzo riga 2",
      billingAddressLine2PlaceHolder: "Indirizzo riga 2",
      CardNumberRequired: "Inserisci il numero della carta",
      CardNumberInvalid: "Numero carta non valido",
      CardExpiryDateRequired: "Inserisci la data di scadenza",
      CVVRequired: "Inserisci il codice di sicurezza",
      customerAccountNumberLabel: "Numero account",
      customerAccountNumberPlaceHolder: "Digita il tuo account",
      AmountRequiredLabel: "Inserisci l'importo",
      AmountInvalidLabel: "L'importo non è valido",
      CancelLabel: "Annulla",
      PhoneNumberRequired: "Inserisci il numero di telefono",
      PhoneNumberInvalid: "Il numero di telefono non è valido",
      PaymentMethodNotAvailableLabel: "Metodo di pagamento non disponibile",
      ButtonConfirmLabel: "Conferma",
      BASIC_CARD: "Carta di credito",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pay with your bank",
      VOLT: "Pay with your bank",
      B2BINPAY: "B2BinPay",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Pay with your bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Deposito di criptovalute",
      ConfirmationMessage: "Conferma l'importo convertito.",
      customSelectPlaceHolderpaymentMethod: "Seleziona metodo",
      customSelectPlaceHolderdocumentType: "Seleziona il tipo di documento",
      customSelectPlaceHoldercryptoCurrency: "Select crypto currency",
      blockchainValuePlaceHolder: "Seleziona il valore della blockchain",
      paymentMethod: "Metodo di pagamento",
      documentType: "Tipo di documento",
      documentNumber: "Numero documento",
      cryptoCurrency: "Crypto currency",
      accountNumber: "Numero account",
      maskedPanMessage: "L'importo verrà detratto da:",
      endingIn: "termina con",
      descriptionLabel: "Descrizione:",
      invalidCVV: "CVV non valido",
      invalidOTP: "OTP non valido",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Data di scadenza non valida",
      IN_PROGRESS: "IN CORSO",
      CANCELLED: "ANNULLATA",
      DECLINED: "DECLINATA",
      COMPLETED: "COMPLETATO",
      backToShopButtonLabel: "Ritorno al sito web",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Deposit amount:",
      debitAmountLabel: "Debit amount:",
      validationRequired: "Il campo {{fieldName}} è obbligatorio",
      validationMaxLength: "La lunghezza massima è {{maxLength}} caratteri",
      cryptoWarningMessage_1:
        "Se invii un altro importo, il sistema lo accetterà comunque!",
      sendAmountInToAddress:
        "Invia {{amount}} in un unico pagamento al seguente indirizzo: {{address}}",
      copyToClipboard: "Copia l'indirizzo",
      copied: "copiato",
      scanAddressOr: "Scansiona l'indirizzo del portafoglio o",
      amountToPay: "Pagare",
      commissionLabel: "Commissione:",
      walletDepositMessage:
        "Si prega di procedere con il deposito utilizzando il seguente indirizzo:",
      ButtonConfirmCascadingLabel: "Riprova utilizzando un altro provider",
      ButtonConfirmCascadingTimeoutLabel: "Proverò con un altro provider",
      cascadingInstructions:
        "La tua transazione è stata declinata, ma non preoccuparti perché abbiamo un altro fornitore da provare. Per favore, non chiudere questa pagina.",
      TERMINAL_NOT_FOUND: `Gentile Cliente, purtroppo non è disponibile un'opzione di pagamento per questo scenario. Si prega di utilizzare un altro metodo di pagamento o un'altra carta.`,
      saveCardLabel: "Salva questa carta",
      addNewCard: "aggiungi una nuova carta",
      accept: "Accettare",
      validationInvalidCharactersInName:
        "Si prega di utilizzare almeno due simboli latini.",
      validationExactlyTwoWordsRequiredInName:
        "Il nome del titolare della carta deve essere composto da due o quattro parole con almeno due lettere.",
      validationNotBlank: "Il campo {{fieldName}} non può essere vuoto.",
      progress:
        "Per favore, attendi, elaborazione della tua transazione. Non chiudere questa pagina.",
      verifyCard: "Verifica della carta",
      withdraw: "Prelievo di fondi",
    },
  },
  fr: {
    translation: {
      ExpiresEnd: "Data di scadenza della",
      cardHolderLabel: "Titulaire",
      cardHolderPlaceHolder: "Nom sur la carte",
      cardPanLabel: "Numéro de carte",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Expiration",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Montant",
      AmountPlaceholder: "Entrez le montant",
      ButtonSubmitLabel: "Payer maintenant",
      SelectPaymentMethodLabel: "Choisissez le mode de paiement",
      SelectPredefineAmountLabel: "Choisissez un montant",
      ManualAmountLabel: "Utiliser un montant différent",
      customerCitizenshipCountryCodeLabel: "Nationalité",
      customerCitizenshipCountryCodePlaceHolder: "Pays de nationalité",
      billingCountryCodeLabel: "Pays",
      billingCountryCodePlaceHolder: "Choisissez le pays",
      customerFirstNameLabel: "Prénom",
      customerFirstNamePlaceHolder: "Prénom",
      customerLastNameLabel: "Nom de famille",
      customerLastNamePlaceHolder: "Nom de famille",
      billingAddressLine1Label: "Adresse ligne 1",
      billingAddressLine1PlaceHolder: "Adresse ligne 1",
      billingCityLabel: "Ville",
      billingCityPlaceHolder: "Ville",
      billingStateLabel: "État",
      billingStatePlaceHolder: "État",
      billingPostalCodeLabel: "Code postal",
      billingPostalCodePlaceHolder: "Code postal",
      customerDateOfBirthLabel: "Date de naissance",
      customerDateOfBirthPlaceHolder: "Date de naissance",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "mail@mail.com",
      customerPhoneLabel: "Numéro de téléphone",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "PIN",
      customerPersonalIdPlaceHolder: "Code PIN",
      billingAddressLine2Label: "Adresse ligne 2",
      billingAddressLine2PlaceHolder: "Adresse ligne 2",
      CardNumberRequired: "Un numéro de carte est requis",
      CardNumberInvalid: "NUméro de carte invalide",
      CardExpiryDateRequired: "Une date d'expiration est requise",
      CVVRequired: "Un code de sécurité est requis",
      customerAccountNumberLabel: "Numéro de compte",
      customerAccountNumberPlaceHolder: "Entrez votre numéro de compte",
      AmountRequiredLabel: "Un montant est requis",
      AmountInvalidLabel: "Le montant est invalide",
      CancelLabel: "Annuler",
      PhoneNumberRequired: "Un numéro de téléphone est requis",
      PhoneNumberInvalid: "Le numéro de téléphone est invalide",
      PaymentMethodNotAvailableLabel: "Mode de paiement non disponible",
      ButtonConfirmLabel: "Confirmer",
      BASIC_CARD: "Carte de crédit",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      TRUSTPAYMENTS: "TrustPayments",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pay with your bank",
      VOLT: "Pay with your bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Pay with your bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Dépôt de crypto",
      ConfirmationMessage: "Veuillez confirmer le montant converti.",
      customSelectPlaceHolderpaymentMethod: "Choisissez le mode de paiement",
      customSelectPlaceHolderdocumentType: "Choisissez le type de document",
      customSelectPlaceHoldercryptoCurrency: "Select crypto currency",
      blockchainValuePlaceHolder: "Sélectionnez la valeur de la blockchain",
      paymentMethod: "Mode de paiement",
      documentType: "Type de document",
      documentNumber: "Numéro du document",
      cryptoCurrency: "Crypto currency",
      accountNumber: "Numéro de compte",
      maskedPanMessage: "Le montant sera déduit de :",
      endingIn: "finissant en",
      descriptionLabel: "Description:",
      invalidCVV: "CVV invalide",
      invalidOTP: "OTP invalide",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Date d'expiration invalide",
      IN_PROGRESS: "EN COURS",
      CANCELLED: "ANNULÉ",
      DECLINED: "REFUSÉE",
      COMPLETED: "COMPLÉTÉ",
      backToShopButtonLabel: "Retourner au site web",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Deposit amount:",
      debitAmountLabel: "Debit amount:",
      validationRequired: "Le champ {{fieldName}} est obligatoire",
      validationMaxLength:
        "La longueur maximale est de {{maxLength}} caractères",
      cryptoWarningMessage_1:
        "Si vous envoyez un autre montant, le système l'acceptera quand même !",
      sendAmountInToAddress:
        "Envoyez {{amount}} en un seul paiement à l'adresse suivante : {{address}}",
      copyToClipboard: "Copier l'adresse dans le presse-papiers",
      copied: "Copié",
      scanAddressOr: "Scannez l'adresse du portefeuille ou",
      amountToPay: "Payer",
      commissionLabel: "Commission:",
      walletDepositMessage: "Veuillez procéder au dépôt à l'adresse suivante:",
      ButtonConfirmCascadingLabel:
        "Réessayez en utilisant un autre fournisseur",
      ButtonConfirmCascadingTimeoutLabel:
        "Je vais essayer un autre fournisseur dans",
      cascadingInstructions:
        "Votre transaction a été refusée, mais ne vous inquiétez pas car nous avons un autre fournisseur à essayer. S'il vous plaît, ne fermez pas cette page.",
      TERMINAL_NOT_FOUND: `Cher client, malheureusement, il n'y a pas d'option de paiement disponible pour ce scénario. Veuillez utiliser un autre mode de paiement ou une autre carte.`,
      saveCardLabel: "Enregistrer cette carte",
      addNewCard: "ajouter une nouvelle carte",
      accept: "J'accepte",
      validationInvalidCharactersInName:
        "Veuillez utiliser au moins deux symboles latinsю",
      validationExactlyTwoWordsRequiredInName:
        "Le nom du titulaire de la carte doit être composé de deux à quatre mots comportant au moins deux lettres.",
      validationNotBlank: "Le champ {{fieldName}} ne peut pas être vide.",
      progress:
        "Veuillez patienter, traitement de votre transaction. Ne fermez pas cette page.",
      verifyCard: "Vérifier la carte",
      withdraw: "Retrait de fonds",
    },
  },
  es: {
    translation: {
      ExpiresEnd: "Fecha de caducidad",
      cardHolderLabel: "Titular de la tarjeta",
      cardHolderPlaceHolder: "Nombre en la tarjeta",
      cardPanLabel: "Número de tarjeta",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Mes/Año",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "One-Time Password",
      Password: "Password",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Importe",
      AmountPlaceholder: "Insertar importe",
      ButtonSubmitLabel: "Paga ahora",
      SelectPaymentMethodLabel: "Seleccionar método de pago",
      SelectPredefineAmountLabel: "Seleccionar el importe",
      ManualAmountLabel: "Elige otro importe",
      customerCitizenshipCountryCodeLabel: "Nacionalidad",
      customerCitizenshipCountryCodePlaceHolder: "País de nacionalidad",
      billingCountryCodeLabel: "País",
      billingCountryCodePlaceHolder: "Seleccionar país",
      customerFirstNameLabel: "Nombre",
      customerFirstNamePlaceHolder: "Nombre",
      customerLastNameLabel: "Apellido",
      customerLastNamePlaceHolder: "Apellido",
      billingAddressLine1Label: "Dirección línea 1",
      billingAddressLine1PlaceHolder: "Dirección línea 1",
      billingCityLabel: "Ciudad",
      billingCityPlaceHolder: "Ciudad",
      billingStateLabel: "Provincia",
      billingStatePlaceHolder: "Provincia",
      billingPostalCodeLabel: "Código postal",
      billingPostalCodePlaceHolder: "Código postal",
      customerDateOfBirthLabel: "Fecha de nacimiento",
      customerDateOfBirthPlaceHolder: "Fecha de nacimiento",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Insertar email",
      customerPhoneLabel: "Teléfono",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "DNI/NIE",
      customerPersonalIdPlaceHolder: "DNI/NIE",
      billingAddressLine2Label: "Dirección línea 2",
      billingAddressLine2PlaceHolder: "Dirección línea 2",
      CardNumberRequired: "Número de tarjeta es obligatorio",
      CardNumberInvalid: "Número de tarjeta no válido",
      CardExpiryDateRequired: "Fecha de caducidad obligatorio",
      CVVRequired: "Código de seguridad obligatorio",
      customerAccountNumberLabel: "Número de cuenta",
      customerAccountNumberPlaceHolder: "Insertar número de cuenta",
      AmountRequiredLabel: "Importe obligatorio",
      AmountInvalidLabel: "Importe no es válido",
      CancelLabel: "Cancelar",
      PhoneNumberRequired: "Número de teléfono obligatorio",
      PhoneNumberInvalid: "Número de teléfono no es válido",
      PaymentMethodNotAvailableLabel: "Métodos de pago no disponibles",
      ButtonConfirmLabel: "Confirmar",
      BASIC_CARD: "Tarjeta de crédito",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Transferencia bancaria",
      VOLT: "Transferencia bancaria",
      B2BINPAY: "B2BinPay (Criptomoneda)",
      B2BINPAYV2: "B2BinPay (Criptomoneda)",
      CLICK: "Transferencia bancaria",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Criptomoneda",
      ConfirmationMessage: "Por favor, confirme el importe convertido.",
      customSelectPlaceHolderpaymentMethod: "Seleccionar método",
      customSelectPlaceHolderdocumentType: "Seleccionar tipo de documento",
      customSelectPlaceHoldercryptoCurrency: "Seleccionar criptomoneda",
      blockchainValuePlaceHolder: "Seleccione el valor de la cadena de bloques",
      paymentMethod: "Método de pago",
      documentType: "Tipo de documento",
      documentNumber: "Número de documento",
      cryptoCurrency: "Criptomoneda",
      accountNumber: "Número de cuenta",
      maskedPanMessage: "Importe será deducido de :",
      endingIn: "que acaba con",
      descriptionLabel: "Descripción:",
      invalidCVV: "CVV no válido",
      invalidOTP: "OTP no válido",
      OTPRequired: "OTP is required",
      PasswordRequired: "Password is required",
      invalidPassword: "Invalid Password",
      CardExpiryDateInvalid: "Fecha no válida o caducada",
      IN_PROGRESS: "IN PROGRESS",
      CANCELLED: "CANCELLED",
      DECLINED: "DECLINED",
      COMPLETED: "COMPLETED",
      backToShopButtonLabel: "Volver al sitio web",
      minAmountLabel: "Mín:",
      maxAmountLabel: "Máx:",
      depositAmountLabel: "Importe de depósito:",
      debitAmountLabel: "Importe de débito:",
      validationRequired: "El campo {{fieldName}} es obligatorio",
      validationMaxLength: "La longitud máxima es de {{maxLength}} carácteres",
      cryptoWarningMessage_1:
        "Si envia otra cantidad, ¡el sistema lo aceptará igualmente!",
      sendAmountInToAddress:
        "Envíe {{amount}} en una sóla transacción a la siguiente dirección: {{address}}",
      copyToClipboard: "Copiar dirección al portapapeles",
      copied: "Copiado",
      scanAddressOr: "Escánear dirección de billetera o",
      amountToPay: "Para pagar",
      commissionLabel: "Comisión:",
      walletDepositMessage: "Proceda con el depósito a la siguiente dirección:",
      ButtonConfirmCascadingLabel: "Pruebe de nuevo con otro proveedor",
      ButtonConfirmCascadingTimeoutLabel: "Intentará con otro proveedor en ",
      cascadingInstructions:
        "Su transacción fue rechazada, pero no se preocupe ya que tenemos otro proveedor para intentar. Por favor, no cierre esta página.",
      errorCode:
        "Estimado cliente, desafortunadamente, no hay una opción de pago disponible para esta situación. Asegúrese que los detalles conformen con los siguientes parámetros: tipo de tarjeta, país de tarjeta, importe y moneda. Póngase en contacto con el banco o intente de nuevo con otra tarjeta.",
      TERMINAL_NOT_FOUND: `Estimado cliente, lamentablemente, no hay una opción de pago disponible para este escenario. Por favor, use otro método de pago u otra tarjeta.`,
      saveCardLabel: "Guarde esta tarjeta",
      addNewCard: "añadir nueva tarjeta",
      accept: "Aceptar",
      validationInvalidCharactersInName:
        "Utilice al menos dos símbolos latinos.",
      validationExactlyTwoWordsRequiredInName:
        "El nombre del titular de la tarjeta debe constar de dos a cuatro palabras con al menos dos letras.",
      validationNotBlank: "El campo {{fieldName}} no puede estar en blanco.",
      progress:
        "Por favor, espere, procesando su transacción. No cierre esta página.",
      verifyCard: "Verificar tarjeta",
      withdraw: "Retirar fondos",
    },
  },
  nl: {
    translation: {
      ExpiresEnd: "Vervaldatum",
      cardHolderLabel: "Naam van de kaarthouder",
      cardHolderPlaceHolder: "Naam op de kaart",
      cardPanLabel: "Kaartnummer",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Vervaldatum",
      CardExpiryPlaceholder: "MM/YY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Eenmalig Wachtwoord",
      Password: "Wachtwoord",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Bedrag",
      AmountPlaceholder: "Voer het bedrag in",
      ButtonSubmitLabel: "BETAAL NU",
      SelectPaymentMethodLabel: "Selecteer de betaalwijze",
      SelectPredefineAmountLabel: "Selecteer een bedrag",
      ManualAmountLabel: "Gebruik een ander bedrag",
      customerCitizenshipCountryCodeLabel: "Burgerschap",
      customerCitizenshipCountryCodePlaceHolder: "Land van burgerschap",
      billingCountryCodeLabel: "Land",
      billingCountryCodePlaceHolder: "Selecteer land",
      customerFirstNameLabel: "Voornaam",
      customerFirstNamePlaceHolder: "Voornaam",
      customerLastNameLabel: "Achternaam",
      customerLastNamePlaceHolder: "Achternaam",
      billingAddressLine1Label: "Adresregel 1",
      billingAddressLine1PlaceHolder: "Adresregel 1",
      billingCityLabel: "Stad",
      billingCityPlaceHolder: "Stad",
      billingStateLabel: "Staat",
      billingStatePlaceHolder: "Staat",
      billingPostalCodeLabel: "Postcode",
      billingPostalCodePlaceHolder: "Postcode",
      customerDateOfBirthLabel: "Geboortedatum",
      customerDateOfBirthPlaceHolder: "Geboortedatum",
      customerEmailLabel: "E-mail",
      customerEmailPlaceHolder: "Voer uw e-mailadres in",
      customerPhoneLabel: "Telefoon",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Persoonlijke identificatie",
      customerPersonalIdPlaceHolder: "Identificatienummer",
      billingAddressLine2Label: "Adresregel 2",
      billingAddressLine2PlaceHolder: "Adresregel 2",
      CardNumberRequired: "Kaartnummer is vereist",
      CardNumberInvalid: "Ongeldig kaartnummer",
      CardExpiryDateRequired: "Vervaldatum is vereist",
      CVVRequired: "Beveiligingscode is vereist",
      customerAccountNumberLabel: "Rekeningnummer",
      customerAccountNumberPlaceHolder: "Voer uw account in",
      AmountRequiredLabel: "Bedrag is vereist",
      AmountInvalidLabel: "Bedrag is ongeldig",
      CancelLabel: "Annuleren",
      PhoneNumberRequired: "Telefoonnummer is vereist",
      PhoneNumberInvalid: "Telefoonnummer is ongeldig",
      PaymentMethodNotAvailableLabel: "Betaalmethoden niet beschikbaar",
      ButtonConfirmLabel: "Bevestigen",
      BASIC_CARD: "Creditcard",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Betaal met uw bank",
      VOLT: "Betaal met uw bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Betaal met uw bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "Bevestig het omgerekende bedrag.",
      customSelectPlaceHolderpaymentMethod: "Selecteer methode",
      customSelectPlaceHolderdocumentType: "Selecteer documenttype",
      customSelectPlaceHoldercryptoCurrency: "Selecteer crypto-valuta",
      blockchainValuePlaceHolder: "Selecteer de blockchain-valuta",
      paymentMethod: "Betaalmethode",
      documentType: "Documenttype",
      documentNumber: "Documentnummer",
      cryptoCurrency: "Crypto-valuta",
      accountNumber: "Rekeningnummer",
      maskedPanMessage: "Bedrag wordt afgetrokken van:",
      endingIn: "eindigt op",
      descriptionLabel: "Beschrijving:",
      invalidCVV: "Ongeldige CVV",
      invalidOTP: "Ongeldige OTP",
      OTPRequired: "OTP is vereist",
      PasswordRequired: "Wachtwoord is vereist",
      invalidPassword: "Ongeldig wachtwoord",
      CardExpiryDateInvalid: "Ongeldige of verlopen datum",
      IN_PROGRESS: "IN UITVOERING",
      CANCELLED: "GEANNULEERD",
      DECLINED: "GEWEIGERD",
      COMPLETED: "VOLTOOID",
      backToShopButtonLabel: "Terug naar de website",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Stortingsbedrag:",
      debitAmountLabel: "Debetbedrag:",
      validationRequired: "Het veld {{ fieldName }} is vereist",
      validationMaxLength: "De maximale lengte is { { maxLength }} tekens",
      cryptoWarningMessage_1:
        "Als u een ander bedrag verzendt, accepteert het systeem het toch!",
      sendAmountInToAddress:
        "Verzend {{ bedrag }} in één betaling naar het volgende adres: {{ adres }}",
      copyToClipboard: "Kopieer adres naar klembord",
      copied: "Gekopieerd",
      scanAddressOr: "Scan het portemonnee-adres of",
      amountToPay: "Te betalen",
      commissionLabel: "Commissie:",
      walletDepositMessage:
        "Gebruik het volgende adres om de storting te voltooien:",
      ButtonConfirmCascadingLabel:
        "Probeer het opnieuw met een andere provider",
      ButtonConfirmCascadingTimeoutLabel: "Zal een andere provider proberen",
      cascadingInstructions:
        "Uw transactie is geweigerd, maar maak u geen zorgen, want we hebben een andere provider om mee te proberen. Sluit deze pagina niet.",
      errorCode:
        "Geachte klant, helaas is er geen beschikbare betalingsoptie voor dit scenario. Zorg ervoor dat de volgende params worden ondersteund: Kaarttype, BIN-land, bedrag en valuta Neem contact op met de handelaar of probeer een andere kaart te gebruiken.",
      TERMINAL_NOT_FOUND: `Beste klant, helaas is er geen betaalmogelijkheid beschikbaar voor dit scenario. Gebruik alstublieft een andere betaalmethode of een andere kaart.`,
      saveCardLabel: "Wil je de kaart opslaan?",
      addNewCard: "Voeg een nieuwe kaart toe",
      accept: "Accepteren",
      validationInvalidCharactersInName:
        "Gebruik ten minste twee Latijnse symbolen",
      validationExactlyTwoWordsRequiredInName:
        "De naam van de kaarthouder moet bestaan uit twee tot vier woorden met ten minste twee letters.",
      validationNotBlank: "Het veld {{ veldnaam }} kan niet leeg zijn",
      receiptBtn: "Ontvang ontvangstbewijs",
      progress:
        "Even geduld, uw transactie wordt verwerkt. Sluit deze pagina niet",
      verifyCard: "Verifieer kaart",
      withdraw: "Terugtrekken",
    },
  },
  de: {
    translation: {
      ExpiresEnd: "Ablaufdatum",
      cardHolderLabel: "Name des Karteninhabers",
      cardHolderPlaceHolder: "Name auf der Karte",
      cardPanLabel: "Kartennummer",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Ablaufdatum",
      CardExpiryPlaceholder: "MM/YY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Einmalpasswort",
      Password: "Passwort",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Menge",
      AmountPlaceholder: "Betrag eingeben",
      ButtonSubmitLabel: "JETZT BEZAHLEN",
      SelectPaymentMethodLabel: "Zahlungsmethode auswählen",
      SelectPredefineAmountLabel: "Betrag auswählen",
      ManualAmountLabel: "Verwenden Sie einen anderen Betrag",
      customerCitizenshipCountryCodeLabel: "Staatsbürgerschaft",
      customerCitizenshipCountryCodePlaceHolder: "Land der Staatsangehörigkeit",
      billingCountryCodeLabel: "Land",
      billingCountryCodePlaceHolder: "Land auswählen",
      customerFirstNameLabel: "Vorname",
      customerFirstNamePlaceHolder: "Vorname",
      customerLastNameLabel: "Nachname",
      customerLastNamePlaceHolder: "Nachname",
      billingAddressLine1Label: "Adresse Zeile 1",
      billingAddressLine1PlaceHolder: "Adresse Zeile 1",
      billingCityLabel: "Stadt",
      billingCityPlaceHolder: "Stadt",
      billingStateLabel: "Bundesland",
      billingStatePlaceHolder: "Bundesland",
      billingPostalCodeLabel: "Postleitzahl",
      billingPostalCodePlaceHolder: "Postleitzahl",
      customerDateOfBirthLabel: "Geburtsdatum",
      customerDateOfBirthPlaceHolder: "Geburtsdatum",
      customerEmailLabel: "E-Mail",
      customerEmailPlaceHolder: "Geben Sie Ihre E-Mail-Adresse ein",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Persönliche Identifikation",
      customerPersonalIdPlaceHolder: "Identifikationsnummer",
      billingAddressLine2Label: "Adresse Zeile 2",
      billingAddressLine2PlaceHolder: "Adresse Zeile 2",
      CardNumberRequired: "Kartennummer ist erforderlich",
      CardNumberInvalid: "Ungültige Kartennummer",
      CardExpiryDateRequired: "Ablaufdatum ist erforderlich",
      CVVRequired: "Der Sicherheitscode ist erforderlich",
      customerAccountNumberLabel: "Kontonummer",
      customerAccountNumberPlaceHolder: "Geben Sie Ihr Konto",
      AmountRequiredLabel: "Betrag ist erforderlich",
      AmountInvalidLabel: "Betrag ist ungültig",
      CancelLabel: "Abbrechen",
      PhoneNumberRequired: "Telefonnummer ist erforderlich",
      PhoneNumberInvalid: "Die Telefonnummer ist ungültig",
      PaymentMethodNotAvailableLabel: "Nicht verfügbare Zahlungsmethoden",
      ButtonConfirmLabel: "Bestätigen",
      BASIC_CARD: "Kreditkarte",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Bezahlen Sie mit Ihrer Bank",
      VOLT: "Bezahlen Sie mit Ihrer Bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Bezahlen Sie mit Ihrer Bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "Bitte bestätigen Sie den umgerechneten Betrag.",
      customSelectPlaceHolderpaymentMethod: "Select -Methode",
      customSelectPlaceHolderdocumentType: "Wählen Sie einen Dokumenttyp",
      customSelectPlaceHoldercryptoCurrency: "Wählen Sie die Kryptowährung aus",
      blockchainValuePlaceHolder: "Wählen Sie den Blockchain-Wert aus",
      paymentMethod: "Zahlungsmethode",
      documentType: "Dokumententyp",
      documentNumber: "Dokumentnummer",
      cryptoCurrency: "Kryptowährung",
      accountNumber: "Kontonummer",
      maskedPanMessage: "Betrag wird abgezogen von :",
      endingIn: "Endet in",
      descriptionLabel: "Beschreibung:",
      invalidCVV: "Ungültiger CVV",
      invalidOTP: "Ungültiger OTP",
      OTPRequired: "OTP ist erforderlich",
      PasswordRequired: "Passwort ist erforderlich",
      invalidPassword: "Ungültiges Passwort",
      CardExpiryDateInvalid: "Ungültiges oder abgelaufenes Datum",
      IN_PROGRESS: "IN BEARBEITUNG",
      CANCELLED: "STORNIERT",
      DECLINED: "ABGELEHNT",
      COMPLETED: "ABGESCHLOSSEN",
      backToShopButtonLabel: "Zurück zur Website",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Einzahlungsbetrag:",
      debitAmountLabel: "Belastungsbetrag:",
      validationRequired: "Das Feld {{fieldName}} ist erforderlich",
      validationMaxLength: "Die maximale Länge beträgt {{maxLength}} Zeichen",
      cryptoWarningMessage_1:
        "Wenn Sie einen anderen Betrag senden, akzeptiert das System ihn trotzdem!",
      sendAmountInToAddress:
        "Senden Sie {{amount}} in einer Zahlung an die folgende Adresse: {{address}}",
      copyToClipboard: "Adresse in die Zwischenablage kopieren",
      copied: "Kopiert",
      scanAddressOr: "Scannen Sie die Wallet-Adresse oder",
      amountToPay: "Zu zahlen",
      commissionLabel: "Kommission:",
      walletDepositMessage:
        "Bitte fahren Sie mit der Einzahlung fort, indem Sie die folgende Adresse verwenden:",
      ButtonConfirmCascadingLabel:
        "Versuchen Sie es erneut mit einem anderen Anbieter",
      ButtonConfirmCascadingTimeoutLabel:
        "Wird einen anderen Anbieter ausprobieren in ",
      cascadingInstructions:
        "Ihre Transaktion wurde abgelehnt, aber keine Sorge, da wir einen anderen Anbieter zum Ausprobieren haben. Bitte schließen Sie diese Seite nicht.",
      errorCode:
        "Sehr geehrter Kunde, leider gibt es für dieses Szenario keine Zahlungsoption. Bitte stellen Sie sicher, dass die folgenden Parameter unterstützt werden: Kartentyp, BIN Land, Betrag und Währung Bitte kontaktieren Sie den Händler oder versuchen Sie, eine andere Karte zu verwenden.",
      TERMINAL_NOT_FOUND: `Sehr geehrter Kunde, leider gibt es für dieses Szenario keine verfügbare Zahlungsmöglichkeit. Bitte verwenden Sie eine andere Zahlungsmethode oder eine andere Karte.`,
      saveCardLabel: "Möchten Sie die Karte speichern?",
      addNewCard: "Neue Karte hinzufügen",
      accept: "Akzeptieren",
      validationInvalidCharactersInName:
        "Bitte verwenden Sie mindestens zwei lateinische Symbole",
      validationExactlyTwoWordsRequiredInName:
        "Der Name des Karteninhabers muss aus zwei bis vier Wörtern mit mindestens zwei Buchstaben bestehen",
      validationNotBlank: "Das Feld {{fieldName}} kann nicht leer sein",
      receiptBtn: "Quittung erhalten",
      progress:
        "Bitte warten Sie, um Ihre Transaktion zu bearbeiten. Schließen Sie diese Seite nicht",
      verifyCard: "Karte verifizieren",
      withdraw: "Auszahlung",
    },
  },
  ar: {
    translation: {
      ExpiresEnd: "تاريخ انتهاء الصلاحية",
      cardHolderLabel: "اسم صاحب البطاقة",
      cardHolderPlaceHolder: "الاسم على البطاقة",
      cardPanLabel: "رقم البطاقة",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "تاريخ انتهاء الصلاحية",
      CardExpiryPlaceholder: "شهر/سنة",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "كلمة مرور لمرة واحدة",
      Password: "كلمة مرور",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "المبلغ",
      AmountPlaceholder: "أدخل المبلغ",
      ButtonSubmitLabel: "ادفع الآن",
      SelectPaymentMethodLabel: "اختر طريقة الدفع",
      SelectPredefineAmountLabel: "اختر المبلغ",
      ManualAmountLabel: "استخدم مبلغًا مختلفًا",
      customerCitizenshipCountryCodeLabel: "الجنسية",
      customerCitizenshipCountryCodePlaceHolder: "بلد الجنسية",
      billingCountryCodeLabel: "بلد",
      billingCountryCodePlaceHolder: "اختر البلد",
      customerFirstNameLabel: "الاسم الأول",
      customerFirstNamePlaceHolder: "الاسم الأول",
      customerLastNameLabel: "الاسم الأخير",
      customerLastNamePlaceHolder: "الاسم الأخير",
      billingAddressLine1Label: "العنوان سطر 1",
      billingAddressLine1PlaceHolder: "العنوان سطر 1",
      billingCityLabel: "مدينة",
      billingCityPlaceHolder: "مدينة",
      billingStateLabel: "ولاية",
      billingStatePlaceHolder: "ولاية",
      billingPostalCodeLabel: "الرمز البريدي",
      billingPostalCodePlaceHolder: "الرمز البريدي",
      customerDateOfBirthLabel: "تاريخ الولادة",
      customerDateOfBirthPlaceHolder: "تاريخ الولادة",
      customerEmailLabel: "البريد الإلكتروني",
      customerEmailPlaceHolder: "أدخل بريدك الإلكتروني",
      customerPhoneLabel: "الهاتف",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "التعريف الشخصي",
      customerPersonalIdPlaceHolder: "رقم التعريف",
      billingAddressLine2Label: "العنوان سطر 2",
      billingAddressLine2PlaceHolder: "العنوان سطر 2",
      CardNumberRequired: "رقم البطاقة مطلوب",
      CardNumberInvalid: "رقم بطاقة غير صالح",
      CardExpiryDateRequired: "تاريخ انتهاء الصلاحية مطلوب",
      CVVRequired: "مطلوب رمز أمني",
      customerAccountNumberLabel: "رقم الحساب",
      customerAccountNumberPlaceHolder: "أدخل حسابك",
      AmountRequiredLabel: "المبلغ مطلوب",
      AmountInvalidLabel: "المبلغ غير صالح",
      CancelLabel: "إلغاء",
      PhoneNumberRequired: "الرقم الهاتفي مطلوب",
      PhoneNumberInvalid: "الرقم الهاتفي غير صالح",
      PaymentMethodNotAvailableLabel: "طرق الدفع غير متاحة",
      ButtonConfirmLabel: "تأكيد",
      BASIC_CARD: "بطاقة ائتمانية",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "ادفع مع البنك الذي تتعامل معه",
      VOLT: "ادفع مع البنك الذي تتعامل معه",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "ادفع مع البنك الذي تتعامل معه",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "يرجى تأكيد المبلغ المحول.",
      customSelectPlaceHolderpaymentMethod: "انتقِ طريقة",
      customSelectPlaceHolderdocumentType: "اختر نوع المستند",
      customSelectPlaceHoldercryptoCurrency: "حدد العملة المشفرة",
      blockchainValuePlaceHolder: "اختر قيمة البلوكشين",
      paymentMethod: "طريقة الدفع",
      documentType: "نوع المستند",
      documentNumber: "رقم المستند",
      cryptoCurrency: "العملة المشفرة",
      accountNumber: "رقم الحساب",
      maskedPanMessage: "سيخصم المبلغ من:",
      endingIn: "المنتهية في",
      descriptionLabel: "الوصف:",
      invalidCVV: "CVV غير صالح",
      invalidOTP: "OTP غير صالح",
      OTPRequired: "مطلوب OTP",
      PasswordRequired: "كلمة المرور مطلوبة",
      invalidPassword: "كلمة مرور غير صالحة",
      CardExpiryDateInvalid: "تاريخ غير صالح أو منتهي الصلاحية",
      IN_PROGRESS: "قيد التقدم",
      CANCELLED: "ملغاة",
      DECLINED: "رُفِضَ",
      COMPLETED: "انتهاء",
      backToShopButtonLabel: "العودة إلى الموقع الإلكتروني",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "مبلغ الإيداع:",
      debitAmountLabel: "Debit مبلغ:",
      validationRequired: "الحقل {{fieldName}} مطلوب",
      validationMaxLength: "الطول الأقصى هو {{maxLength}} حرفاً",
      cryptoWarningMessage_1:
        "إذا قمت بإرسال أي مبلغ آخر، فسيقوم النظام بقبوله على أي حال!",
      sendAmountInToAddress:
        "أرسل {{المبلغ}} في دفعة واحدة إلى العنوان التالي: {{العنوان}}",
      copyToClipboard: "نسخ العنوان إلى حافظة",
      copied: "تم نسخه",
      scanAddressOr: "مسح عنوان المحفظة أو",
      amountToPay: "لدفع",
      commissionLabel: "اللجنة:",
      walletDepositMessage: "يرجى الشروع في الإيداع باستخدام العنوان التالي:",
      ButtonConfirmCascadingLabel: "حاول مرة أخرى باستخدام مزود آخر",
      ButtonConfirmCascadingTimeoutLabel: "سوف تجرب مزود آخر في ",
      cascadingInstructions:
        "تم رفض صفقتك، لكن لا تقلق لأن لدينا مزودًا آخر نجربه. من فضلك، لا تغلق هذه الصفحة.",
      errorCode:
        "عزيزي العميل، لسوء الحظ، لا يوجد خيار دفع متاح لهذا السيناريو. يرجى التأكد من دعم البارامات التالية: نوع البطاقة، بلد BIN، المبلغ والعملة يرجى الاتصال بالتاجر أو محاولة استخدام بطاقة أخرى.",
      TERMINAL_NOT_FOUND: `عزيزي العميل، للأسف، لا توجد طريقة دفع متاحة لهذا السيناريو. يُرجى استخدام طريقة دفع أخرى أو بطاقة أخرى.`,
      saveCardLabel: "هل تريد حفظ البطاقة؟",
      addNewCard: "أضف بطاقة جديدة",
      accept: "قبول",
      validationInvalidCharactersInName:
        "يرجى استخدام رمزين لاتينيين على الأقل",
      validationExactlyTwoWordsRequiredInName:
        "يجب أن يتألف اسم حامل البطاقة من كلمتين إلى أربع كلمات من حرفين على الأقل",
      validationNotBlank: "حقل {{fieldName}} لا يمكن أن يكون فارغًا",
      receiptBtn: "احصل على الإيصال",
      progress: "من فضلك انتظر، معالجة صفقتك. لا تغلق هذه الصفحة",
      verifyCard: "تحقق من البطاقة",
      withdraw: "سحب",
    },
  },
  pt: {
    translation: {
      ExpiresEnd: "Data de expiração",
      cardHolderLabel: "Nome do titular",
      cardHolderPlaceHolder: "Nome no cartão",
      cardPanLabel: "Número do cartão",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Data de expiração",
      CardExpiryPlaceholder: "MM/AA",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Senha única",
      Password: "Senha",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Valor",
      AmountPlaceholder: "Insira o valor",
      ButtonSubmitLabel: "PAGAR AGORA",
      SelectPaymentMethodLabel: "Selecione o método de pagamento",
      SelectPredefineAmountLabel: "Selecione um valor",
      ManualAmountLabel: "Use um valor diferente",
      customerCitizenshipCountryCodeLabel: "Cidadania",
      customerCitizenshipCountryCodePlaceHolder: "País de cidadania",
      billingCountryCodeLabel: "País",
      billingCountryCodePlaceHolder: "Selecione o país",
      customerFirstNameLabel: "Primeiro nome",
      customerFirstNamePlaceHolder: "Primeiro nome",
      customerLastNameLabel: "Último nome",
      customerLastNamePlaceHolder: "Último nome",
      billingAddressLine1Label: "Endereço linha 1",
      billingAddressLine1PlaceHolder: "Endereço linha 1",
      billingCityLabel: "Cidade",
      billingCityPlaceHolder: "Cidade",
      billingStateLabel: "Estado",
      billingStatePlaceHolder: "Estado",
      billingPostalCodeLabel: "Código postal",
      billingPostalCodePlaceHolder: "Código postal",
      customerDateOfBirthLabel: "Data de nascimento",
      customerDateOfBirthPlaceHolder: "Data de nascimento",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Insira seu email",
      customerPhoneLabel: "Telefone",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Identificação pessoal",
      customerPersonalIdPlaceHolder: "Número de identificação",
      billingAddressLine2Label: "Endereço linha 2",
      billingAddressLine2PlaceHolder: "Endereço linha 2",
      CardNumberRequired: "Número do cartão é obrigatório",
      CardNumberInvalid: "Número do cartão inválido",
      CardExpiryDateRequired: "Data de expiração é obrigatória",
      CVVRequired: "Código de segurança é obrigatório",
      customerAccountNumberLabel: "Número da conta",
      customerAccountNumberPlaceHolder: "Insira sua conta",
      AmountRequiredLabel: "O valor é obrigatório",
      AmountInvalidLabel: "Valor inválido",
      CancelLabel: "Cancelar",
      PhoneNumberRequired: "Número de telefone é obrigatório",
      PhoneNumberInvalid: "Número de telefone inválido",
      PaymentMethodNotAvailableLabel: "Métodos de pagamento não disponíveis",
      ButtonConfirmLabel: "Confirmar",
      BASIC_CARD: "Cartão de Crédito",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Pagamento Local",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pague com seu banco",
      VOLT: "Pague com seu banco",
      B2BINPAY: "B2BinPay (Cripto)",
      B2BINPAYV2: "B2BinPay (Cripto)",
      CLICK: "Pague com seu banco",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Depósito em cripto",
      ConfirmationMessage: "Por favor, confirme o valor convertido.",
      customSelectPlaceHolderpaymentMethod: "Selecione o método",
      customSelectPlaceHolderdocumentType: "Selecione o tipo de documento",
      customSelectPlaceHoldercryptoCurrency: "Selecione a criptomoeda",
      blockchainValuePlaceHolder: "Selecione o valor da blockchain",
      paymentMethod: "Método de pagamento",
      documentType: "Tipo de documento",
      documentNumber: "Número do documento",
      cryptoCurrency: "Criptomoeda",
      accountNumber: "Número da conta",
      maskedPanMessage: "O valor será deduzido de:",
      endingIn: "terminando em",
      descriptionLabel: "Descrição:",
      invalidCVV: "CVV inválido",
      invalidOTP: "OTP inválido",
      OTPRequired: "OTP é obrigatório",
      PasswordRequired: "Senha é obrigatória",
      invalidPassword: "Senha inválida",
      CardExpiryDateInvalid: "Data inválida ou expirada",
      IN_PROGRESS: "EM PROGRESSO",
      CANCELLED: "CANCELADO",
      DECLINED: "RECUSADO",
      COMPLETED: "COMPLETO",
      backToShopButtonLabel: "Retornar ao site",
      minAmountLabel: "Mín:",
      maxAmountLabel: "Máx:",
      depositAmountLabel: "Valor do depósito:",
      debitAmountLabel: "Valor de débito:",
      validationRequired: "O campo {{fieldName}} é obrigatório",
      validationMaxLength: "O comprimento máximo é de {{maxLength}} caracteres",
      cryptoWarningMessage_1:
        "Se você enviar qualquer outro valor, o sistema o aceitará de qualquer maneira!",
      sendAmountInToAddress:
        "Envie {{amount}} em um único pagamento para o seguinte endereço: {{address}}",
      copyToClipboard: "Copiar endereço para a área de transferência",
      copied: "Copiado",
      scanAddressOr: "Escaneie o endereço da carteira ou",
      amountToPay: "A pagar",
      commissionLabel: "Comissão:",
      walletDepositMessage:
        "Por favor, prossiga com o depósito usando o seguinte endereço:",
      ButtonConfirmCascadingLabel: "Tente novamente usando outro provedor",
      ButtonConfirmCascadingTimeoutLabel: "Tentará outro provedor em",
      cascadingInstructions:
        "Sua transação foi recusada, mas não se preocupe, temos outro provedor para tentar. Por favo, não feche esta página.",
      errorCode:
        "Caro cliente, infelizmente, não há opção de pagamento disponível para este cenário. Certifique-se de que os seguintes parâmetros sejam suportados: Tipo de Cartão, País do BIN, Valor e Moeda. Por favor, contate o comerciante ou tente usar outro cartão.",
      TERMINAL_NOT_FOUND:
        "Prezado cliente, Infelizmente não há opção de pagamento disponível para este cenário. Utilize outro método de pagamento ou outro cartão.",
      saveCardLabel: "Deseja salvar o cartão?",
      addNewCard: "Adicionar novo cartão",
      accept: "Aceitar",
      validationInvalidCharactersInName:
        "Por favor, use pelo menos dois símbolos latinos",
      validationExactlyTwoWordsRequiredInName:
        "O nome do titular do cartão deve consistir em exatamente duas palavras",
      validationNotBlank: "O campo {{fieldName}} não pode estar em branco",
      receiptBtn: "Obter recibo",
      progress:
        "Por favor, aguarde, processando sua transação. Não feche esta página",
      verifyCard: "Verificar cartão",
      withdraw: "Retirar",
    },
  },
  lv: {
    translation: {
      ExpiresEnd: "Derīguma termiņš",
      cardHolderLabel: "Kartes turētāja vārds un uzvārds",
      cardHolderPlaceHolder: "Nosaukums uz kartes",
      cardPanLabel: "Kartes numurs",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Derīguma termiņš",
      CardExpiryPlaceholder: "MM/GGGGG",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Vienreizēja parole",
      Password: "Parole",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Summa",
      AmountPlaceholder: "Ievadiet summu",
      ButtonSubmitLabel: "MAKSĀT TAGAD",
      SelectPaymentMethodLabel: "Izvēlieties maksājuma veidu",
      SelectPredefineAmountLabel: "Izvēlieties summu",
      ManualAmountLabel: "Izmantot citu summu",
      customerCitizenshipCountryCodeLabel: "Valstspiederība",
      customerCitizenshipCountryCodePlaceHolder: "Valsts pilsonība",
      billingCountryCodeLabel: "Valsts",
      billingCountryCodePlaceHolder: "Izvēlieties valsti",
      customerFirstNameLabel: "Vārds",
      customerFirstNamePlaceHolder: "Vārds",
      customerLastNameLabel: "Uzvārds",
      customerLastNamePlaceHolder: "Uzvārds",
      billingAddressLine1Label: "Adreses 1. līnija",
      billingAddressLine1PlaceHolder: "Adreses līnija 1",
      billingCityLabel: "Pilsēta",
      billingCityPlaceHolder: "Pilsēta",
      billingStateLabel: "Valsts",
      billingStatePlaceHolder: "Valsts",
      billingPostalCodeLabel: "Pasta indekss",
      billingPostalCodePlaceHolder: "Pasta indekss",
      customerDateOfBirthLabel: "Dzimšanas datums",
      customerDateOfBirthPlaceHolder: "Dzimšanas datums",
      customerEmailLabel: "E-pasts",
      customerEmailPlaceHolder: "Ievadiet savu e-pastu",
      customerPhoneLabel: "Tālrunis",
      customerPhonePlaceHolder: "+371 1234567",
      customerPersonalIdLabel: "Personas identifikācija",
      customerPersonalIdPlaceHolder: "Identifikācijas numurs",
      billingAddressLine2Label: "Adreses 2. rinda",
      billingAddressLine2PlaceHolder: "Adreses 2. rinda",
      CardNumberRequired: "Nepieciešamais kartes numurs",
      CardNumberInvalid: "Nepareizs kartes numurs",
      CardExpiryDateRequired: "Nepieciešams derīguma termiņš",
      CVVRequired: "Nepieciešams drošības kods",
      customerAccountNumberLabel: "Konta numurs",
      customerAccountNumberPlaceHolder: "Ievadiet savu kontu",
      AmountRequiredLabel: "Nepieciešamā summa",
      AmountInvalidLabel: "Nederīga summa",
      CancelLabel: "Atcelšana",
      PhoneNumberRequired: "Nepieciešams tālruņa numurs",
      PhoneNumberInvalid: "Nepareizs tālruņa numurs",
      PaymentMethodNotAvailableLabel: "Maksājuma metodes nav pieejamas",
      ButtonConfirmLabel: "Apstiprināt",
      BASIC_CARD: "Kredītkarte",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Maksājiet, izmantojot savu banku",
      VOLT: "Maksājiet, izmantojot savu banku",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Maksājiet, izmantojot savu banku",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Kriptovalūtas depozīts",
      ConfirmationMessage: "Lūdzu, apstipriniet konvertēto summu.",
      customSelectPlaceHolderpaymentMethod: "Izvēlieties metodi",
      customSelectPlaceHolderdocumentType: "Izvēlieties dokumenta veidu",
      customSelectPlaceHoldercryptoCurrency: "Izvēlieties kriptovalūtu",
      blockchainValuePlaceHolder: "Izvēlieties blokķēdes vērtību",
      paymentMethod: "Maksājuma metode",
      documentType: "Dokumenta veids",
      documentNumber: "Dokumenta numurs",
      cryptoCurrency: "Kriptovalūta",
      accountNumber: "Konta numurs",
      maskedPanMessage: "Summa tiks debetēta no:",
      endingIn: "Beidzas plkst.",
      descriptionLabel: "Apraksts:",
      invalidCVV: "Nepareizs CVV",
      invalidOTP: "Nederīgs OTP",
      OTPRequired: "Nepieciešams OTP",
      PasswordRequired: "Nepieciešama parole",
      invalidPassword: "Nederīga parole",
      CardExpiryDateInvalid: "Nepareizs vai beidzies derīguma termiņš",
      IN_PROGRESS: "PROCESĀ",
      CANCELLED: "ATCELTS",
      DECLINED: "ATCELTS",
      COMPLETED: "NOBEIGTS",
      backToShopButtonLabel: "Atgriezties vietnē",
      minAmountLabel: "Min:",
      maxAmountLabel: "Maks:",
      depositAmountLabel: "Depozīta summa:",
      debitAmountLabel: "Izņemšanas summa:",
      validationRequired: "{{fieldName}} lauks ir obligāts",
      validationMaxLength: "Maksimālais garums {{maxLength}} rakstzīmes.",
      cryptoWarningMessage_1: "Ja nosūtīsiet jebkuru citu summu, sistēma to pieņems jebkurā gadījumā!",
      sendAmountInToAddress: "Nosūtiet {{amount}} vienu maksājumu uz šādu adresi {{address}}",
      copyToClipboard: "Nokopējiet adresi uz starpliktuvi",
      copied: "Kopēts",
      scanAddressOr: "Skenējiet maka adresi vai",
      amountToPay: "Maksājumam",
      commissionLabel: "Maksa:",
      walletDepositMessage: "Lūdzu, turpiniet iemaksu, izmantojot šādu adresi:",
      ButtonConfirmCascadingLabel: "Mēģiniet vēlreiz, izmantojot citu interneta pakalpojumu sniedzēju",
      ButtonConfirmCascadingTimeoutLabel: "Mainīt interneta pakalpojumu sniedzēju, izmantojot",
      cascadingInstructions: "Jūsu darījums tika noraidīts, bet neuztraucieties, mēs varam izmēģināt citu pakalpojumu sniedzēju. Lūdzu, neaizveriet šo lapu.",
      errorCode: "Cienījamais klients, diemžēl nav pieejamas nekādas maksājumu iespējas. Lūdzu, pārliecinieties, vai tiek atbalstītas šādas iespējas: Kartes veids, valsts BIN, summa un valūta. Lūdzu, sazinieties ar tirgotāju vai mēģiniet izmantot citu karti.",
      TERMINAL_NOT_FOUND: "Cienījamais klient, diemžēl nav pieejamas nekādas maksājumu iespējas. Lūdzu, izmantojiet citu maksājumu veidu vai citu karti.",
      saveCardLabel: "Vai vēlaties saglabāt savu karti?",
      addNewCard: "Pievienojiet jaunu karti",
      accept: "Pieņemt",
      validationInvalidCharactersInName: "Lūdzu, izmantojiet vismaz divus latīņu burtus",
      validationExactlyTwoWordsRequiredInName: "Kartes īpašnieka vārdam jāsastāv tieši no diviem vārdiem.",
      validationNotBlank: "{{fieldName}} lauks nedrīkst būt tukšs.",
      receiptBtn: "Saņemt kvīti",
      progress: "Lūdzu, uzgaidiet, jūsu darījums tiek apstrādāts. Neaizveriet šo lapu",
      verifyCard: "Pārbaudīt karti",
      withdraw: "Izņemt"
    },
  },
  ja: {
    translation: {
      ExpiresEnd: "有効期限",
      cardHolderLabel: "カード所有者名",
      cardHolderPlaceHolder: "カード名義",
      cardPanLabel: "カード番号",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "有効期限",
      CardExpiryPlaceholder: "MM/YY年",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "ワンタイムパスワード",
      Password: "パスワード",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "金額",
      AmountPlaceholder: "金額を入力",
      ButtonSubmitLabel: "今すぐ支払う",
      SelectPaymentMethodLabel: "支払い方法を選択",
      SelectPredefineAmountLabel: "金額を選択",
      ManualAmountLabel: "別の金額を選択",
      customerCitizenshipCountryCodeLabel: "国籍",
      customerCitizenshipCountryCodePlaceHolder: "国籍",
      billingCountryCodeLabel: "国",
      billingCountryCodePlaceHolder: "国を選択",
      customerFirstNameLabel: "名",
      customerFirstNamePlaceHolder: "名",
      customerLastNameLabel: "名字",
      customerLastNamePlaceHolder: "名字",
      billingAddressLine1Label: "住所1行目",
      billingAddressLine1PlaceHolder: "住所1行目",
      billingCityLabel: "市町村名",
      billingCityPlaceHolder: "市町村",
      billingStateLabel: "都道府県",
      billingStatePlaceHolder: "都道府県",
      billingPostalCodeLabel: "郵便番号",
      billingPostalCodePlaceHolder: "郵便番号",
      customerDateOfBirthLabel: "生年月日",
      customerDateOfBirthPlaceHolder: "生年月日",
      customerEmailLabel: "Eメール",
      customerEmailPlaceHolder: "メールアドレスを入力してください",
      customerPhoneLabel: "電話番号",
      customerPhonePlaceHolder: "+81 1234567",
      customerPersonalIdLabel: "個人識別番号",
      customerPersonalIdPlaceHolder: "識別番号",
      billingAddressLine2Label: "住所2行目",
      billingAddressLine2PlaceHolder: "住所2行目",
      CardNumberRequired: "カード番号が必要",
      CardNumberInvalid: "カード番号に誤りがある",
      CardExpiryDateRequired: "有効期限が必要",
      CVVRequired: "セキュリティコードが必要です",
      customerAccountNumberLabel: "口座番号",
      customerAccountNumberPlaceHolder: "口座を入力してください",
      AmountRequiredLabel: "必要な金額",
      AmountInvalidLabel: "無効な金額",
      CancelLabel: "キャンセル",
      PhoneNumberRequired: "電話番号必須",
      PhoneNumberInvalid: "電話番号が正しくありません",
      PaymentMethodNotAvailableLabel: "お支払い方法がご利用いただけません",
      ButtonConfirmLabel: "確認",
      BASIC_CARD: "クレジットカード",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "銀行を通して支払う",
      VOLT: "銀行を通して支払う",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "銀行を通して支払う",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "暗号通貨の入金",
      ConfirmationMessage: "換金額をご確認ください。",
      customSelectPlaceHolderpaymentMethod: "方法を選択",
      customSelectPlaceHolderdocumentType: "書類の種類を選択",
      customSelectPlaceHoldercryptoCurrency: "暗号通貨を選択",
      blockchainValuePlaceHolder: "ブロックチェーン価値を選択",
      paymentMethod: "支払い方法",
      documentType: "文書タイプ",
      documentNumber: "文書番号",
      cryptoCurrency: "暗号通貨",
      accountNumber: "口座番号",
      maskedPanMessage: "引き落とし元:",
      endingIn: "終了日時",
      descriptionLabel: "説明:",
      invalidCVV: "無効なCVV",
      invalidOTP: "無効なOTP",
      OTPRequired: "OTPが必要",
      PasswordRequired: "パスワードが必要",
      invalidPassword: "無効なパスワード",
      CardExpiryDateInvalid: "有効期限切れ",
      IN_PROGRESS: "処理中",
      CANCELLED: "キャンセル",
      DECLINED: "拒否された",
      COMPLETED: "終了",
      backToShopButtonLabel: "サイトに戻る",
      minAmountLabel: "最小:",
      maxAmountLabel: "最大:",
      depositAmountLabel: "入金額:",
      debitAmountLabel: "出金額:",
      validationRequired: "{{fieldName}}フィールドは必須です。",
      validationMaxLength: "最大長{{maxLength}}文字。",
      cryptoWarningMessage_1: "それ以外の金額を送信した場合でも、システムは受け入れます！",
      sendAmountInToAddress: "1回の支払いにつき{{amount}}を次のアドレス{{address}}に送信してください。",
      copyToClipboard: "アドレスをクリップボードにコピー",
      copied: "コピー",
      scanAddressOr: "ウォレットアドレスをスキャンするか",
      amountToPay: "支払い",
      commissionLabel: "手数料:",
      walletDepositMessage: "次のアドレスで入金を続けてください:",
      ButtonConfirmCascadingLabel: "別のISPで再度お試しください。",
      ButtonConfirmCascadingTimeoutLabel: "プロバイダーの変更",
      cascadingInstructions: "お取引は拒否されましたが、ご心配なく。このページは閉じないでください。",
      errorCode: "親愛なるお客様、残念ながらご利用可能なお支払い方法はありません。以下のオプションがサポートされていることを確認してください： カードの種類、国のBIN、金額、通貨をご確認ください。加盟店にお問い合わせいただくか、別のカードをお試しください。",
      TERMINAL_NOT_FOUND: "申し訳ございませんが、ご利用いただけるお支払い方法がございません。他のお支払い方法または他のカードをご利用ください。",
      saveCardLabel: "カードを維持しますか？",
      addNewCard: "新しいカードを追加する",
      accept: "受付",
      validationInvalidCharactersInName: "少なくとも2つのラテン文字を使用してください。",
      validationExactlyTwoWordsRequiredInName: "カード名義人の名前は正確に2つの単語で構成されている必要があります。",
      validationNotBlank: "{{fieldName}}フィールドを空にすることはできません。",
      receiptBtn: "レシートを受け取る",
      progress: "お取引は処理中です。このページを閉じないでください。",
      verifyCard: "カードを確認する",
      withdraw: "引き出す"
    },
  },
  hr: {
    translation: {
      ExpiresEnd: "Datum isteka",
      cardHolderLabel: "Ime vlasnika kartice",
      cardHolderPlaceHolder: "Ime na kartici",
      cardPanLabel: "Broj kartice",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Datum isteka",
      CardExpiryPlaceholder: "MM/GG",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Jednokratna lozinka",
      Password: "Lozinka",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Iznos",
      AmountPlaceholder: "Unesite iznos",
      ButtonSubmitLabel: "PLATI SADA",
      SelectPaymentMethodLabel: "Odaberite način plaćanja",
      SelectPredefineAmountLabel: "Odaberite iznos",
      ManualAmountLabel: "Upotrijebi drugi iznos",
      customerCitizenshipCountryCodeLabel: "Državljanstvo",
      customerCitizenshipCountryCodePlaceHolder: "Zemlja državljanstva",
      billingCountryCodeLabel: "Država",
      billingCountryCodePlaceHolder: "Odaberite zemlju",
      customerFirstNameLabel: "Ime",
      customerFirstNamePlaceHolder: "Ime",
      customerLastNameLabel: "Prezime",
      customerLastNamePlaceHolder: "Prezime",
      billingAddressLine1Label: "Adresna linija 1",
      billingAddressLine1PlaceHolder: "Adresna linija 1",
      billingCityLabel: "Grad",
      billingCityPlaceHolder: "Grad",
      billingStateLabel: "Država",
      billingStatePlaceHolder: "Država",
      billingPostalCodeLabel: "Poštanski broj",
      billingPostalCodePlaceHolder: "Poštanski broj",
      customerDateOfBirthLabel: "Datum rođenja",
      customerDateOfBirthPlaceHolder: "Datum rođenja",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Unesite svoj email",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+385 1234567",
      customerPersonalIdLabel: "Osobna identifikacija",
      customerPersonalIdPlaceHolder: "Identifikacijski broj",
      billingAddressLine2Label: "Adresna linija 2",
      billingAddressLine2PlaceHolder: "Adresna linija 2",
      CardNumberRequired: "Potreban broj kartice",
      CardNumberInvalid: "Nevažeći broj kartice",
      CardExpiryDateRequired: "Potreban datum isteka",
      CVVRequired: "Potreban sigurnosni kod",
      customerAccountNumberLabel: "Broj računa",
      customerAccountNumberPlaceHolder: "Unesite svoj račun",
      AmountRequiredLabel: "Potreban iznos",
      AmountInvalidLabel: "Nevažeći iznos",
      CancelLabel: "Otkaži",
      PhoneNumberRequired: "Potreban broj telefona",
      PhoneNumberInvalid: "Nevažeći broj telefona",
      PaymentMethodNotAvailableLabel: "Načini plaćanja nisu dostupni",
      ButtonConfirmLabel: "Potvrdi",
      BASIC_CARD: "Kreditna kartica",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Lokalna plaćanja",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Plaćajte preko svoje banke",
      VOLT: "Plaćajte preko svoje banke",
      B2BINPAY: "B2BinPay (Kripto)",
      B2BINPAYV2: "B2BinPay (Kripto)",
      CLICK: "Plaćajte preko svoje banke",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Depozit kriptovalute",
      ConfirmationMessage: "Molimo potvrdite konvertirani iznos.",
      customSelectPlaceHolderpaymentMethod: "Odaberite metodu",
      customSelectPlaceHolderdocumentType: "Odaberite vrstu dokumenta",
      customSelectPlaceHoldercryptoCurrency: "Odaberite kriptovalutu",
      blockchainValuePlaceHolder: "Odaberite vrijednost blockchaina",
      paymentMethod: "Način plaćanja",
      documentType: "Vrsta dokumenta",
      documentNumber: "Broj dokumenta",
      cryptoCurrency: "Kriptovaluta",
      accountNumber: "Broj računa",
      maskedPanMessage: "Iznos koji se tereti od:",
      endingIn: "završava sa",
      descriptionLabel: "Opis:",
      invalidCVV: "Nevažeći CVV",
      invalidOTP: "Nevažeći OTP",
      OTPRequired: "Potreban OTP",
      PasswordRequired: "Potrebna lozinka",
      invalidPassword: "Nevažeća lozinka",
      CardExpiryDateInvalid: "Nevažeći ili istekli datum",
      IN_PROGRESS: "U PROCESU",
      CANCELLED: "OTKAZANO",
      DECLINED: "ODBIJEN",
      COMPLETED: "ZAVRŠENO",
      backToShopButtonLabel: "Povratak na stranicu",
      minAmountLabel: "Min.:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Iznos depozita:",
      debitAmountLabel: "Iznos otpisa:",
      validationRequired: "{{fieldName}} polje je obavezno",
      validationMaxLength: "Maksimalna duljina {{maxLength}} znakova",
      cryptoWarningMessage_1: "Ako pošaljete bilo koji drugi iznos, sustav će ga svejedno prihvatiti!",
      sendAmountInToAddress: "Pošaljite {{amount}} u jednoj uplati na sljedeću adresu {{address}}",
      copyToClipboard: "Kopiraj adresu u međuspremnik",
      copied: "Kopirano",
      scanAddressOr: "Skenirajte adresu novčanika ili",
      amountToPay: "Za plaćanje",
      commissionLabel: "Komisija:",
      walletDepositMessage: "Molimo nastavite svoj depozit koristeći sljedeću adresu:",
      ButtonConfirmCascadingLabel: "Pokušajte ponovno s drugim pružateljem usluga",
      ButtonConfirmCascadingTimeoutLabel: "Promjena pružatelja usluga putem",
      cascadingInstructions: "Vaša transakcija je odbijena, ali ne brinite, imamo drugog pružatelja usluga za isprobati. Molimo vas da ne zatvarate ovu stranicu.",
      errorCode: "Poštovani kupče, nažalost, nema dostupnih opcija plaćanja. Provjerite jesu li podržani sljedeći parametri: vrsta kartice, BIN država, iznos i valuta. Molimo kontaktirajte prodavača ili pokušajte koristiti drugu karticu.",
      TERMINAL_NOT_FOUND: "Poštovani kupče, nažalost, nema dostupnih opcija plaćanja. Molimo koristite drugi način plaćanja ili drugu karticu.",
      saveCardLabel: "Želite spremiti karticu?",
      addNewCard: "Dodajte novu karticu",
      accept: "Prihvatiti",
      validationInvalidCharactersInName: "Molimo koristite najmanje dva latinična znaka",
      validationExactlyTwoWordsRequiredInName: "Ime vlasnika kartice mora se sastojati od točno dvije riječi",
      validationNotBlank: "{{fieldName}} polje ne može biti prazno",
      receiptBtn: "Dobiti potvrdu",
      progress: "Pričekajte, vaša transakcija je u obradi. Ne zatvaraj ovu stranicu",
      verifyCard: "Provjerite karticu",
      withdraw: "Povući",
    },
  },
  el: {
    translation: {
      ExpiresEnd: "Ημερομηνία λήξης",
      cardHolderLabel: "Όνομα του κατόχου της κάρτας",
      cardHolderPlaceHolder: "Όνομα στην κάρτα",
      cardPanLabel: "Αριθμός κάρτας",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Ημερομηνία λήξης",
      CardExpiryPlaceholder: "MM/YYY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Κωδικός πρόσβασης μιας χρήσης",
      Password: "Κωδικός πρόσβασης",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Ποσό",
      AmountPlaceholder: "Εισάγετε το ποσό",
      ButtonSubmitLabel: "ΠΛΗΡΩΣΤΕ ΤΩΡΑ",
      SelectPaymentMethodLabel: "Επιλέξτε μέθοδο πληρωμής",
      SelectPredefineAmountLabel: "Επιλέξτε ποσό",
      ManualAmountLabel: "Χρήση διαφορετικού ποσού",
      customerCitizenshipCountryCodeLabel: "Εθνικότητα",
      customerCitizenshipCountryCodePlaceHolder: "Χώρα υπηκοότητας",
      billingCountryCodeLabel: "Χώρα",
      billingCountryCodePlaceHolder: "Επιλέξτε χώρα",
      customerFirstNameLabel: "Όνομα",
      customerFirstNamePlaceHolder: "Όνομα",
      customerLastNameLabel: "Επώνυμο",
      customerLastNamePlaceHolder: "Επώνυμο",
      billingAddressLine1Label: "Γραμμή διεύθυνσης 1",
      billingAddressLine1PlaceHolder: "Γραμμή διεύθυνσης 1",
      billingCityLabel: "Πόλη",
      billingCityPlaceHolder: "Πόλη",
      billingStateLabel: "Πολιτεία",
      billingStatePlaceHolder: "Πολιτεία",
      billingPostalCodeLabel: "Ταχυδρομικός κώδικας",
      billingPostalCodePlaceHolder: "Ταχυδρομικός κώδικας",
      customerDateOfBirthLabel: "Ημερομηνία γέννησης",
      customerDateOfBirthPlaceHolder: "Ημερομηνία γέννησης",
      customerEmailLabel: "Ηλεκτρονικό ταχυδρομείο",
      customerEmailPlaceHolder: "Εισάγετε το email σας",
      customerPhoneLabel: "Τηλέφωνο",
      customerPhonePlaceHolder: "+30 1234567",
      customerPersonalIdLabel: "Προσωπική ταυτοποίηση",
      customerPersonalIdPlaceHolder: "Αριθμός αναγνώρισης",
      billingAddressLine2Label: "Γραμμή διεύθυνσης 2",
      billingAddressLine2PlaceHolder: "Γραμμή διεύθυνσης 2",
      CardNumberRequired: "Απαιτούμενος αριθμός κάρτας",
      CardNumberInvalid: "Λανθασμένος αριθμός κάρτας",
      CardExpiryDateRequired: "Απαιτείται ημερομηνία λήξης",
      CVVRequired: "Απαιτείται κωδικός ασφαλείας",
      customerAccountNumberLabel: "Αριθμός λογαριασμού",
      customerAccountNumberPlaceHolder: "Πληκτρολογήστε το λογαριασμό σας",
      AmountRequiredLabel: "Απαιτούμενο ποσό",
      AmountInvalidLabel: "Μη έγκυρο ποσό",
      CancelLabel: "Ακύρωση",
      PhoneNumberRequired: "Απαιτούμενος αριθμός τηλεφώνου",
      PhoneNumberInvalid: "Λανθασμένος αριθμός τηλεφώνου",
      PaymentMethodNotAvailableLabel: "Μη διαθέσιμες μέθοδοι πληρωμής",
      ButtonConfirmLabel: "Επιβεβαίωση",
      BASIC_CARD: "Πιστωτική κάρτα",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Τοπική Πληρωμή",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Πληρώστε μέσω της τράπεζάς σας",
      VOLT: "Πληρώστε μέσω της τράπεζάς σας",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Πληρώστε μέσω της τράπεζάς σας",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Κατάθεση κρυπτονομισμάτων",
      ConfirmationMessage: "Παρακαλούμε επιβεβαιώστε το μετατρεπόμενο ποσό.",
      customSelectPlaceHolderpaymentMethod: "Επιλέξτε μέθοδο",
      customSelectPlaceHolderdocumentType: "Επιλέξτε τύπο εγγράφου",
      customSelectPlaceHoldercryptoCurrency: "Επιλέξτε κρυπτονόμισμα",
      blockchainValuePlaceHolder: "Επιλέξτε την αξία της αλυσίδας μπλοκ",
      paymentMethod: "Μέθοδος πληρωμής",
      documentType: "Τύπος εγγράφου",
      documentNumber: "Αριθμός εγγράφου",
      cryptoCurrency: "Κρυπτονόμισμα",
      accountNumber: "Αριθμός λογαριασμού",
      maskedPanMessage: "Το ποσό θα χρεωθεί από:",
      endingIn: "Τελειώνει σε.",
      descriptionLabel: "Περιγραφή:",
      invalidCVV: "Μη έγκυρο CVV",
      invalidOTP: "Μη έγκυρο OTP",
      OTPRequired: "Απαιτείται OTP",
      PasswordRequired: "Απαιτείται κωδικός πρόσβασης",
      invalidPassword: "Μη έγκυρος κωδικός πρόσβασης",
      CardExpiryDateInvalid: "Λανθασμένη ή ληγμένη ημερομηνία",
      IN_PROGRESS: "ΣΕ ΔΙΑΔΙΚΑΣΙΑ",
      CANCELLED: "ΑΚΥΡΩΘΗΚΕ",
      DECLINED: "ΑΚΥΡΩΘΗΚΕ",
      COMPLETED: "ΛΗΞΗ",
      backToShopButtonLabel: "Επιστροφή στον ιστότοπο",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Ποσό κατάθεσης:",
      debitAmountLabel: "Ποσό ανάληψης:",
      validationRequired: "{{fieldName}} το πεδίο είναι υποχρεωτικό",
      validationMaxLength: "Μέγιστο μήκος {{maxLength}} χαρακτήρες.",
      cryptoWarningMessage_1: "Εάν στείλετε οποιοδήποτε άλλο ποσό, το σύστημα θα το δεχτεί ούτως ή άλλως!",
      sendAmountInToAddress: "Στείλτε {{Ποσό}} για μία πληρωμή στην ακόλουθη διεύθυνση {{διεύθυνση}}",
      copyToClipboard: "Αντιγράψτε τη διεύθυνση στο πρόχειρο",
      copied: "Αντιγραφή",
      scanAddressOr: "Σαρώστε τη διεύθυνση του πορτοφολιού ή",
      amountToPay: "Για πληρωμή",
      commissionLabel: "Αμοιβή:",
      walletDepositMessage: "Παρακαλούμε συνεχίστε την κατάθεσή σας χρησιμοποιώντας την ακόλουθη διεύθυνση:",
      ButtonConfirmCascadingLabel: "Δοκιμάστε ξανά με διαφορετικό ISP",
      ButtonConfirmCascadingTimeoutLabel: "Αλλαγή ISP μέσω",
      cascadingInstructions: "Η συναλλαγή σας απορρίφθηκε, αλλά μην ανησυχείτε, έχουμε έναν άλλο πάροχο για να δοκιμάσετε. Παρακαλούμε μην κλείσετε αυτή τη σελίδα.",
      errorCode: "Αγαπητέ πελάτη, δυστυχώς δεν υπάρχουν διαθέσιμες επιλογές πληρωμής. Παρακαλούμε βεβαιωθείτε ότι υποστηρίζονται οι ακόλουθες επιλογές: Τύπος κάρτας, χώρα BIN, ποσό και νόμισμα. Παρακαλούμε επικοινωνήστε με τον έμπορο ή δοκιμάστε να χρησιμοποιήσετε μια άλλη κάρτα.",
      TERMINAL_NOT_FOUND: "Αγαπητέ πελάτη, δυστυχώς δεν υπάρχουν διαθέσιμες επιλογές πληρωμής. Παρακαλούμε χρησιμοποιήστε άλλη μέθοδο πληρωμής ή άλλη κάρτα.",
      saveCardLabel: "Θέλετε να κρατήσετε την κάρτα σας;",
      addNewCard: "Προσθέστε μια νέα κάρτα",
      accept: "Αποδοχή",
      validationInvalidCharactersInName: "Παρακαλούμε χρησιμοποιήστε τουλάχιστον δύο λατινικούς χαρακτήρες",
      validationExactlyTwoWordsRequiredInName: "Το όνομα του κατόχου της κάρτας πρέπει να αποτελείται από ακριβώς δύο λέξεις",
      validationNotBlank: "Το πεδίο {{fieldName}} δεν μπορεί να είναι κενό.",
      receiptBtn: "Λάβετε μια απόδειξη",
      progress: "Παρακαλώ περιμένετε, η συναλλαγή σας βρίσκεται σε επεξεργασία. Μην κλείσετε αυτή τη σελίδα",
      verifyCard: "Επαλήθευση κάρτας",
      withdraw: "Ανάληψη",
    },
  },
  fi: {
    translation: {
      ExpiresEnd: "Viimeinen voimassaolopäivä",
      cardHolderLabel: "Kortinhaltijan nimi",
      cardHolderPlaceHolder: "Kortissa oleva nimi",
      cardPanLabel: "Kortin numero",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Viimeinen voimassaolopäivä",
      CardExpiryPlaceholder: "MM/YYY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Kertakäyttösalasana",
      Password: "Salasana",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Määrä",
      AmountPlaceholder: "Syötä summa",
      ButtonSubmitLabel: "MAKSA NYT",
      SelectPaymentMethodLabel: "Valitse maksutapa",
      SelectPredefineAmountLabel: "Valitse summa",
      ManualAmountLabel: "Käytä eri summaa",
      customerCitizenshipCountryCodeLabel: "Kansalaisuus",
      customerCitizenshipCountryCodePlaceHolder: "Kansalaisuusmaa",
      billingCountryCodeLabel: "Maa",
      billingCountryCodePlaceHolder: "Valitse maa",
      customerFirstNameLabel: "Etunimi",
      customerFirstNamePlaceHolder: "Etunimi",
      customerLastNameLabel: "Sukunimi",
      customerLastNamePlaceHolder: "Sukunimi",
      billingAddressLine1Label: "Osoiterivi 1",
      billingAddressLine1PlaceHolder: "Osoite rivi 1",
      billingCityLabel: "Kaupunki",
      billingCityPlaceHolder: "Kaupunki",
      billingStateLabel: "Osavaltio",
      billingStatePlaceHolder: "Osavaltio",
      billingPostalCodeLabel: "Postinumero",
      billingPostalCodePlaceHolder: "Postinumero",
      customerDateOfBirthLabel: "Syntymäaika",
      customerDateOfBirthPlaceHolder: "Syntymäaika",
      customerEmailLabel: "Sähköpostiosoite",
      customerEmailPlaceHolder: "Kirjoita sähköpostiosoitteesi",
      customerPhoneLabel: "Puhelin",
      customerPhonePlaceHolder: "+358 1234567",
      customerPersonalIdLabel: "Henkilötunnus",
      customerPersonalIdPlaceHolder: "Henkilötunnus",
      billingAddressLine2Label: "Osoiterivi 2",
      billingAddressLine2PlaceHolder: "Osoiterivi 2",
      CardNumberRequired: "Tarvittava kortin numero",
      CardNumberInvalid: "Väärä kortin numero",
      CardExpiryDateRequired: "Vanhentumispäivämäärä vaaditaan",
      CVVRequired: "Turvakoodi vaaditaan",
      customerAccountNumberLabel: "Tilinumero",
      customerAccountNumberPlaceHolder: "Syötä tilisi",
      AmountRequiredLabel: "Tarvittava summa",
      AmountInvalidLabel: "Virheellinen määrä",
      CancelLabel: "Peruutus",
      PhoneNumberRequired: "Tarvittava puhelinnumero",
      PhoneNumberInvalid: "Väärä puhelinnumero",
      PaymentMethodNotAvailableLabel: "Maksutavat eivät ole käytettävissä",
      ButtonConfirmLabel: "Vahvista",
      BASIC_CARD: "Luottokortti",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Paikallinen maksu",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Maksa pankkisi kautta",
      VOLT: "Maksa pankkisi kautta",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Maksa pankkisi kautta",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Kryptovaluutan talletus",
      ConfirmationMessage: "Vahvista muunnettu määrä.",
      customSelectPlaceHolderpaymentMethod: "Valitse menetelmä",
      customSelectPlaceHolderdocumentType: "Valitse asiakirjan tyyppi",
      customSelectPlaceHoldercryptoCurrency: "Valitse kryptovaluutta",
      blockchainValuePlaceHolder: "Valitse lohkoketjun arvo",
      paymentMethod: "Maksutapa",
      documentType: "Asiakirjan tyyppi",
      documentNumber: "Asiakirjan numero",
      cryptoCurrency: "Kryptovaluutta",
      accountNumber: "Tilinumero",
      maskedPanMessage: "Summa veloitetaan:",
      endingIn: "Päättyy klo.",
      descriptionLabel: "Kuvaus:",
      invalidCVV: "CVV on virheellinen.",
      invalidOTP: "Virheellinen OTP",
      OTPRequired: "OTP vaaditaan",
      PasswordRequired: "Salasana vaaditaan",
      invalidPassword: "Virheellinen salasana",
      CardExpiryDateInvalid: "Virheellinen tai vanhentunut päivämäärä",
      IN_PROGRESS: "KÄSITTELYSSÄ",
      CANCELLED: "PERUUTETTU",
      DECLINED: "PERUUTETTU",
      COMPLETED: "PÄÄTTYNYT",
      backToShopButtonLabel: "Palaa sivustolle",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Talletuksen määrä:",
      debitAmountLabel: "Nostosumma:",
      validationRequired: "{{fieldName}} kenttä on pakollinen.",
      validationMaxLength: "Maksimipituus {{maxLength}} merkkiä.",
      cryptoWarningMessage_1: "Jos lähetät jonkin muun summan, järjestelmä hyväksyy sen joka tapauksessa!",
      sendAmountInToAddress: "Lähetä {{summa}} per maksu seuraavaan osoitteeseen {{osoite}}",
      copyToClipboard: "Kopioi osoite leikepöydälle",
      copied: "Kopioitu",
      scanAddressOr: "Skannaa lompakon osoite tai",
      amountToPay: "Maksua varten",
      commissionLabel: "Maksu:",
      walletDepositMessage: "Jatka talletusta käyttämällä seuraavaa osoitetta:",
      ButtonConfirmCascadingLabel: "Yritä uudelleen eri ISP:llä",
      ButtonConfirmCascadingTimeoutLabel: "Vaihda palveluntarjoajaa",
      cascadingInstructions: "Tapahtumasi hylättiin, mutta älä huoli, meillä on toinen palveluntarjoaja, jota voit kokeilla. Älä sulje tätä sivua.",
      errorCode: "Hyvä asiakas, valitettavasti maksuvaihtoehtoja ei ole käytettävissä. Varmista, että seuraavat vaihtoehdot ovat tuettuja: Korttityyppi, maan BIN, summa ja valuutta. Ota yhteyttä kauppiaaseen tai yritä käyttää toista korttia.",
      TERMINAL_NOT_FOUND: "Hyvä asiakas, valitettavasti maksuvaihtoehtoja ei ole saatavilla. Käytä toista maksutapaa tai toista korttia.",
      saveCardLabel: "Haluatko pitää korttisi?",
      addNewCard: "Lisää uusi kortti",
      accept: "Hyväksy",
      validationInvalidCharactersInName: "Käytä vähintään kahta latinalaista merkkiä",
      validationExactlyTwoWordsRequiredInName: "Kortinhaltijan nimen on koostuttava täsmälleen kahdesta sanasta.",
      validationNotBlank: "{{fieldName}}-kenttä ei voi olla tyhjä.",
      receiptBtn: "Vastaanota kuitti",
      progress: "Odota, maksutapahtumaasi käsitellään parhaillaan. Älä sulje tätä sivua",
      verifyCard: "Tarkista kortti",
      withdraw: "Nosta",
    },
  },
  sv: {
    translation: {
      ExpiresEnd: "Utgångsdatum",
      cardHolderLabel: "Kortinnehavarens namn",
      cardHolderPlaceHolder: "Namn på kortet",
      cardPanLabel: "Kortets nummer",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Datum för upphörande",
      CardExpiryPlaceholder: "MM/ÅÅÅÅ",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Engångslösenord",
      Password: "Lösenord",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Belopp",
      AmountPlaceholder: "Ange belopp",
      ButtonSubmitLabel: "BETALA NU",
      SelectPaymentMethodLabel: "Välj betalningsmetod",
      SelectPredefineAmountLabel: "Välj belopp",
      ManualAmountLabel: "Använd ett annat belopp",
      customerCitizenshipCountryCodeLabel: "Nationalitet",
      customerCitizenshipCountryCodePlaceHolder: "Land där du är medborgare",
      billingCountryCodeLabel: "Land",
      billingCountryCodePlaceHolder: "Välj land",
      customerFirstNameLabel: "Förnamn",
      customerFirstNamePlaceHolder: "Förnamn",
      customerLastNameLabel: "Efternamn",
      customerLastNamePlaceHolder: "Efternamn",
      billingAddressLine1Label: "Adressrad 1",
      billingAddressLine1PlaceHolder: "Adress rad 1",
      billingCityLabel: "Stad, ort",
      billingCityPlaceHolder: "stad",
      billingStateLabel: "stat",
      billingStatePlaceHolder: "Land",
      billingPostalCodeLabel: "Postnummer",
      billingPostalCodePlaceHolder: "Postnummer",
      customerDateOfBirthLabel: "Födelsedatum",
      customerDateOfBirthPlaceHolder: "Födelsedatum",
      customerEmailLabel: "Din e-post",
      customerEmailPlaceHolder: "Ange din e-postadress",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+46 1234567",
      customerPersonalIdLabel: "Personlig identifiering",
      customerPersonalIdPlaceHolder: "Identifikationsnummer",
      billingAddressLine2Label: "Adressrad 2",
      billingAddressLine2PlaceHolder: "Adressrad 2",
      CardNumberRequired: "Kortnummer krävs",
      CardNumberInvalid: "Felaktigt kortnummer",
      CardExpiryDateRequired: "Utgångsdatum krävs",
      CVVRequired: "Säkerhetskod krävs",
      customerAccountNumberLabel: "Kontonummer",
      customerAccountNumberPlaceHolder: "Ange ditt konto",
      AmountRequiredLabel: "Belopp krävs",
      AmountInvalidLabel: "Ogiltigt belopp",
      CancelLabel: "Avbeställning",
      PhoneNumberRequired: "Telefonnummer krävs",
      PhoneNumberInvalid: "Felaktigt telefonnummer",
      PaymentMethodNotAvailableLabel: "Betalningsmetoder inte tillgängliga",
      ButtonConfirmLabel: "Bekräfta",
      BASIC_CARD: "Kreditkort",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Betala via din bank",
      VOLT: "Betala via din bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Betala via din bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Insättning av kryptovaluta",
      ConfirmationMessage: "Vänligen bekräfta det konverterade beloppet.",
      customSelectPlaceHolderpaymentMethod: "Välj metod",
      customSelectPlaceHolderdocumentType: "Välj dokumenttyp",
      customSelectPlaceHoldercryptoCurrency: "Välj kryptovaluta",
      blockchainValuePlaceHolder: "Välj blockkedjevärde",
      paymentMethod: "Betalningsmetod",
      documentType: "Dokumenttyp",
      documentNumber: "Dokumentets nummer",
      cryptoCurrency: "Kryptovaluta",
      accountNumber: "Kontonummer",
      maskedPanMessage: "Beloppet kommer att debiteras från:",
      endingIn: "Slutar på.",
      descriptionLabel: "Beskrivning:",
      invalidCVV: "Ogiltig CVV",
      invalidOTP: "Ogiltig OTP",
      OTPRequired: "OTP krävs",
      PasswordRequired: "Lösenord krävs",
      invalidPassword: "Ogiltigt lösenord",
      CardExpiryDateInvalid: "Felaktigt eller utgånget datum",
      IN_PROGRESS: "UNDER BEHANDLING",
      CANCELLED: "ANNULLERAD",
      DECLINED: "AVBRUTEN",
      COMPLETED: "AVSLUTAD",
      backToShopButtonLabel: "Återgå till webbplatsen",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Insättningsbelopp:",
      debitAmountLabel: "Uttagsbelopp:",
      validationRequired: "{{fieldName}} fältet är obligatoriskt",
      validationMaxLength: "Maximal längd {{maxLängd}} tecken.",
      cryptoWarningMessage_1: "Om du skickar något annat belopp kommer systemet att acceptera det ändå!",
      sendAmountInToAddress: "Skicka {{amount}} för en betalning till följande adress {{address}}",
      copyToClipboard: "Kopiera adressen till urklipp",
      copied: "Kopierad",
      scanAddressOr: "Skanna plånboksadressen eller",
      amountToPay: "För betalning",
      commissionLabel: "Avgift:",
      walletDepositMessage: "Fortsätt din insättning med hjälp av följande adress:",
      ButtonConfirmCascadingLabel: "Försök igen med en annan internetleverantör",
      ButtonConfirmCascadingTimeoutLabel: "Byt internetleverantör via",
      cascadingInstructions: "Din transaktion avvisades, men oroa dig inte, vi har en annan leverantör att prova. Vänligen stäng inte denna sida.",
      errorCode: "Kära kund, tyvärr finns det inga betalningsalternativ tillgängliga. Vänligen kontrollera att följande alternativ stöds: Korttyp, Land BIN, Belopp och Valuta. Vänligen kontakta handlaren eller försök använda ett annat kort.",
      TERMINAL_NOT_FOUND: "Kära kund, tyvärr finns det inga tillgängliga betalningsalternativ. Vänligen använd en annan betalningsmetod eller ett annat kort.",
      saveCardLabel: "Vill du behålla ditt kort?",
      addNewCard: "Lägg till ett nytt kort",
      accept: "Acceptera",
      validationInvalidCharactersInName: "Vänligen använd minst två latinska tecken",
      validationExactlyTwoWordsRequiredInName: "Kortinnehavarens namn måste bestå av exakt två ord",
      validationNotBlank: "Fältet {{fieldName}} får inte vara tomt.",
      receiptBtn: "Ta emot ett kvitto",
      progress: "Vänligen vänta, din transaktion håller på att behandlas. Stäng inte denna sida",
      verifyCard: "Verifiera kort",
      withdraw: "Ta ut pengar"
    },
  },
  sk: {
    translation: {
      ExpiresEnd: "Dátum skončenia platnosti",
      cardHolderLabel: "Meno držiteľa karty",
      cardHolderPlaceHolder: "Názov na karte",
      cardPanLabel: "Číslo karty",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Dátum skončenia platnosti",
      CardExpiryPlaceholder: "MM/RRRR",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Jednorazové heslo",
      Password: "Heslo",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Suma",
      AmountPlaceholder: "Zadajte sumu",
      ButtonSubmitLabel: "PLATBA TERAZ",
      SelectPaymentMethodLabel: "Vyberte spôsob platby",
      SelectPredefineAmountLabel: "Vyberte sumu",
      ManualAmountLabel: "Použite inú sumu",
      customerCitizenshipCountryCodeLabel: "Štátna príslušnosť",
      customerCitizenshipCountryCodePlaceHolder: "Krajina občianstva",
      billingCountryCodeLabel: "Krajina",
      billingCountryCodePlaceHolder: "Vyberte krajinu",
      customerFirstNameLabel: "Krstné meno",
      customerFirstNamePlaceHolder: "Krstné meno",
      customerLastNameLabel: "Priezvisko",
      customerLastNamePlaceHolder: "Priezvisko",
      billingAddressLine1Label: "Riadok adresy 1",
      billingAddressLine1PlaceHolder: "Riadok adresy 1",
      billingCityLabel: "Mesto",
      billingCityPlaceHolder: "Mesto",
      billingStateLabel: "Štát",
      billingStatePlaceHolder: "Štát",
      billingPostalCodeLabel: "PSČ",
      billingPostalCodePlaceHolder: "PSČ",
      customerDateOfBirthLabel: "Dátum narodenia",
      customerDateOfBirthPlaceHolder: "Dátum narodenia",
      customerEmailLabel: "E-mail",
      customerEmailPlaceHolder: "Zadajte svoj e-mail",
      customerPhoneLabel: "Telefón",
      customerPhonePlaceHolder: "+421 1234567",
      customerPersonalIdLabel: "Osobná identifikácia",
      customerPersonalIdPlaceHolder: "Identifikačné číslo",
      billingAddressLine2Label: "Riadok adresy 2",
      billingAddressLine2PlaceHolder: "Riadok adresy 2",
      CardNumberRequired: "Požadované číslo karty",
      CardNumberInvalid: "Nesprávne číslo karty",
      CardExpiryDateRequired: "Požadovaný dátum skončenia platnosti",
      CVVRequired: "Požadovaný bezpečnostný kód",
      customerAccountNumberLabel: "Číslo účtu",
      customerAccountNumberPlaceHolder: "Zadajte svoj účet",
      AmountRequiredLabel: "Požadovaná suma",
      AmountInvalidLabel: "Neplatná suma",
      CancelLabel: "Zrušenie",
      PhoneNumberRequired: "Požadované telefónne číslo",
      PhoneNumberInvalid: "Nesprávne telefónne číslo",
      PaymentMethodNotAvailableLabel: "Platobné metódy nie sú k dispozícii",
      ButtonConfirmLabel: "Potvrďte",
      BASIC_CARD: "Kreditná karta",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Miestna platba",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Platba prostredníctvom vašej banky",
      VOLT: "Platba prostredníctvom vašej banky",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Platba prostredníctvom vašej banky",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Vklad kryptomeny",
      ConfirmationMessage: "Potvrďte prosím prevedenú sumu.",
      customSelectPlaceHolderpaymentMethod: "Vyberte metódu",
      customSelectPlaceHolderdocumentType: "Vyberte typ dokladu",
      customSelectPlaceHoldercryptoCurrency: "Vyberte kryptomenu",
      blockchainValuePlaceHolder: "Vyberte hodnotu blockchainu",
      paymentMethod: "Spôsob platby",
      documentType: "Typ dokumentu",
      documentNumber: "Číslo dokladu",
      cryptoCurrency: "Kryptomena",
      accountNumber: "Číslo účtu",
      maskedPanMessage: "Suma bude odpísaná z:",
      endingIn: "Končí na.",
      descriptionLabel: "Popis:",
      invalidCVV: "Neplatné CVV",
      invalidOTP: "Neplatné OTP",
      OTPRequired: "Vyžaduje sa OTP",
      PasswordRequired: "Požadované heslo",
      invalidPassword: "Neplatné heslo",
      CardExpiryDateInvalid: "Nesprávny alebo neplatný dátum",
      IN_PROGRESS: "V PROCESE",
      CANCELLED: "ZRUŠENÉ",
      DECLINED: "ZRUŠENÉ",
      COMPLETED: "UKONČENÉ",
      backToShopButtonLabel: "Návrat na stránku",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Min.: Výška zálohy:",
      debitAmountLabel: "Výška vkladu: Výška výberu:",
      validationRequired: "{{fieldName}} pole je povinné",
      validationMaxLength: "Maximálna dĺžka {{maxLength}} znakov.",
      cryptoWarningMessage_1: "Ak pošlete akúkoľvek inú sumu, systém ju aj tak prijme!",
      sendAmountInToAddress: "Pošlite {{sumu}} za platbu na nasledujúcu adresu {{adresa}}",
      copyToClipboard: "Skopírujte adresu do schránky",
      copied: "Skopírujte",
      scanAddressOr: "Naskenujte adresu peňaženky alebo",
      amountToPay: "Pre platbu",
      commissionLabel: "Poplatok:",
      walletDepositMessage: "Vklad pokračujte pomocou nasledujúcej adresy:",
      ButtonConfirmCascadingLabel: "Skúste to znova s iným poskytovateľom internetových služieb",
      ButtonConfirmCascadingTimeoutLabel: "Zmeniť poskytovateľa prostredníctvom",
      cascadingInstructions: "Vaša transakcia bola zamietnutá, ale nebojte sa, máme pre vás iného poskytovateľa, ktorého môžete vyskúšať. Nezatvárajte prosím túto stránku.",
      errorCode: "Vážený zákazník, bohužiaľ nie sú k dispozícii žiadne možnosti platby. Uistite sa, že sú podporované nasledujúce možnosti: Typ karty, BIN krajiny, suma a mena. Kontaktujte prosím obchodníka alebo skúste použiť inú kartu.",
      TERMINAL_NOT_FOUND: "Vážený zákazník, bohužiaľ nie sú k dispozícii žiadne možnosti platby. Použite prosím iný spôsob platby alebo inú kartu.",
      saveCardLabel: "Chcete si ponechať svoju kartu?",
      addNewCard: "Pridajte novú kartu",
      accept: "Prijať",
      validationInvalidCharactersInName: "Prosím, použite aspoň dva znaky latinky",
      validationExactlyTwoWordsRequiredInName: "Meno držiteľa karty musí pozostávať presne z dvoch slov",
      validationNotBlank: "Pole {{fieldName}} nesmie byť prázdne.",
      receiptBtn: "Prijať potvrdenie",
      progress: "Počkajte prosím, vaša transakcia sa spracováva. Nezatvárajte túto stránku",
      verifyCard: "Overiť kartu",
      withdraw: "Výber",
    },
  },
  tr: {
    translation: {
    ExpiresEnd: "Son kullanma tarihi",
    cardHolderLabel: "Kart sahibinin adı",
    cardHolderPlaceHolder: "Karttaki isim",
    cardPanLabel: "Kart numarası",
    cardPanPlaceHolder: "0000 0000 0000 0000",
    CardExpiryLable: "Son kullanma tarihi",
    CardExpiryPlaceholder: "MM/YY",
    CardSecurityCodeLabel: "CVV",
    PasswordLabel: "Tek kullanımlık şifre",
    Password: "Şifre",
    CardSecurityPlaceholder: "CVV",
    AmountLabel: "Miktar",
    AmountPlaceholder: "Tutar girin",
    ButtonSubmitLabel: "ŞİMDİ ÖDE",
    SelectPaymentMethodLabel: "Ödeme yöntemini seçin",
    SelectPredefineAmountLabel: "Miktar seçiniz",
    ManualAmountLabel: "Farklı bir miktar kullanın",
    customerCitizenshipCountryCodeLabel: "Uyruk",
    customerCitizenshipCountryCodePlaceHolder: "Vatandaşı olduğunuz ülke",
    billingCountryCodeLabel: "Ülke",
    billingCountryCodePlaceHolder: "Ülke seçiniz",
    customerFirstNameLabel: "İsim",
    customerFirstNamePlaceHolder: "İlk isim",
    customerLastNameLabel: "Soyadı",
    customerLastNamePlaceHolder: "Soyadı",
    billingAddressLine1Label: "Adres satırı 1",
    billingAddressLine1PlaceHolder: "Adres satırı 1",
    billingCityLabel: "Şehir",
    billingCityPlaceHolder: "Şehir",
    billingStateLabel: "Eyalet",
    billingStatePlaceHolder: "Eyalet",
    billingPostalCodeLabel: "Posta kodu",
    billingPostalCodePlaceHolder: "Posta kodu",
    customerDateOfBirthLabel: "Doğum tarihi",
    customerDateOfBirthPlaceHolder: "Doğum tarihi",
    customerEmailLabel: "E-posta",
    customerEmailPlaceHolder: "E-posta adresinizi girin",
    customerPhoneLabel: "Telefon",
    customerPhonePlaceHolder: "+51 1234567",
    customerPersonalIdLabel: "Kişisel tanımlama",
    customerPersonalIdPlaceHolder: "Kimlik numarası",
    billingAddressLine2Label: "Adres satırı 2",
    billingAddressLine2PlaceHolder: "Adres satırı 2",
    CardNumberRequired: "Kart numarası gerekli",
    CardNumberInvalid: "Yanlış kart numarası",
    CardExpiryDateRequired: "Son kullanma tarihi gerekli",
    CVVRequired: "Güvenlik kodu gerekli",
    customerAccountNumberLabel: "Hesap numarası",
    customerAccountNumberPlaceHolder: "Hesabınızı girin",
    AmountRequiredLabel: "Gerekli miktar",
    AmountInvalidLabel: "Geçersiz tutar",
    CancelLabel: "İptal",
    PhoneNumberRequired: "Telefon numarası gerekli",
    PhoneNumberInvalid: "Yanlış telefon numarası",
    PaymentMethodNotAvailableLabel: "Ödeme yöntemleri mevcut değil",
    ButtonConfirmLabel: "Onaylayın",
    BASIC_CARD: "Kredi kartı",
    TINK: "Bankanız aracılığıyla ödeme yapın",
    B2BINPAY: "Kripto para yatırma",
    ConfirmationMessage: "Lütfen dönüştürülen tutarı onaylayın.",
    customSelectPlaceHolderpaymentMethod: "Yöntem seçiniz",
    customSelectPlaceHolderdocumentType: "Belge türünü seçin",
    customSelectPlaceHoldercryptoCurrency: "Kripto para seçin",
    blockchainValuePlaceHolder: "Blok zinciri değerini seçin",
    paymentMethod: "Ödeme yöntemi",
    documentType: "Belge türü",
    documentNumber: "Belge numarası",
    cryptoCurrency: "Kripto para",
    accountNumber: "Hesap numarası",
    maskedPanMessage: "Tutar borçlandırılacaktır:",
    endingIn: "Şurada biter.",
    descriptionLabel: "Açıklama:",
    invalidCVV: "Geçersiz",
    invalidOTP: "Geçersiz OTP",
    OTPRequired: "OTP gerekli",
    PasswordRequired: "Şifre gerekli",
    invalidPassword: "Geçersiz şifre",
    CardExpiryDateInvalid: "Yanlış veya son kullanma tarihi geçmiş",
    IN_PROGRESS: "İŞLEMDE",
    CANCELLED: "İPTAL EDİLDİ",
    DECLINED: "İPTAL EDİLDİ",
    COMPLETED: "SONLANDI",
    backToShopButtonLabel: "Siteye geri dön",
    minAmountLabel: "Min:",
    maxAmountLabel: "Maks:",
    depositAmountLabel: "Depozito Tutarı:",
    debitAmountLabel: "Para Çekme Tutarı:",
    validationRequired: "{{fieldName}} alanı zorunludur",
    validationMaxLength: "Maksimum uzunluk {{maxLength}} karakter.",
    cryptoWarningMessage_1: "Başka bir miktar gönderirseniz, sistem yine de kabul edecektir!",
    sendAmountInToAddress: "Aşağıdaki adrese {{adres}} bir ödeme için {{miktar}} gönderin",
    copyToClipboard: "Adresi panoya kopyalayın",
    copied: "Kopyalandı",
    scanAddressOr: "Cüzdan adresini tarayın veya",
    amountToPay: "Ödeme için",
    commissionLabel: "Ücret:",
    walletDepositMessage: "Lütfen aşağıdaki adresi kullanarak para yatırma işleminize devam edin:",
    ButtonConfirmCascadingLabel: "Farklı bir İSS ile tekrar deneyin",
    ButtonConfirmCascadingTimeoutLabel: "Sağlayıcıyı şu yolla değiştirin",
    cascadingInstructions: "İşleminiz reddedildi, ancak endişelenmeyin, deneyebileceğiniz başka bir sağlayıcımız var. Lütfen bu sayfayı kapatmayın.",
    errorCode: "Değerli müşterimiz, maalesef mevcut ödeme seçeneği bulunmamaktadır. Lütfen aşağıdaki seçeneklerin desteklendiğinden emin olun: Kart Türü, Ülke BIN, Tutar ve Para Birimi. Lütfen satıcıyla iletişime geçin veya farklı bir kart kullanmayı deneyin.",
    TERMINAL_NOT_FOUND: "Değerli müşterimiz, maalesef ödeme seçeneği mevcut değil. Lütfen başka bir ödeme yöntemi veya başka bir kart kullanın.",
    saveCardLabel: "Kartınızı saklamak mı istiyorsunuz?",
    addNewCard: "Yeni bir kart ekleyin",
    accept: "Kabul et",
    validationInvalidCharactersInName: "Lütfen en az iki Latin karakteri kullanın",
    validationExactlyTwoWordsRequiredInName: "Kart sahibinin adı tam olarak iki kelimeden oluşmalıdır",
    validationNotBlank: "{{fieldName}} alanı boş olamaz.",
    receiptBtn: "Bir makbuz alın",
    progress: "Lütfen bekleyin, işleminiz gerçekleştiriliyor. Bu sayfayı kapatmayın",
    verifyCard: "Kartı doğrulayın",
    withdraw: "Geri Çekilme",
    },
  },
  uz: {
    translation: {
      ExpiresEnd: "Muddati tugagan sana",
      cardHolderLabel: "Karta egasining ismi",
      cardHolderPlaceHolder: "Xaritadagi nom",
      cardPanLabel: "Karta raqami",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Muddati tugagan sana",
      CardExpiryPlaceholder: "MM/GG",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Bir martalik parol",
      Password: "Parol",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Miqdori",
      AmountPlaceholder: "Miqdorni kiriting",
      ButtonSubmitLabel: "HOZIR TO'LANG",
      SelectPaymentMethodLabel: "To'lov usulini tanlang",
      SelectPredefineAmountLabel: "Miqdorni tanlang",
      ManualAmountLabel: "Boshqa miqdordan foydalaning",
      customerCitizenshipCountryCodeLabel: "Fuqarolik",
      customerCitizenshipCountryCodePlaceHolder: "Fuqaroligi bo'lgan mamlakat",
      billingCountryCodeLabel: "Mamlakat",
      billingCountryCodePlaceHolder: "Mamlakatni tanlang",
      customerFirstNameLabel: "Ism",
      customerFirstNamePlaceHolder: "Ism",
      customerLastNameLabel: "Familiya",
      customerLastNamePlaceHolder: "Familiya",
      billingAddressLine1Label: "Manzil qatori 1",
      billingAddressLine1PlaceHolder: "Manzil qatori 1",
      billingCityLabel: "Shahar",
      billingCityPlaceHolder: "Shahar",
      billingStateLabel: "Shtat",
      billingStatePlaceHolder: "Shtat",
      billingPostalCodeLabel: "Pochta kodi",
      billingPostalCodePlaceHolder: "Pochta kodi",
      customerDateOfBirthLabel: "Tug'ilgan sana",
      customerDateOfBirthPlaceHolder: "Tug'ilgan sana",
      customerEmailLabel: "Elektron pochta",
      customerEmailPlaceHolder: "Elektron pochtangizni kiriting",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Shaxsiy identifikatsiya",
      customerPersonalIdPlaceHolder: "Identifikatsiya raqami",
      billingAddressLine2Label: "Manzil qatori 2",
      billingAddressLine2PlaceHolder: "Manzil qatori 2",
      CardNumberRequired: "Karta raqami talab qilinadi",
      CardNumberInvalid: "Noto'g'ri karta raqami",
      CardExpiryDateRequired: "Yaroqlilik muddati talab qilinadi",
      CVVRequired: "Xavfsizlik kodi talab qilinadi",
      customerAccountNumberLabel: "Hisob raqami",
      customerAccountNumberPlaceHolder: "Hisobingizni kiriting",
      AmountRequiredLabel: "Kerakli miqdor",
      AmountInvalidLabel: "Noto'g'ri miqdor",
      CancelLabel: "Bekor qilish",
      PhoneNumberRequired: "Telefon raqami talab qilinadi",
      PhoneNumberInvalid: "Noto'g'ri telefon raqami",
      PaymentMethodNotAvailableLabel: "To'lov usullari mavjud emas",
      ButtonConfirmLabel: "Tasdiqlang",
      BASIC_CARD: "Kredit kartasi",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Mahalliy to'lov",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Bankingiz orqali to'lang",
      VOLT: "Bankingiz orqali to'lang",
      B2BINPAY: "B2BinPay (Kripto)",
      B2BINPAYV2: "B2BinPay (Kripto)",
      CLICK: "Bankingiz orqali to'lang",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Kriptovalyuta depoziti",
      ConfirmationMessage: "Iltimos, konvertatsiya qilingan miqdorni tasdiqlang.",
      customSelectPlaceHolderpaymentMethod: "Usulni tanlang",
      customSelectPlaceHolderdocumentType: "Hujjat turini tanlang",
      customSelectPlaceHoldercryptoCurrency: "Kriptovalyutani tanlang",
      blockchainValuePlaceHolder: "Blokcheyn qiymatini tanlang",
      paymentMethod: "To'lov usuli",
      documentType: "Hujjat turi",
      documentNumber: "Hujjat raqami",
      cryptoCurrency: "Kriptovalyuta",
      accountNumber: "Hisob raqami",
      maskedPanMessage: "Miqdor hisobdan chiqariladi:",
      endingIn: "bilan tugaydi",
      descriptionLabel: "Tavsif:",
      invalidCVV: "Noto'g'ri CVV",
      invalidOTP: "Noto'g'ri OTP",
      OTPRequired: "Otp talab qilinadi",
      PasswordRequired: "Parol talab qilinadi",
      invalidPassword: "Parol noto'g'ri",
      CardExpiryDateInvalid: "Noto'g'ri yoki muddati tugagan sana",
      IN_PROGRESS: "JARAYONDA",
      CANCELLED: "BEKOR QILINGAN",
      DECLINED: "RAD ETILGAN",
      COMPLETED: "YAKUNLANDI",
      backToShopButtonLabel: "Saytga qaytish",
      minAmountLabel: "Min:",
      maxAmountLabel: "Maks:",
      depositAmountLabel: "Depozit miqdori:",
      debitAmountLabel: "Hisobdan chiqarish miqdori:",
      validationRequired: "{{fieldName}} maydoni talab qilinadi",
      validationMaxLength: "Maksimal uzunlik {{maxLength}} belgilari",
      cryptoWarningMessage_1: "Agar siz boshqa miqdorni yuborsangiz, tizim baribir uni qabul qiladi!",
      sendAmountInToAddress: "{{amount}}-ni bitta to'lov uchun quyidagi manzilga yuboring {{address}}",
      copyToClipboard: "Manzilni clipboardga nusxalash",
      copied: "Nusxalangan",
      scanAddressOr: "Hamyon manzilingizni skanerlang yoki",
      amountToPay: "To'lov uchun",
      commissionLabel: "Komissiya:",
      walletDepositMessage: "Iltimos, quyidagi manzildan foydalanib depozitni davom ettiring:",
      ButtonConfirmCascadingLabel: "Boshqa provayder bilan qayta urinib ko'ring",
      ButtonConfirmCascadingTimeoutLabel: "Provayder provayderini o'zgartirish orqali",
      cascadingInstructions: "Sizning tranzaktsiyangiz rad etildi, lekin tashvishlanmang, bizda sinab ko'rish uchun boshqa provayder bor. Iltimos, bu sahifani yopmang.",
      errorCode: "Hurmatli mijoz, afsuski, to'lov imkoniyatlari mavjud emas. Iltimos, quyidagi variantlar qo'llab-quvvatlanishiga ishonch hosil qiling: Karta turi, BIN mamlakati, miqdori va valyutasi. Iltimos, sotuvchi bilan bog'laning yoki boshqa kartadan foydalanishga harakat qiling.",
      TERMINAL_NOT_FOUND: "Hurmatli mijoz, afsuski, to'lov imkoniyatlari mavjud emas. Iltimos, boshqa to'lov usuli yoki boshqa kartadan foydalaning.",
      saveCardLabel: "Kartani saqlamoqchimisiz?",
      addNewCard: "Yangi xarita qo'shing",
      accept: "Qabul qilish",
      validationInvalidCharactersInName: "Iltimos, kamida ikkita lotin belgisidan foydalaning",
      validationExactlyTwoWordsRequiredInName: "Karta egasining ismi aniq ikki so'zdan iborat bo'lishi kerak",
      validationNotBlank: "{{fieldName}} maydoni bo'sh bo'lishi mumkin emas",
      receiptBtn: "Kvitansiya oling",
      progress: "Iltimos, kuting, tranzaktsiyangiz qayta ishlanmoqda. Bu sahifani yopmang",
      verifyCard: "Xaritani tekshiring",
      withdraw: "Chiqarish",
    },
  },
  cs: {
    translation: {
      ExpiresEnd: "Datum vypršení platnosti",
      cardHolderLabel: "Jméno držitele karty",
      cardHolderPlaceHolder: "Jméno na kartě",
      cardPanLabel: "Číslo karty",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Datum ukončení platnosti",
      CardExpiryPlaceholder: "MM/RRRR",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Jednorázové heslo",
      Password: "Heslo",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Částka",
      AmountPlaceholder: "Zadejte částku",
      ButtonSubmitLabel: "PLATBA TEĎ",
      SelectPaymentMethodLabel: "Vyberte způsob platby",
      SelectPredefineAmountLabel: "Vyberte částku",
      ManualAmountLabel: "Použijte jinou částku",
      customerCitizenshipCountryCodeLabel: "Státní příslušnost",
      customerCitizenshipCountryCodePlaceHolder: "Státní občanství",
      billingCountryCodeLabel: "Země",
      billingCountryCodePlaceHolder: "Vyberte zemi",
      customerFirstNameLabel: "Křestní jméno",
      customerFirstNamePlaceHolder: "Křestní jméno",
      customerLastNameLabel: "Příjmení",
      customerLastNamePlaceHolder: "Příjmení",
      billingAddressLine1Label: "Adresní řádek 1",
      billingAddressLine1PlaceHolder: "Adresní řádek 1",
      billingCityLabel: "Město",
      billingCityPlaceHolder: "Město",
      billingStateLabel: "Stát",
      billingStatePlaceHolder: "Stát",
      billingPostalCodeLabel: "Poštovní směrovací číslo",
      billingPostalCodePlaceHolder: "Poštovní směrovací číslo",
      customerDateOfBirthLabel: "Datum narození",
      customerDateOfBirthPlaceHolder: "Datum narození",
      customerEmailLabel: "E-mail",
      customerEmailPlaceHolder: "Zadejte svůj e-mail",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Osobní identifikace",
      customerPersonalIdPlaceHolder: "Identifikační číslo",
      billingAddressLine2Label: "Adresní řádek 2",
      billingAddressLine2PlaceHolder: "Adresní řádek 2",
      CardNumberRequired: "Požadované číslo karty",
      CardNumberInvalid: "Nesprávné číslo karty",
      CardExpiryDateRequired: "Požadované datum ukončení platnosti",
      CVVRequired: "Požadovaný bezpečnostní kód",
      customerAccountNumberLabel: "Číslo účtu",
      customerAccountNumberPlaceHolder: "Zadejte svůj účet",
      AmountRequiredLabel: "Požadovaná částka",
      AmountInvalidLabel: "Neplatná částka",
      CancelLabel: "Zrušení",
      PhoneNumberRequired: "Požadované telefonní číslo",
      PhoneNumberInvalid: "Nesprávné telefonní číslo",
      PaymentMethodNotAvailableLabel: "Platební metody nejsou k dispozici",
      ButtonConfirmLabel: "Potvrďte",
      invalidCVV: "Neplatný CVV",
      invalidOTP: "Neplatné OTP",
      OTPRequired: "Vyžadováno OTP",
      PasswordRequired: "Heslo vyžadováno",
      invalidPassword: "Neplatné heslo",
      CardExpiryDateInvalid: "Nesprávné nebo prošlé datum",
      IN_PROGRESS: "V PROCESU",
      CANCELLED: "ZRUŠENO",
      DECLINED: "ZRUŠENO",
      COMPLETED: "UKONČENO",
      backToShopButtonLabel: "Návrat na stránky",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Max:",
      debitAmountLabel: "Částka výběru:",
      validationRequired: "{{fieldName}} pole je povinné.",
      validationMaxLength: "Maximální délka {{maxLength}} znaků.",
      cryptoWarningMessage_1: "Pokud pošlete jinou částku, systém ji stejně přijme!",
      sendAmountInToAddress: "Zašlete {{částku}} pro jednu platbu na následující adresu {{adresa}}",
      copyToClipboard: "Zkopírujte adresu do schránky",
      copied: "Zkopírovat",
      scanAddressOr: "Naskenujte adresu peněženky nebo",
      amountToPay: "Pro platbu",
      commissionLabel: "Poplatek:",
      walletDepositMessage: "Pro platbu prosím pokračujte v platbě pomocí následující adresy:",
      ButtonConfirmCascadingLabel: "Zkuste to znovu s jiným poskytovatelem internetových služeb",
      ButtonConfirmCascadingTimeoutLabel: "Změňte poskytovatele prostřednictvím",
      cascadingInstructions: "Vaše transakce byla zamítnuta, ale nezoufejte, máme pro vás jiného poskytovatele, kterého můžete vyzkoušet. Nezavírejte prosím tuto stránku.",
      errorCode: "Vážený zákazníku, bohužel nejsou k dispozici žádné možnosti platby. Ujistěte se prosím, že jsou podporovány následující možnosti: Typ karty, BIN země, částka a měna. Kontaktujte prosím obchodníka nebo zkuste použít jinou kartu.",
      TERMINAL_NOT_FOUND: "Vážený zákazníku, bohužel nejsou k dispozici žádné možnosti platby. Použijte prosím jiný způsob platby nebo jinou kartu.",
      saveCardLabel: "Chcete si svou kartu ponechat?",
      addNewCard: "Přidejte novou kartu",
      accept: "Přijímat",
      validationInvalidCharactersInName: "Použijte prosím alespoň dva znaky latinky",
      validationExactlyTwoWordsRequiredInName: "Jméno držitele karty se musí skládat přesně ze dvou slov",
      validationNotBlank: "Pole {{fieldName}} nesmí být prázdné.",
      receiptBtn: "Přijmout účtenku",
      progress: "Počkejte prosím, vaše transakce se zpracovává. Nezavírejte tuto stránku",
      verifyCard: "Ověřit kartu",
      withdraw: "Vybrat"
    },
  },
  da: {
    translation: {
      ExpiresEnd: "Udløbsdato",
      cardHolderLabel: "Kortholders navn",
      cardHolderPlaceHolder: "Navn på kort",
      cardPanLabel: "Kortets nummer",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Udløbsdato",
      CardExpiryPlaceholder: "MM/ÅÅÅ",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Engangsadgangskode",
      Password: "Adgangskode",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Beløb",
      AmountPlaceholder: "Indtast beløb",
      ButtonSubmitLabel: "BETAL NU",
      SelectPaymentMethodLabel: "Vælg betalingsmetode",
      SelectPredefineAmountLabel: "Vælg beløb",
      ManualAmountLabel: "Brug et andet beløb",
      customerCitizenshipCountryCodeLabel: "Nationalitet",
      customerCitizenshipCountryCodePlaceHolder: "Land med statsborgerskab",
      billingCountryCodeLabel: "Land",
      billingCountryCodePlaceHolder: "Vælg land",
      customerFirstNameLabel: "Fornavn",
      customerFirstNamePlaceHolder: "Fornavn",
      customerLastNameLabel: "Efternavn",
      customerLastNamePlaceHolder: "Efternavn",
      billingAddressLine1Label: "Adresselinje 1",
      billingAddressLine1PlaceHolder: "Adresse linje 1",
      billingCityLabel: "By",
      billingCityPlaceHolder: "By",
      billingStateLabel: "Stat",
      billingStatePlaceHolder: "Stat",
      billingPostalCodeLabel: "Postnummer",
      billingPostalCodePlaceHolder: "Postnummer",
      customerDateOfBirthLabel: "Fødselsdato",
      customerDateOfBirthPlaceHolder: "Fødselsdato",
      customerEmailLabel: "E-mail",
      customerEmailPlaceHolder: "Indtast din e-mail",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+45 1234567",
      customerPersonalIdLabel: "Personlig identifikation",
      customerPersonalIdPlaceHolder: "Identifikationsnummer",
      billingAddressLine2Label: "Adresselinje 2",
      billingAddressLine2PlaceHolder: "Adresselinje 2",
      CardNumberRequired: "Kortnummer påkrævet",
      CardNumberInvalid: "Forkert kortnummer",
      CardExpiryDateRequired: "Udløbsdato påkrævet",
      CVVRequired: "Sikkerhedskode påkrævet",
      customerAccountNumberLabel: "Kontonummer",
      customerAccountNumberPlaceHolder: "Indtast din konto",
      AmountRequiredLabel: "Krævet beløb",
      AmountInvalidLabel: "Ugyldigt beløb",
      CancelLabel: "Annullering",
      PhoneNumberRequired: "Telefonnummer påkrævet",
      PhoneNumberInvalid: "Forkert telefonnummer",
      PaymentMethodNotAvailableLabel: "Betalingsmetoder ikke tilgængelige",
      ButtonConfirmLabel: "Bekræft",
      BASIC_CARD: "Kreditkort",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Lokal betaling",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Betal gennem din bank",
      VOLT: "Betal gennem din bank",
      B2BINPAY: "B2BinPay (Krypto)",
      B2BINPAYV2: "B2BinPay (Krypto)",
      CLICK: "Betal gennem din bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Indbetaling af kryptovaluta",
      ConfirmationMessage: "Bekræft venligst det konverterede beløb.",
      customSelectPlaceHolderpaymentMethod: "Vælg metode",
      customSelectPlaceHolderdocumentType: "Vælg dokumenttype",
      customSelectPlaceHoldercryptoCurrency: "Vælg kryptovaluta",
      blockchainValuePlaceHolder: "Vælg blockchain-værdi",
      paymentMethod: "Betalingsmetode",
      documentType: "Dokumenttype",
      documentNumber: "Dokumentnummer",
      cryptoCurrency: "Kryptovaluta",
      accountNumber: "Kontonummer",
      maskedPanMessage: "Beløbet vil blive debiteret fra:",
      endingIn: "Slutter ved.",
      descriptionLabel: "Beskrivelse:",
      invalidCVV: "Ugyldig CVV",
      invalidOTP: "Ugyldig OTP",
      OTPRequired: "OTP påkrævet",
      PasswordRequired: "Adgangskode påkrævet",
      invalidPassword: "Ugyldig adgangskode",
      CardExpiryDateInvalid: "Forkert eller udløbet dato",
      IN_PROGRESS: "I BEHANDLING",
      CANCELLED: "ANNULLERET",
      DECLINED: "AFVIST",
      COMPLETED: "SLUTTET",
      backToShopButtonLabel: "Vend tilbage til webstedet",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Indskudsbeløb:",
      debitAmountLabel: "Udbetalingsbeløb:",
      validationRequired: "{{fieldName}} feltet er obligatorisk",
      validationMaxLength: "Maksimal længde {{maxLength}} tegn.",
      cryptoWarningMessage_1: "Hvis du sender et andet beløb, accepterer systemet det alligevel!",
      sendAmountInToAddress: "Send {{amount}} for en betaling til følgende adresse {{address}}",
      copyToClipboard: "Kopier adressen til udklipsholderen",
      copied: "Kopieret",
      scanAddressOr: "Scan wallet-adressen eller",
      amountToPay: "Til betaling",
      commissionLabel: "Gebyr:",
      walletDepositMessage: "Fortsæt venligst din indbetaling med følgende adresse:",
      ButtonConfirmCascadingLabel: "Prøv igen med en anden udbyder",
      ButtonConfirmCascadingTimeoutLabel: "Skift udbyder via",
      cascadingInstructions: "Din transaktion blev afvist, men bare rolig, vi har en anden udbyder, du kan prøve. Luk venligst ikke denne side.",
      errorCode: "Kære kunde, der er desværre ingen tilgængelige betalingsmuligheder. Sørg for, at følgende muligheder er understøttet: Korttype, BIN-land, beløb og valuta. Kontakt venligst forhandleren, eller prøv at bruge et andet kort.",
      TERMINAL_NOT_FOUND: "Kære kunde, der er desværre ingen tilgængelige betalingsmuligheder. Brug venligst en anden betalingsmetode eller et andet kort.",
      saveCardLabel: "Vil du beholde dit kort?",
      addNewCard: "Tilføj et nyt kort",
      accept: "Accepter",
      validationInvalidCharactersInName: "Brug venligst mindst to latinske tegn",
      validationExactlyTwoWordsRequiredInName: "Kortholderens navn skal bestå af præcis to ord",
      validationNotBlank: "{{fieldName}} feltet må ikke være tomt.",
      receiptBtn: "Modtag en kvittering",
      progress: "Vent venligst, din transaktion er ved at blive behandlet. Luk ikke denne side",
      verifyCard: "Bekræft kort",
      withdraw: "Træk penge ud",
    },
  },
  ro: {
    translation: {
      ExpiresEnd: "Data expirării",
      cardHolderLabel: "Numele titularului cardului",
      cardHolderPlaceHolder: "Numele de pe card",
      cardPanLabel: "Numărul cardului",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Data expirării",
      CardExpiryPlaceholder: "MM/YYY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Parolă unică",
      Password: "Parola",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Suma",
      AmountPlaceholder: "Introduceți suma",
      ButtonSubmitLabel: "PLĂTIȚI ACUM",
      SelectPaymentMethodLabel: "Selectați metoda de plată",
      SelectPredefineAmountLabel: "Selectați suma",
      ManualAmountLabel: "Utilizați o sumă diferită",
      customerCitizenshipCountryCodeLabel: "Cetățenia",
      customerCitizenshipCountryCodePlaceHolder: "Țara de cetățenie",
      billingCountryCodeLabel: "Țara",
      billingCountryCodePlaceHolder: "Selectați țara",
      customerFirstNameLabel: "Numele și prenumele",
      customerFirstNamePlaceHolder: "Numele și prenumele",
      customerLastNameLabel: "Numele de familie",
      customerLastNamePlaceHolder: "Numele și prenumele",
      billingAddressLine1Label: "Linia 1 a adresei",
      billingAddressLine1PlaceHolder: "Adresa linia 1",
      billingCityLabel: "Oraș",
      billingCityPlaceHolder: "Oraș",
      billingStateLabel: "Stat",
      billingStatePlaceHolder: "Stat",
      billingPostalCodeLabel: "Cod poștal",
      billingPostalCodePlaceHolder: "Cod poștal",
      customerDateOfBirthLabel: "Data nașterii",
      customerDateOfBirthPlaceHolder: "Data nașterii",
      customerEmailLabel: "Email",
      customerEmailPlaceHolder: "Introduceți adresa dvs. de e-mail",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+40 1234567",
      customerPersonalIdLabel: "Identificare personală",
      customerPersonalIdPlaceHolder: "Numărul de identificare",
      billingAddressLine2Label: "Linia 2 a adresei",
      billingAddressLine2PlaceHolder: "Linia 2 a adresei",
      CardNumberRequired: "Numărul cardului necesar",
      CardNumberInvalid: "Număr de card incorect",
      CardExpiryDateRequired: "Data expirării necesară",
      CVVRequired: "Cod de securitate necesar",
      customerAccountNumberLabel: "Numărul contului",
      customerAccountNumberPlaceHolder: "Introduceți contul dvs.",
      AmountRequiredLabel: "Suma necesară",
      AmountInvalidLabel: "Suma invalidă",
      CancelLabel: "Anulare",
      PhoneNumberRequired: "Număr de telefon necesar",
      PhoneNumberInvalid: "Număr de telefon incorect",
      PaymentMethodNotAvailableLabel: "Metodele de plată nu sunt disponibile",
      ButtonConfirmLabel: "Confirmare",
      BASIC_CARD: "Card de credit",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Plată locală",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Plătiți prin intermediul băncii dvs.",
      VOLT: "Plătiți prin intermediul băncii dvs.",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Plătiți prin intermediul băncii dvs.",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Depozit criptomonedă",
      ConfirmationMessage: "Vă rugăm să confirmați suma convertită.",
      customSelectPlaceHolderpaymentMethod: "Selectați metoda",
      customSelectPlaceHolderdocumentType: "Selectați tipul de document",
      customSelectPlaceHoldercryptoCurrency: "Selectați criptomoneda",
      blockchainValuePlaceHolder: "Selectați valoarea blockchain",
      paymentMethod: "Metoda de plată",
      documentType: "Tipul documentului",
      documentNumber: "Numărul documentului",
      cryptoCurrency: "Criptomonedă",
      accountNumber: "Numărul contului",
      maskedPanMessage: "Suma va fi debitată din:",
      endingIn: "Se termină la.",
      descriptionLabel: "Descriere:",
      invalidCVV: "CVV invalid",
      invalidOTP: "OTP invalid",
      OTPRequired: "OTP necesar",
      PasswordRequired: "Parolă necesară",
      invalidPassword: "Parolă invalidă",
      CardExpiryDateInvalid: "Dată incorectă sau expirată",
      IN_PROGRESS: "ÎN PROCES",
      CANCELLED: "CANCELAT",
      DECLINED: "CANCELAT",
      COMPLETED: "TERMINAT",
      backToShopButtonLabel: "Reveniți la site",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Suma depozitului:",
      debitAmountLabel: "Suma retragerii:",
      validationRequired: "Câmpul {{fieldName}} este obligatoriu",
      validationMaxLength: "Lungimea maximă {{maxLength}} caractere.",
      cryptoWarningMessage_1: "Dacă trimiteți orice altă sumă, sistemul o va accepta oricum!",
      sendAmountInToAddress: "Trimiteți {{summa}} pentru o plată la următoarea adresă {{adresa}}",
      copyToClipboard: "Copiați adresa în clipboard",
      copied: "Copiat",
      scanAddressOr: "Scanați adresa portofelului sau",
      amountToPay: "Pentru plată",
      commissionLabel: "Taxă:",
      walletDepositMessage: "Vă rugăm să continuați depunerea utilizând următoarea adresă:",
      ButtonConfirmCascadingLabel: "Încercați din nou cu un ISP diferit",
      ButtonConfirmCascadingTimeoutLabel: "Schimbați furnizorul prin",
      cascadingInstructions: "Tranzacția dvs. a fost refuzată, dar nu vă faceți griji, avem un alt furnizor de încercat. Vă rugăm să nu închideți această pagină.",
      errorCode: "Stimate client, din păcate nu există opțiuni de plată disponibile. Vă rugăm să vă asigurați că următoarele opțiuni sunt acceptate: Tipul cardului, Țara BIN, Suma și Moneda. Vă rugăm să contactați comerciantul sau să încercați să utilizați un alt card.",
      TERMINAL_NOT_FOUND: "Stimate client, din păcate nu sunt disponibile opțiuni de plată. Vă rugăm să utilizați o altă metodă de plată sau un alt card.",
      saveCardLabel: "Doriți să vă păstrați cardul?",
      addNewCard: "Adăugați un card nou",
      accept: "Acceptați",
      validationInvalidCharactersInName: "Vă rugăm să folosiți cel puțin două caractere latine",
      validationExactlyTwoWordsRequiredInName: "Numele titularului cardului trebuie să fie format din exact două cuvinte",
      validationNotBlank: "Câmpul {{fieldName}} nu poate fi gol.",
      receiptBtn: "Primiți o chitanță",
      progress: "Vă rugăm să așteptați, tranzacția dvs. este în curs de procesare. Nu închideți această pagină",
      verifyCard: "Verificarea cardului",
      withdraw: "Retrageți",
    },
  },
  bg: {
    translation: {
      ExpiresEnd: "Дата на изтичане на валидността",
      cardHolderLabel: "Име на картодържателя",
      cardHolderPlaceHolder: "Име на картата",
      cardPanLabel: "Номер на картата",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Дата на изтичане",
      CardExpiryPlaceholder: "ММ/ГГГ",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Еднократна парола",
      Password: "Парола",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Сума",
      AmountPlaceholder: "Въведете сумата",
      ButtonSubmitLabel: "ПЛАТЕТЕ СЕГА",
      SelectPaymentMethodLabel: "Изберете метод на плащане",
      SelectPredefineAmountLabel: "Изберете сумата",
      ManualAmountLabel: "Използвайте друга сума",
      customerCitizenshipCountryCodeLabel: "Националност",
      customerCitizenshipCountryCodePlaceHolder: "Държава на гражданство",
      billingCountryCodeLabel: "Държава",
      billingCountryCodePlaceHolder: "Изберете държава",
      customerFirstNameLabel: "Първо име",
      customerFirstNamePlaceHolder: "Първо име",
      customerLastNameLabel: "Фамилно име",
      customerLastNamePlaceHolder: "Фамилно име",
      billingAddressLine1Label: "Адресен ред 1",
      billingAddressLine1PlaceHolder: "Адресен ред 1",
      billingCityLabel: "Град",
      billingCityPlaceHolder: "Град",
      billingStateLabel: "Щат",
      billingStatePlaceHolder: "Държава",
      billingPostalCodeLabel: "Пощенски код",
      billingPostalCodePlaceHolder: "Пощенски код",
      customerDateOfBirthLabel: "Дата на раждане",
      customerDateOfBirthPlaceHolder: "Дата на раждане",
      customerEmailLabel: "Електронна поща",
      customerEmailPlaceHolder: "Въведете вашия имейл",
      customerPhoneLabel: "Телефон",
      customerPhonePlaceHolder: "+359 1234567",
      customerPersonalIdLabel: "Лична идентификация",
      customerPersonalIdPlaceHolder: "Идентификационен номер",
      billingAddressLine2Label: "Адресен ред 2",
      billingAddressLine2PlaceHolder: "Адресен ред 2",
      CardNumberRequired: "Необходим номер на картата",
      CardNumberInvalid: "Неправилен номер на картата",
      CardExpiryDateRequired: "Изисква се дата на валидност",
      CVVRequired: "Изисква се код за сигурност",
      customerAccountNumberLabel: "Номер на сметката",
      customerAccountNumberPlaceHolder: "Въведете вашата сметка",
      AmountRequiredLabel: "Необходима сума",
      AmountInvalidLabel: "Невалидна сума",
      CancelLabel: "Анулиране",
      PhoneNumberRequired: "Необходим телефонен номер",
      PhoneNumberInvalid: "Неправилен телефонен номер",
      PaymentMethodNotAvailableLabel: "Не са налични методи за плащане",
      ButtonConfirmLabel: "Потвърдете",
      BASIC_CARD: "Кредитна карта",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Плащайте чрез банката си",
      VOLT: "Плащайте чрез банката си",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Плащайте чрез банката си",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Депозит на криптовалута",
      ConfirmationMessage: "Моля, потвърдете конвертираната сума.",
      customSelectPlaceHolderpaymentMethod: "Изберете метод",
      customSelectPlaceHolderdocumentType: "Изберете вида на документа",
      customSelectPlaceHoldercryptoCurrency: "Изберете криптовалута",
      blockchainValuePlaceHolder: "Изберете стойност на блокчейн",
      paymentMethod: "Метод на плащане",
      documentType: "Вид на документа",
      documentNumber: "Номер на документа",
      cryptoCurrency: "Криптовалута",
      accountNumber: "Номер на сметката",
      maskedPanMessage: "Сумата ще бъде дебитирана от:",
      endingIn: "Приключва в.",
      descriptionLabel: "Описание:",
      invalidCVV: "Невалиден CVV",
      invalidOTP: "Невалиден OTP",
      OTPRequired: "Изисква се OTP",
      PasswordRequired: "Изисква се парола",
      invalidPassword: "Невалидна парола",
      CardExpiryDateInvalid: "Неправилна или изтекла дата",
      IN_PROGRESS: "В ПРОЦЕС",
      CANCELLED: "ОТМЕНЕН",
      DECLINED: "ОТМЕНЕН",
      COMPLETED: "ПРИКЛЮЧЕН",
      backToShopButtonLabel: "Връщане към сайта",
      minAmountLabel: "Мин:",
      maxAmountLabel: "Макс:",
      depositAmountLabel: "Сума на депозита:",
      debitAmountLabel: "Сума за теглене:",
      validationRequired: "{{fieldName}} полето е задължително",
      validationMaxLength: "Максимална дължина {{maxLength}} символи.",
      cryptoWarningMessage_1: "Ако изпратите каквато и да е друга сума, системата все пак ще я приеме!",
      sendAmountInToAddress: "Изпратете {{сума}} за плащане на следния адрес {{адрес}}",
      copyToClipboard: "Копирайте адреса в клипборда",
      copied: "Копиран",
      scanAddressOr: "Сканирайте адреса на портфейла или",
      amountToPay: "За плащане",
      commissionLabel: "Такса:",
      walletDepositMessage: "Моля, продължете депозита си, като използвате следния адрес:",
      ButtonConfirmCascadingLabel: "Опитайте отново с друг интернет доставчик",
      ButtonConfirmCascadingTimeoutLabel: "Променете доставчика чрез",
      cascadingInstructions: "Транзакцията ви е била отказана, но не се притеснявайте, имаме друг доставчик, който да опитате. Моля, не затваряйте тази страница.",
      errorCode: "Уважаеми клиенти, за съжаление няма налични опции за плащане. Моля, уверете се, че следните опции се поддържат: Тип карта, BIN код на държавата, сума и валута. Моля, свържете се с търговеца или опитайте да използвате друга карта.",
      TERMINAL_NOT_FOUND: "Уважаеми клиенти, за съжаление няма налични опции за плащане. Моля, използвайте друг начин на плащане или друга карта.",
      saveCardLabel: "Искате да запазите картата си?",
      addNewCard: "Добавете нова карта",
      accept: "Приемете",
      validationInvalidCharactersInName: "Моля, използвайте поне два латински символа",
      validationExactlyTwoWordsRequiredInName: "Името на картодържателя трябва да се състои от точно две думи",
      validationNotBlank: "Полето {{fieldName}} не може да бъде празно.",
      receiptBtn: "Получаване на разписка",
      progress: "Моля, изчакайте, Вашата транзакция се обработва. Не затваряйте тази страница",
      verifyCard: "Потвърждаване на картата",
      withdraw: "Изтеглете"
    },
  },
  hu: {
    translation: {
      ExpiresEnd: "Lejárati idő",
      cardHolderLabel: "Kártyabirtokos neve",
      cardHolderPlaceHolder: "A kártyán szereplő név",
      cardPanLabel: "Kártyaszám",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "Lejárati idő",
      CardExpiryPlaceholder: "MM/YYY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "Egyszeri jelszó",
      Password: "Jelszó",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "Összeg",
      AmountPlaceholder: "Adja meg az összeget",
      ButtonSubmitLabel: "FIZETÉS MOST",
      SelectPaymentMethodLabel: "Válassza ki a fizetési módot",
      SelectPredefineAmountLabel: "Válassza ki az összeget",
      ManualAmountLabel: "Másik összeg használata",
      customerCitizenshipCountryCodeLabel: "Nemzetiség",
      customerCitizenshipCountryCodePlaceHolder: "Állampolgárság szerinti ország",
      billingCountryCodeLabel: "Ország",
      billingCountryCodePlaceHolder: "Válassza ki az országot",
      customerFirstNameLabel: "Keresztnév",
      customerFirstNamePlaceHolder: "Keresztnév",
      customerLastNameLabel: "Vezetéknév",
      customerLastNamePlaceHolder: "Vezetéknév",
      billingAddressLine1Label: "Címsor 1",
      billingAddressLine1PlaceHolder: "Cím 1. sor",
      billingCityLabel: "Város",
      billingCityPlaceHolder: "Város",
      billingStateLabel: "Állam",
      billingStatePlaceHolder: "Állam",
      billingPostalCodeLabel: "Irányítószám",
      billingPostalCodePlaceHolder: "Irányítószám",
      customerDateOfBirthLabel: "Születési dátum",
      customerDateOfBirthPlaceHolder: "Születési dátum",
      customerEmailLabel: "E-mail",
      customerEmailPlaceHolder: "Írja be az e-mail címét",
      customerPhoneLabel: "Telefon",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Személyazonosítás",
      customerPersonalIdPlaceHolder: "Azonosítószám",
      billingAddressLine2Label: "Címsor 2",
      billingAddressLine2PlaceHolder: "Cím sor 2",
      CardNumberRequired: "Kártyaszám szükséges",
      CardNumberInvalid: "Helytelen kártyaszám",
      CardExpiryDateRequired: "Lejárati dátum szükséges",
      CVVRequired: "Biztonsági kód szükséges",
      customerAccountNumberLabel: "Számlaszám",
      customerAccountNumberPlaceHolder: "Adja meg a számláját",
      AmountRequiredLabel: "Szükséges összeg",
      AmountInvalidLabel: "Érvénytelen összeg",
      CancelLabel: "Lemondás",
      PhoneNumberRequired: "Telefonszám szükséges",
      PhoneNumberInvalid: "Helytelen telefonszám",
      PaymentMethodNotAvailableLabel: "Nem elérhető fizetési módok",
      ButtonConfirmLabel: "Megerősítés",
      BASIC_CARD: "Hitelkártya",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Helyi fizetés",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Fizessen bankján keresztül",
      VOLT: "Fizessen bankján keresztül",
      B2BINPAY: "B2BinPay (Kripto)",
      B2BINPAYV2: "B2BinPay (Kripto)",
      CLICK: "Fizessen bankján keresztül",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Kriptovaluta befizetés",
      ConfirmationMessage: "Kérjük, erősítse meg az átváltott összeget.",
      customSelectPlaceHolderpaymentMethod: "Módszer kiválasztása",
      customSelectPlaceHolderdocumentType: "Válassza ki a dokumentum típusát",
      customSelectPlaceHoldercryptoCurrency: "Válassza ki a kriptovalutát",
      blockchainValuePlaceHolder: "Válassza ki a blokklánc értékét",
      paymentMethod: "Fizetési mód",
      documentType: "Dokumentum típusa",
      documentNumber: "Dokumentum száma",
      cryptoCurrency: "Kriptopénz",
      accountNumber: "Számlaszám",
      maskedPanMessage: "Az összeg a következő számláról kerül megterhelésre:",
      endingIn: "Véget ér a következő címen.",
      descriptionLabel: "Leírás:",
      invalidCVV: "Érvénytelen CVV",
      invalidOTP: "Érvénytelen OTP",
      OTPRequired: "OTP szükséges",
      PasswordRequired: "Jelszó szükséges",
      invalidPassword: "Érvénytelen jelszó",
      CardExpiryDateInvalid: "Helytelen vagy lejárt dátum",
      IN_PROGRESS: "FOLYAMATBAN",
      CANCELLED: "MEGSZŰNT",
      DECLINED: "ELMARADVA",
      COMPLETED: "MEGSZŰNT",
      backToShopButtonLabel: "Visszatérés a helyszínre",
      minAmountLabel: "Min:",
      maxAmountLabel: "Max:",
      depositAmountLabel: "Letét összege:",
      debitAmountLabel: "Visszavétel összege:",
      validationRequired: "{{mezőNév}} mező kötelező",
      validationMaxLength: "Maximális hossza {{maxLength}} karakter.",
      cryptoWarningMessage_1: "Ha ettől eltérő összeget küld, a rendszer mindenképpen elfogadja!",
      sendAmountInToAddress: "Küldjön {{összeg}} egy befizetést a következő címre {{cím}}",
      copyToClipboard: "Másolja a címet a vágólapra",
      copied: "Másolt",
      scanAddressOr: "Szkennelje be a tárca címét vagy",
      amountToPay: "Fizetéshez",
      commissionLabel: "Díj:",
      walletDepositMessage: "Kérjük, folytassa a befizetést a következő címen:",
      ButtonConfirmCascadingLabel: "Próbálja meg újra egy másik internetszolgáltatóval",
      ButtonConfirmCascadingTimeoutLabel: "Szolgáltatóváltás a",
      cascadingInstructions: "A tranzakciót elutasítottuk, de ne aggódjon, megpróbálhatja egy másik szolgáltatóval. Kérjük, ne zárja be ezt az oldalt.",
      errorCode: "Kedves vásárló, sajnos nem áll rendelkezésre semmilyen fizetési lehetőség. Kérjük, győződjön meg arról, hogy a következő opciók támogatottak: Kártya típusa, ország BIN, összeg és pénznem. Kérjük, lépjen kapcsolatba a kereskedővel, vagy próbáljon meg másik kártyát használni.",
      TERMINAL_NOT_FOUND: "Kedves vásárló, sajnos nem állnak rendelkezésre fizetési lehetőségek. Kérjük, használjon más fizetési módot vagy másik kártyát.",
      saveCardLabel: "Szeretné megtartani a kártyáját?",
      addNewCard: "Új kártya hozzáadása",
      accept: "Fogadja el",
      validationInvalidCharactersInName: "Kérjük, legalább két latin betűt használjon",
      validationExactlyTwoWordsRequiredInName: "A kártyatulajdonos nevének pontosan két szóból kell állnia.",
      validationNotBlank: "A {{fieldName}} mező nem lehet üres.",
      receiptBtn: "Bizonylat fogadása",
      progress: "Kérjük, várjon, a tranzakció feldolgozása folyamatban van. Ne zárja be ezt az oldalt",
      verifyCard: "Kártya ellenőrzése",
      withdraw: "Visszavonás",
    },
  },
  ka: {
    translation: {
      ExpiresEnd: "მოქმედების ვადა",
      cardHolderLabel: "ბარათის მფლობელის სახელი",
      cardHolderPlaceHolder: "სახელი ბარათზე",
      cardPanLabel: "ბარათის ნომერი",
      cardPanPlaceHolder: "0000 0000 0000 0000",
      CardExpiryLable: "ვარგისიანობის ვადა",
      CardExpiryPlaceholder: "MM/YY",
      CardSecurityCodeLabel: "CVV",
      PasswordLabel: "ერთჯერადი პაროლი",
      Password: "პაროლი",
      CardSecurityPlaceholder: "CVV",
      AmountLabel: "თანხა",
      AmountPlaceholder: "მიუთითე თანხის ოდენობა",
      ButtonSubmitLabel: "გადაიხადე ახლა",
      SelectPaymentMethodLabel: "აირჩიე გადახდის მეთოდი",
      SelectPredefineAmountLabel: "მიუთითე ოდენობა",
      ManualAmountLabel: "მიუთითე განსხვავებული თანხა",
      customerCitizenshipCountryCodeLabel: "მოქალაქეობა",
      customerCitizenshipCountryCodePlaceHolder: "მოქალაქეობა",
      billingCountryCodeLabel: "ქვეყანა",
      billingCountryCodePlaceHolder: "აირჩიე ქვეყანა",
      customerFirstNameLabel: "სახელი",
      customerFirstNamePlaceHolder: "სახელი",
      customerLastNameLabel: "გვარი",
      customerLastNamePlaceHolder: "გვარი",
      billingAddressLine1Label: "მისამართი 1",
      billingAddressLine1PlaceHolder: "მისამართი 1",
      billingCityLabel: "ქალაქი",
      billingCityPlaceHolder: "ქალაქი",
      billingStateLabel: "რეგიონი",
      billingStatePlaceHolder: "რეგიონი",
      billingPostalCodeLabel: "საფოსტო კოდი",
      billingPostalCodePlaceHolder: "საფოსტო კოდი",
      customerDateOfBirthLabel: "დაბადების თარიღი",
      customerDateOfBirthPlaceHolder: "დაბადების თარიღი",
      customerEmailLabel: "ელ-ფოსტა",
      customerEmailPlaceHolder: "მიუთითე ელ-ფოსტა",
      customerPhoneLabel: "მობილური",
      customerPhonePlaceHolder: "+51 1234567",
      customerPersonalIdLabel: "Personal Identification",
      customerPersonalIdPlaceHolder: "პირადი ნომერი",
      billingAddressLine2Label: "მისამართი 2",
      billingAddressLine2PlaceHolder: "მისამართი 2",
      CardNumberRequired: "ბარათის ნომერი აუცილებელია",
      CardNumberInvalid: "არასწორი ბარათის ნომერი",
      CardExpiryDateRequired: "მოქმედების თარიღი აუცილებელია",
      CVVRequired:  "უსაფრთხოების კოდი აუცილებელია",
      customerAccountNumberLabel: "ანგარიშის ნომერი",
      customerAccountNumberPlaceHolder: "შეიყვანე შენი ანგარიში",
      AmountRequiredLabel: "თანხა აუცილებელია",
      AmountInvalidLabel: "თანხა არასწორია",
      CancelLabel: "გაუქმება",
      PhoneNumberRequired: "ტელეფონის ნომერი აუცილებელია",
      PhoneNumberInvalid: "ტელეფონის ნომერი არასწორია",
      PaymentMethodNotAvailableLabel: "გადახდის მეთოდები ხელმისაწვდომი არ არის",
      ButtonConfirmLabel: "დადასტურება",
      BASIC_CARD: "საკრედიტო ბარათი",
      SKRILL: "Skrill",
      FLEXEPIN: "Flexepin",
      MACROPAY: "Macropay",
      PAYPAL: "PayPal",
      NETELLER: "Neteller",
      PAYRETAILERS: "PayRetailers",
      TRUSTPAYMENTS: "TrustPayments",
      LOCALPAYMENT: "Local Payment",
      MONNET: "Monnet",
      PAYMAXIS: "PayMaxis",
      GATE8TRANSACT: "Gate8 Transact",
      TINK: "Pay with your bank",
      VOLT: "Pay with your bank",
      B2BINPAY: "B2BinPay (Crypto)",
      B2BINPAYV2: "B2BinPay (Crypto)",
      CLICK: "Pay with your bank",
      MONETIX: "Monetix",
      PERFECTMONEY: "Perfect Money",
      KESSPAY: "KessPay",
      CRYPTO: "Crypto deposit",
      ConfirmationMessage: "დაადასტურე კონვერტირებული თანხა",
      customSelectPlaceHolderpaymentMethod: "აირჩიე მეთოდი",
      customSelectPlaceHolderdocumentType: "აირჩიე დოკუმენტის ტიპი",
      customSelectPlaceHoldercryptoCurrency: "აირჩიე კრიპტო ვალუტა",
      blockchainValuePlaceHolder: "Select the blockchain value",
      paymentMethod: "გადახდის მეთოდი",
      documentType: "დოკუმენტის ტიპი",
      documentNumber: "დოკუმენტის ტიპი",
      cryptoCurrency: "კრიპტოვალუტა",
      accountNumber: "ანგარიშის ნომერი",
      maskedPanMessage: "თანხა ჩამოიჭრება:",
      endingIn: "დასრულდება",
      descriptionLabel: "აღწერა:",
      invalidCVV: "არასწორი CVV",
      invalidOTP: "არასწორი OTP",
      OTPRequired: "საჭიროა OTP",
      PasswordRequired: "პაროლი აუცილებელია",
      invalidPassword: "არასწორი პაროლი",
      CardExpiryDateInvalid: "არასწორი ან ვადაგასული თარიღი",
      IN_PROGRESS: "IN PROGRESS",
      CANCELLED: "გაუქმებულია",
      DECLINED: "უარყოფილია",
      COMPLETED: "დასრულებულია",
      backToShopButtonLabel: "დაბრუნდი ვებ-გვერდზე",
      minAmountLabel: "მინიმალური:",
      maxAmountLabel: "მაქსიმალური:",
      depositAmountLabel: "ჩასარიცხი თანხა:",
      debitAmountLabel: "გასატანი თანხა:",
      validationRequired: " საჭიროა {{fieldName}}",
      validationMaxLength: "სიმბოლოების მაქსიმალური ოდენობა{{maxLength}}",
      cryptoWarningMessage_1:
        "თუ სხვა ოდენობის თანხას გადარიცხავ, სისტემა მაინც მიიღებს!",
      sendAmountInToAddress:
        "გაგზავნე  {{amount}} ერთი გადახდით შემდეგ მისამართზე: {{address}}",
      copyToClipboard: "Copy address to clipboard",
      copied: "დაკოპირებულია",
      scanAddressOr: "დაასკანერე საფულის მისამართი, ან",
      amountToPay: "გადახდა",
      commissionLabel: "საკომისიო:",
      walletDepositMessage:
        "განაგრძე დეპოზიტი შემდეგ მისამართზე:",
      ButtonConfirmCascadingLabel: "სცადეთ ხელახლა სხვა პროვაიდერის გამოყენებით",
      ButtonConfirmCascadingTimeoutLabel: "სცადე სხვა პროვაიდერი",
      cascadingInstructions:
        "გადარიცხვა უარყოფილია. არ დახურო გვერდი, სცადე სხვა პროვაიდერი.",
      errorCode:
        "გადახდის მეთოდი არ არის ხელმისაწვდომი. დარწმუნდით, რომ მითითებული მონაცემები - ბარათის ტიპი, ქვეყანა, თანხის ოდენობა და ვალუტა შესაბამისია. წინააღმდეგ შემთხვევაში, შეგიძლია მომხმარებელთა მხარდაჭერის გუნდს დაუკავშირდე.",
      TERMINAL_NOT_FOUND: "სამწუხაროდ, არ არის ხელმისაწვდომი გადახდის მეთოდი. შეგიძლია გამოიყენო სხვა გადახდის მეთოდი ან ბარათი",
      saveCardLabel: "გსურს დაიმახსოვრო ბარათი?",
      addNewCard: "დაამატე ახალი ბარათი",
      accept: "მიღება",
      validationInvalidCharactersInName:
        "გამოიყენე მინიმუმ ორი ლათინური სიმბოლო",
      validationExactlyTwoWordsRequiredInName:
        "ბარათის მფლობელის სახელი უნდა შედგებოდეს ზუსტად ორი სიტყვისგან.",
      validationNotBlank: "The {{fieldName}} field cannot be blank",
      receiptBtn: "მიიღე ქვითარი",
      progress:
        "დაელოდე, შენი ტრანზაქცია მუშავდება. არ დახურო ეს გვერდი",
      verifyCard: "ბარათის დადასტურება",
      withdraw: "თანხის გატანა",
    },
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
