import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import { formatAmount } from "../../../../utils/CurrencyFormater";

import "./style.scss";

export const FixedAmount = (props) => {
  const { data, method } = useContext(CheckoutContext);

  const browserLanguage = navigator.language || navigator.userLanguage;

  const { t } = useTranslation();

  return (
    <div className={`fixed-amount-wrapper ${props.additionalClass}`}>
      <div className="method-label">
        {typeof method === "object" ? t(method.paymentMethod) : t(method)}
      </div>
      <div className="fixed-amount-details">
        <div className="fixed-amount">
          {data.amount && formatAmount(data.amount, data.currency)}
        </div>
      </div>
    </div>
  );
};
