import React, { useContext } from "react";
import { CleaveInput } from "../../../../components/input/CleaveInput";
import { CustomInput } from "../../../../components/input/CustomInput";
import { CustomPhoneInput } from "../../../../components/input/phoneInput/CustomPhoneInput";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import { CountrySelect } from "../../../input/countrySelect/CountrySelect";
import { BlockchainSelect } from "../../../input/blockchainSelect/BlockchainSelect";
import { useFormContext } from "react-hook-form";
import { ReactComponent as UserIcon } from "../../../../assets/img/form-icons/solar_user-bold.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/img/form-icons/bi_phone-fill.svg";
import { ReactComponent as EmailIcon } from "../../../../assets/img/form-icons/ic_baseline-email.svg";
import { ReactComponent as PlanetIcon } from "../../../../assets/img/form-icons/mdi_planet.svg";
import { ReactComponent as AccountNumberIcon } from "../../../../assets/img/form-icons/account_number.svg";
import { ReactComponent as AddressLine1Icon } from "../../../../assets/img/form-icons/address_line_1.svg";
import { ReactComponent as AddressLine2Icon } from "../../../../assets/img/form-icons/address-line_2.svg";
import { ReactComponent as BirthdayIcon } from "../../../../assets/img/form-icons/birthday.svg";
import { ReactComponent as BlockchainIcon } from "../../../../assets/img/form-icons/blockchain.svg";
import { ReactComponent as CityIcon } from "../../../../assets/img/form-icons/city.svg";
import { ReactComponent as IdIcon } from "../../../../assets/img/form-icons/id.svg";
import { ReactComponent as PostCodeIcon } from "../../../../assets/img/form-icons/post_code.svg";
import { ReactComponent as StateIcon } from "../../../../assets/img/form-icons/state.svg";

import "./style.scss";

export const AdditionalFields = () => {
  const { method } = useContext(CheckoutContext);
  const { errors } = useFormContext();

  const getMaxLength = (item) => {
    switch (item) {
      case "customerFirstName":
      case "customerLastName":
        return 128;
      case "customerAccountNumber":
      case "customerPersonalId":
        return 64;
      case "billingAddressLine1":
      case "billingAddressLine2":
      case "billingCity":
        return 50;
      case "billingState":
        return 40;
      case "billingPostalCode":
        return 5;
      case "customerEmail":
        return 256;
      case "customerPhone":
        return 18;
      default:
        return null;
    }
  };

  return (
    <div className="additional-fields">
      {method.additionalFields.map((item) => {
        switch (item) {
          case "customerCitizenshipCountryCode":
          case "billingCountryCode":
            return (
              <CountrySelect
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                placeHolder={`${item}PlaceHolder`}
                required={item}
                additionalChild={
                  <PlanetIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "customerFirstName":
          case "customerLastName":
            return (
              <div key={item} className="additional-item">
                <CustomInput
                  name={item}
                  regexp={/^\s*[a-zA-Z]+\s*$/}
                  id={item}
                  label={`${item}Label`}
                  required={item}
                  maxLength={getMaxLength(item)}
                  additionalChild={
                    <UserIcon
                      className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                    />
                  }
                />
              </div>
            );
          case "customerAccountNumber":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                maxLength={getMaxLength(item)}
                additionalChild={
                  <AccountNumberIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "billingAddressLine1":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                maxLength={getMaxLength(item)}
                additionalChild={
                  <AddressLine1Icon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "billingCity":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                maxLength={getMaxLength(item)}
                additionalChild={
                  <CityIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "billingState":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                maxLength={getMaxLength(item)}
                additionalChild={
                  <StateIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "billingPostalCode":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                maxLength={getMaxLength(item)}
                additionalChild={
                  <PostCodeIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "customerPersonalId":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                maxLength={getMaxLength(item)}
                additionalChild={
                  <IdIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "customerDateOfBirth":
            return (
              <CleaveInput
                key={item}
                options={{ date: true, datePattern: ["d", "m", "Y"] }}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                additionalChild={
                  <BirthdayIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "customerEmail":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                maxLength={getMaxLength(item)}
                type="email"
                additionalChild={
                  <EmailIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "customerPhone":
            return (
              <CustomPhoneInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                required={item}
                additionalChild={
                  <PhoneIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "billingAddressLine2":
            return (
              <CustomInput
                key={item}
                name={item}
                id={item}
                label={`${item}Label`}
                type={item}
                maxLength={getMaxLength(item)}
                additionalChild={
                  <AddressLine2Icon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          case "currencyBlockchain":
            return (
              <BlockchainSelect
                key={item}
                name={item}
                id={item}
                label={`Blockchain`}
                placeHolder={`${item}PlaceHolder`}
                required={item}
                additionalChild={
                  <BlockchainIcon
                    className={`left-slot ${errors[item]?.message ? "error" : ""}`}
                  />
                }
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
