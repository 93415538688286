import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutContext } from "../../../../context/CheckoutContext";
import { CleaveInput } from "../../../input/CleaveInput";
import { AmountsRadio } from "./AmoutsRadio";
import { useFormContext } from "react-hook-form";
import { ReactComponent as MoneyIcon } from "../../../../assets/img/form-icons/mdi_money.svg";

import "./style.scss";

export const Amount = () => {
  const { data, method } = useContext(CheckoutContext);
  const { errors } = useFormContext();
  const [usePredefined, setPredefinedState] = useState(
    typeof method.predefinedAmounts !== "undefined"
  );

  const { t } = useTranslation();

  function switchToManual() {
    setPredefinedState(false);
  }

  function switchToPredefined() {
    setPredefinedState(true);
  }

  function required(value) {
    let error;
    if (!value) {
      error = `${t("AmountRequiredLabel")}`;
    }
    if (value < method.minAmount || value > method.maxAmount) {
      error = `${t("AmountInvalidLabel")}`;
    }
    return error || true;
  }

  return (
    <>
      {usePredefined ? (
        <div className="predefine-amount-wrapper">
          <div className="method-label">
            {typeof method === "object" ? t(method.paymentMethod) : t(method)}
          </div>
          <div className="predefined-amounts">
            <div className="amounts">
              <AmountsRadio onManualClick={switchToManual} />
            </div>
          </div>
        </div>
      ) : (
        data.amount !== 0 && (
          <div className="amount-wrapper manual">
            <div className="amount-top">
              <CleaveInput
                specialClass="amount-with-range"
                options={{
                  numeral: true,
                  numeralDecimalMark: ".",
                  delimiter: " ",
                }}
                wrapperClass="manual-label"
                name="amount"
                id="amount"
                type="tel"
                inputValidation={(value) => required(value)}
                inputClass={data.currency + " amount-input-icon"}
                additionalChild={
                  <>
                    {!usePredefined &&
                      typeof method.predefinedAmounts !== "undefined" && (
                        <div className="range-and-toggle">
                          <div onClick={switchToPredefined}>
                            <p className="button-toggle-amount-input">
                              {t("SelectPredefineAmountLabel")}
                            </p>
                          </div>
                        </div>
                      )}

                    <MoneyIcon
                      className={`left-slot ${errors["amount"]?.message ? "error" : ""}`}
                    />
                  </>
                }
              />
              <span
                className={`currency ${errors["amount"]?.message ? "error" : ""}`}
              >
                {data.currency}
              </span>
            </div>
            {(method.minAmount || method.maxAmount) && (
              <div className="range-and-toggle">
                <div className="amount-range-label">
                  {method.minAmount > 0 && (
                    <small>
                      {t("minAmountLabel")} {method.minAmount}
                    </small>
                  )}
                  {method.maxAmount > 0 && (
                    <small>
                      {t("maxAmountLabel")} {method.maxAmount}
                    </small>
                  )}
                </div>
              </div>
            )}
          </div>
        )
      )}
    </>
  );
};
