import React, { useContext } from "react";
import { CheckoutContext } from "../../context/CheckoutContext";
import { useTranslation } from "react-i18next";

import "./styles.scss";

export const TcFooter = ({ checked, handleChange }) => {
  const { data } = useContext(CheckoutContext);
  const { t } = useTranslation();

  return (
    <>
      <div className="tc_wrapper">
        <span className="tc-text">"{data.tcFooter.text}</span>
        {data.tcFooter.link && (
          <span>
            <a href={data.tcFooter.link}> {data.tcFooter.link}</a>"
          </span>
        )}
        <div className="checkbox-wrapper">
          <label htmlFor="tc_checkbox">{t("accept")}</label>
          <input
            id="tc_checkbox"
            type="checkbox"
            checked={checked}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};
