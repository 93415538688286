import success from "./COMPLETED.png";
import cancelled from "./CANCELLED.png";
import progress from "./PROGRESS.png";
import cascading_confirmation from "./CASCADING_CONFIRMATION.svg";

export default {
  success,
  cancelled,
  progress,
  cascading_confirmation,
};
