export default (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "STOP_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "SET_ID":
      return {
        ...state,
        id: action.payload,
      };
    case "SET_METHOD":
      return {
        ...state,
        method: action.payload,
      };
    case "UNSET_METHOD":
      return {
        ...state,
        method: null,
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "GET_SUCCESS":
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: false,
        method: action.payload.paymentMethod ?? null,
      };
    case "POST_SUCCESS":
      return {
        ...state,
        isLoading: true,
        id: action.payload,
        error: false,
      };
    case "GET_FAIL":
    case "POST_FAIL":
      return {
        ...state,
        isLoading: false,
        data: null,
        error: "Something went wrong",
      };
    case "CONFIRM_REQUEST":
    case "CONFIRM_CASCADING":
    case "CANCEL_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    default:
      return state;
  }
};
